import React, { useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Typography, Button, useTheme, FormControl, styled, TextField, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Paper, FormGroup, FormControlLabel, Checkbox, Tabs, Tab } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import LoadingModal from '../../../../services/loading';
import './styles.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ModalReports from '../StatisticsCampaign/Modal/ModalReports';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { getAllLogByDate, getCountLogByDate, getCountLogByMethodAndDate, getCountUserConnected, getLanguageLogByDate, getUserCountDates, getUserLogByDate } from '../../../../services/statistics';
import TablePagination from '@mui/material/TablePagination';
import FloatingImage from '../../../FloatingImage';
import SwipeableViews from 'react-swipeable-views';
import { ConnectionMethodTable } from './Tables/ConnectionMethodTable';
import { UseTimeTable } from './Tables/UseTimeTable';
import { UsersOnlineTable } from './Tables/UsersOnlineTable';
import { LanguageTable } from './Tables/LanguageTable';
import { AmountIncome } from './Tables/AmountIncome';


interface LogData {
    login_method: string;
    email: string;
    create_at: string;
    user_details: string;
    expiration_account: string;
    sessionat: string;
}
interface UserData {
    Username: string;
    ConnectedTime: string;
    LoginMethod: string;
    Language: string;
    CreatedAt: string;
    AccountExpiration: string;
}

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StatisticsPage = () => {
    const [success, setSuccess] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [value, setValue] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [paginatedData, setPaginatedData] = useState<LogData[]>([]);

    useLayoutEffect(
        () => {
            const getAll = async () => {
                const res1 = await getUserCountDates();
                setIsLoading(false);
            }
            const timeout = setTimeout(() => {
                getAll();
            }, 1000);
        },
        []
    );


    const handleSnackbarClick = async () => {
        setIsOpen(true);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);

        setButtonClicked(false);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(result: boolean): void {
        setIsLoading(true);
        setIsOpen(false);
        if (result) {
            setReload(true);
            setSuccess(true);
            setSnackbarOpen(true);
            setIsLoading(false);
        } else {
            setReload(false);
            setIsLoading(false);
        }
    }

    const [downloadSnackbarOpen, setDownloadSnackbarOpen] = React.useState(false);
    const [downloadSnackbarMessage, setDownloadSnackbarMessage] = React.useState('');
    const [downloadSnackbarSeverity, setDownloadSnackbarSeverity] = React.useState('error');

    // Para los checks
    async function closeModalDownloadHandler(result: any) {
        let resultFile = '';
        let fileName = "InformeSeleccionado.csv";

        let anyDataSelected = false;

        if (result) {
            if (isCheckedConexion) {
                const resultData = await getCountLogByMethodAndDate(start_date, end_date);

                if (resultData && Object.keys(resultData).length > 0) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const csvHeaders = Object.keys(resultData);
                    resultFile += csvHeaders.join(', ') + '\n';
                    const rowData = Object.values(resultData);
                    resultFile += rowData.map(value => `${value}`).join(', ') + '\n';

                    anyDataSelected = true;
                }
                fileName = `Metodo de conexion_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }

            if (isCheckedTiempoUso) {
                const resultData = await getUserLogByDate(start_date, end_date);

                if (resultData) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const lines = resultData.split('\n');
                    let emails = '';
                    let values = '';
                    lines.forEach((line: string) => {
                        const parts = line.split(':');
                        if (parts.length === 2) {
                            const email = parts[0].trim();
                            const value = parts[1].trim();
                            emails += `${email}, `;
                            values += `${value} `;
                        }
                    });

                    resultFile += `${emails}\n${values}\n`;
                    anyDataSelected = true;
                }
                fileName = `Tiempo de uso_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;

            }

            if (isCheckedCantidad) {
                const resultData = await getCountLogByDate(start_date, end_date);
                resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                resultFile += `${resultData}\n`;

                anyDataSelected = anyDataSelected || Boolean(resultData);

                fileName = `Cantidad de ingresos_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }

            if (isCheckedPromedio) {
                const resultData = await getLanguageLogByDate(start_date, end_date);

                if (resultData && Object.keys(resultData).length > 0) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const csvHeaders = Object.keys(resultData);
                    resultFile += csvHeaders.join(', ') + '\n';
                    const rowData = Object.values(resultData);
                    resultFile += rowData.map(value => `${value}`).join(', ') + '\n';

                    anyDataSelected = true;
                }
                fileName = `Promedio de uso de idiomas_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }

            if (isCheckedUsuarios) {
                const resultData = await getCountUserConnected();

                const header = "Username,ConnectedTime,LoginMethod,Language,CreatedAt,AccountExpiration";
                resultFile += `${header}\n`;
                if (Array.isArray(resultData) && resultData.length > 0) {
                    for (const item of resultData) {
                        const Username = item.Username || '-';
                        const ConnectedTime = item.ConnectedTime || '-';
                        const LoginMethod = item.LoginMethod || '-';
                        const Language = item.Language || '-';
                        const CreatedAt = item.CreatedAt || '-';
                        const AccountExpiration = item.AccountExpiration || '-';

                        resultFile += `${Username},${ConnectedTime},${LoginMethod},${Language},${CreatedAt},${AccountExpiration}\n`;

                        anyDataSelected = true;
                    }
                }

                fileName = `Usuarios conectados.csv`;
            }



            if (!anyDataSelected) {
                setDownloadSnackbarMessage("No hay datos disponibles");
                setDownloadSnackbarSeverity("error");
                setDownloadSnackbarOpen(true);

                setReports(false);
                return;
            }

            //Info adicional en la descarga
            if (!isCheckedUsuarios) {
                resultFile += '\n';
                resultFile += '\n';
                const resultData = await getAllLogByDate(start_date, end_date);
                const header = "idloggs,email,create_at,expiration_account,sessionat,login_method,user_details";
                resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                resultFile += `${header}\n`;
                for (const item of resultData) {
                    const idloggs = item.idloggs || '-';
                    const email = item.email || '-';
                    const create_at = item.create_at || '-';
                    const login_method = item.login_method || '-';
                    const os = item.os || '-';
                    const location = item.location || '-';
                    const user_details = item.user_details === 'unknown' ? '-' : item.user_details;
                    const expiration_account = item.expiration_account || '-';
                    const sessionat = item.sessionat || '-';

                    resultFile += `${idloggs},${email},${create_at},${expiration_account},${sessionat},${login_method},${user_details}\n`;
                }
            }

            const blob = new Blob([resultFile], { type: 'text/csv;charset=utf-8' });
            const csvUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setReports(false);
    }


    function handleAttributeChange(result: any) {
        setRowsPerPage(result);
    }

    function handlePageChange(result: any) {
        setPage(result);
    }

    const fechaActual = new Date();
    const ultimoDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);

    // Obtén los componentes de la fecha (día, mes, año)
    const dia = fechaActual.getDate();
    const ultimoDia = ultimoDiaDelMes.getDate();
    const mes = fechaActual.getMonth() + 1; // Los meses son indexados desde 0, por lo que sumamos 1
    const año = fechaActual.getFullYear();

    const [start_date, setStart_date] = React.useState<Dayjs | null>(dayjs(`${año}-${mes}-${dia}`));
    const [end_date, setEnd_date] = React.useState<Dayjs | null>(dayjs(`${año}-${mes}-${ultimoDia}`));

    const [dates, setDates] = useState('');
    const handleDatesChange = (event: any) => {
        setDates(event.target.value);
    };

    const [reports, setReports] = useState(false);
    const handleDownloadClick = () => {
        setReports(true);
    };

    const [isCheckedConexion, setIsCheckedConexion] = useState(false);
    const [isCheckedTiempoUso, setIsCheckedTiempoUso] = useState(false);
    const [isCheckedCantidad, setIsCheckedCantidad] = useState(false);
    const [isCheckedPromedio, setIsCheckedPromedio] = useState(false);
    const [isCheckedUsuarios, setIsCheckedUsuarios] = useState(false);

    const handleCheckboxConexion = () => {
        setIsCheckedConexion(true);
        setIsCheckedTiempoUso(false);
        setIsCheckedCantidad(false);
        setIsCheckedPromedio(false);
        setIsCheckedUsuarios(false);

        setButtonClicked(false);
    };

    const handleCheckboxTiempoUso = () => {
        setIsCheckedConexion(false);
        setIsCheckedTiempoUso(true);
        setIsCheckedCantidad(false);
        setIsCheckedPromedio(false);
        setIsCheckedUsuarios(false);

        setButtonClicked(false);
    };

    const handleCheckboxCantidad = () => {
        setIsCheckedConexion(false);
        setIsCheckedTiempoUso(false);
        setIsCheckedCantidad(true);
        setIsCheckedPromedio(false);
        setIsCheckedUsuarios(false);

        setButtonClicked(false);
    };

    const handleCheckboxPromedio = () => {
        setIsCheckedConexion(false);
        setIsCheckedTiempoUso(false);
        setIsCheckedCantidad(false);
        setIsCheckedPromedio(true);
        setIsCheckedUsuarios(false);

        setButtonClicked(false);
    };

    const handleCheckboxUsuarios = () => {
        setIsCheckedConexion(false);
        setIsCheckedTiempoUso(false);
        setIsCheckedCantidad(false);
        setIsCheckedPromedio(false);
        setIsCheckedUsuarios(true);

        setButtonClicked(false);
    };

    const [data, setData] = useState<LogData[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        const startIndex = newPage * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        setPaginatedData(data.slice(startIndex, endIndex));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setPaginatedData(data.slice(0, rowsPerPage));
    };

    //Para la info de usuarios
    const handleViewClickUsers = async () => {
        try {
            const newData = await getAllLogByDate(start_date, end_date);

            const formattedData = newData.map((row: { login_method: string; email: string; create_at: string; expiration_account: string; sessionat: string; user_details: string; }) => ({
                login_method: row.login_method || '-',
                email: row.email || '-',
                create_at: row.create_at || '-',
                expiration_account: row.expiration_account || '-',
                sessionat: row.sessionat || '-',
                user_details: row.user_details === 'unknown' ? '-' : row.user_details,
            }));

            const startIndex = page * rowsPerPage;
            const endIndex = startIndex + rowsPerPage;

            const paginatedData = formattedData.slice(startIndex, endIndex);

            setPaginatedData(paginatedData);
            setData(formattedData);
        } catch (error) {
            console.error("Error al obtener datos:", error);
        }
    };

    const [buttonClicked, setButtonClicked] = useState(false);
    const [prevDates, setPrevDates] = useState<{ start_date: dayjs.Dayjs | null; end_date: dayjs.Dayjs | null }>({ start_date: null, end_date: null });
    const [loginMethodData, setLoginMethodData] = useState<{ name: string; value: number }[]>([]);
    const [totalConnections, setTotalConnections] = useState(0);
    const [usersCantidad, setUsersCantidad] = useState(0);
    const [useTimeData, setUseTimeData] = useState<{ name: string; value: number }[]>([]);
    const [totalUseTime, setTotalUseTime] = useState(0);
    const [dataUser, setDataUser] = useState<UserData[]>([]);
    const [languageData, setLanguageData] = useState<{ name: string; value: number }[]>([]);
    const [totalLanguages, setTotalLanguages] = useState(0);

    //Para los graficos
    const handleViewClick = async () => {
        try {
            if (isCheckedConexion) {  // Método de conexión
                const newData = await getCountLogByMethodAndDate(start_date, end_date);
                const transformedData = Object.entries(newData).map(([name, value]) => ({
                    name,
                    value: value as number,
                }));
                setLoginMethodData(transformedData);
                const total = transformedData.reduce((acc, entry) => acc + entry.value, 0);
                setTotalConnections(total);

                setButtonClicked(true);
            }

            if (isCheckedTiempoUso) { // Tiempo de uso
                const newDataUser = await getUserLogByDate(start_date, end_date);
                const lines = newDataUser.split('\n');
                const emails: string[] = [];
                const values: number[] = [];
                lines.forEach((line: string) => {
                    const parts = line.split(':');
                    if (parts.length === 2) {
                        const email = parts[0].trim();
                        const value = parseInt(parts[1].trim());
                        emails.push(email);
                        values.push(value);
                    }
                });
                const transformedDataUser = emails.map((email, index) => ({
                    name: email,
                    value: values[index],
                }));
                setUseTimeData(transformedDataUser);
                const userTotal = values.reduce((acc, value) => acc + value, 0);
                setTotalUseTime(userTotal);

                setButtonClicked(true);
            }

            if (isCheckedCantidad) { // Cantidad de ingresos
                const usersCantidad = await getCountLogByDate(start_date, end_date);
                setUsersCantidad(usersCantidad);

                setButtonClicked(true);
            }

            if (isCheckedPromedio) {// Idioma
                const newDataLanguage = await getLanguageLogByDate(start_date, end_date);
                const transformedDataLanguage = Object.entries(newDataLanguage).map(([name, value]) => ({
                    name,
                    value: value as number,
                }));
                setLanguageData(transformedDataLanguage);
                const languageTotal = transformedDataLanguage.reduce((acc, entry) => acc + entry.value, 0);
                setTotalLanguages(languageTotal);

                setButtonClicked(true);
            }

            if (isCheckedUsuarios) { // Usuarios conectados
                const newDataUserConnected = await getCountUserConnected();

                if (Array.isArray(newDataUserConnected) && newDataUserConnected.length > 0) {
                    const formattedData = newDataUserConnected.map((row: any) => ({
                        Username: row.Username || '-',
                        ConnectedTime: row.ConnectedTime || '-',
                        LoginMethod: row.LoginMethod || '-',
                        Language: row.Language === 'unknown' ? '-' : row.Language,
                        CreatedAt: row.CreatedAt || '-',
                        AccountExpiration: row.AccountExpiration || '-',
                    }));
                    setDataUser(formattedData);
                }
                setButtonClicked(true);
            }

        } catch (error) {
            console.error("Error al obtener datos:", error);
        }
    };

    const handleStartDateChange = (newValue: dayjs.Dayjs | null) => {
        setStart_date(newValue);
        if (!prevDates.start_date || !newValue || !newValue.isSame(prevDates.start_date) || !dayjs(end_date).isSame(prevDates.end_date)) {
            setButtonClicked(false);
        }
        setPrevDates({ ...prevDates, start_date: newValue });
    };

    const handleEndDateChange = (newValue: dayjs.Dayjs | null) => {
        setEnd_date(newValue);
        if (!prevDates.end_date || !newValue || !dayjs(start_date).isSame(prevDates.start_date) || !newValue.isSame(prevDates.end_date)) {
            setButtonClicked(false);
        }
        setPrevDates({ ...prevDates, end_date: newValue });
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {isLoading && <LoadingModal />}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px" }}>Reportes</Typography>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "5px" }}>Estadísticas de uso</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', marginLeft: "22px", marginRight: "22px", }}>
                                <div className="box-statistics-use" >
                                    <div style={{ display: 'grid' }}>
                                        <FormControl component="fieldset" sx={{}}>
                                            <FormGroup aria-label="position" sx={{ marginLeft: '20px' }}>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', marginTop: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedConexion} onChange={handleCheckboxConexion} />}
                                                        label="Método de conexión"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedTiempoUso} onChange={handleCheckboxTiempoUso} />}
                                                        label="Tiempo de uso"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedCantidad} onChange={handleCheckboxCantidad} />}
                                                        label="Cantidad de ingresos"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedPromedio} onChange={handleCheckboxPromedio} />}
                                                        label="Promedio de uso de idiomas"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedUsuarios} onChange={handleCheckboxUsuarios} />}
                                                        label="Usuarios conectados (Sin fechas)"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            </FormGroup>

                                            <Button variant="contained" onClick={handleDownloadClick}
                                                sx={{
                                                    backgroundColor: primaryColor,
                                                    color: "white",
                                                    width: "140px",
                                                    height: "40px",
                                                    padding: "8px 16px",
                                                    fontSize: "14px",
                                                    margin: "10px",
                                                    marginBottom: "10px",
                                                    marginTop: "60px",
                                                    "&:hover": {
                                                        backgroundColor: primaryColorHover,
                                                        color: "white",
                                                    },
                                                    "&:active": {
                                                        backgroundColor: primaryColorHover,
                                                        color: "white",
                                                    },
                                                    '@media (min-width: 230px) and (max-width: 670px)': {
                                                        right: "-20%",
                                                    },
                                                    '@media (min-width: 671px) and (max-width: 959px)': {
                                                        right: "-75%",
                                                    },
                                                    '@media (min-width: 960px) and (max-width: 1023px)': {
                                                        right: "-135%",
                                                    },
                                                    '@media (min-width: 1024px) and (max-width: 1327px)': {
                                                        right: "-140%",
                                                    },
                                                    '@media (min-width: 1328px) and (max-width: 1627px)': {
                                                        right: "-165%",
                                                    },
                                                    '@media (min-width: 1628px)': {
                                                        right: "-170%",
                                                    },
                                                }}
                                            >
                                                <FileDownloadOutlinedIcon sx={{ marginLeft: "-5px" }} />
                                                Descargar
                                            </Button>
                                        </FormControl>

                                    </div>
                                    <FormControl sx={{ width: '100%', marginTop: "25px" }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '40px', marginTop: "15px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker label="Fecha inicial*" value={start_date} onChange={handleStartDateChange} disabled={isCheckedUsuarios} />
                                            </LocalizationProvider>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '20px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker label="Fecha final*" value={end_date} onChange={handleEndDateChange} disabled={isCheckedUsuarios} />
                                            </LocalizationProvider>
                                        </Box>

                                    </FormControl>
                                </div>
                                <ModalReports isOpen={reports} openModalHandler={openModalHandler} closeModalHandler={closeModalDownloadHandler}></ModalReports>
                            </Box>

                            <Box sx={{ mt: 4, marginLeft: "22px", marginRight: "22px", }}>
                                <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginBottom: "25px", marginTop: "22px", marginLeft: "5px" }}>Gráficos</Typography>

                                <Button
                                    variant="contained"
                                    onClick={handleViewClick}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "140px",
                                        height: "40px",
                                        padding: "8px 16px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        marginTop: "-20px",
                                        marginBottom: "20px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        '@media (min-width: 230px) and (max-width: 670px)': {
                                            right: "-20%",

                                        },
                                        '@media (min-width: 671px) and (max-width: 959px)': {
                                            right: "-45%",

                                        },
                                        '@media (min-width: 960px) and (max-width: 1023px)': {
                                            right: "-70%",
                                        },
                                        '@media (min-width: 1024px) and (max-width: 1327px)': {
                                            right: "-75%",
                                        },
                                        '@media (min-width: 1328px) and (max-width: 1627px)': {
                                            right: "-80%",
                                        },
                                        '@media (min-width: 1628px)': {
                                            right: "-85%",
                                        },
                                    }}
                                >
                                    Visualizar
                                </Button>


                                {isCheckedConexion && buttonClicked && totalConnections === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedConexion && buttonClicked && totalConnections !== 0 && (
                                        <ConnectionMethodTable
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalConnections={totalConnections}
                                            loginMethodData={loginMethodData}
                                        />
                                    )
                                )}

                                {isCheckedTiempoUso && buttonClicked && useTimeData.length === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedTiempoUso && buttonClicked && useTimeData.length !== 0 && (
                                        <UseTimeTable
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalUseTime={totalUseTime}
                                            useTimeData={useTimeData}
                                        />
                                    )
                                )}

                                {isCheckedCantidad && buttonClicked && usersCantidad === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedCantidad && buttonClicked && usersCantidad !== 0 && (
                                        <AmountIncome
                                            usersCantidad={usersCantidad}
                                        />
                                    )
                                )}

                                {isCheckedPromedio && buttonClicked && languageData.length === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedPromedio && buttonClicked && languageData.length !== 0 && (
                                        <LanguageTable
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalLanguages={totalLanguages}
                                            languageData={languageData}
                                        />
                                    )
                                )}

                                {isCheckedUsuarios && buttonClicked && dataUser.length === 0 ? (
                                    <p><strong>No hay usuarios conectados</strong></p>
                                ) : (
                                    isCheckedUsuarios && buttonClicked && (
                                        <UsersOnlineTable
                                            dataUser={dataUser}
                                        />
                                    )
                                )}

                            </Box>

                            <Box sx={{ mt: 4, marginLeft: "22px", marginRight: "22px", }}>
                                <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginBottom: "25px", marginTop: "22px", marginLeft: "5px" }}>Datos de Usuarios</Typography>

                                <Button variant="contained" onClick={handleViewClickUsers}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "140px",
                                        height: "40px",
                                        padding: "8px 16px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        marginTop: "-20px",
                                        marginBottom: "20px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        '@media (min-width: 230px) and (max-width: 670px)': {
                                            right: "-20%",
                                        },
                                        '@media (min-width: 671px) and (max-width: 959px)': {
                                            right: "-45%",
                                        },
                                        '@media (min-width: 960px) and (max-width: 1023px)': {
                                            right: "-70%",
                                        },
                                        '@media (min-width: 1024px) and (max-width: 1327px)': {
                                            right: "-75%",
                                        },
                                        '@media (min-width: 1328px) and (max-width: 1627px)': {
                                            right: "-80%",
                                        },
                                        '@media (min-width: 1628px)': {
                                            right: "-85%",
                                        },
                                    }}
                                >
                                    Información
                                </Button>
                                <TableContainer component={Paper}>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Método de Conexión</TableCell>
                                                <TableCell align="left">Correo electrónico</TableCell>
                                                <TableCell align="left">Creación de cuenta</TableCell>
                                                <TableCell align="left">Expiración de cuenta</TableCell>
                                                <TableCell align="left">Tiempo de Sesión(seg.)</TableCell>
                                                <TableCell align="left">Idioma</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.length > 0 ? (
                                                paginatedData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">{row.login_method}</TableCell>
                                                        <TableCell align="left">{row.email}</TableCell>
                                                        <TableCell align="left">{row.create_at}</TableCell>
                                                        <TableCell align="left">{row.expiration_account}</TableCell>
                                                        <TableCell align="left">{row.sessionat}</TableCell>
                                                        <TableCell align="left">{row.user_details}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        {isLoading ? "Cargando datos..." : "Seleccione las fechas e información para ver los usuarios. Si no hay datos para esas fechas ingresadas no se visualizara."}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={data.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{ marginBottom: "50px" }}
                                />
                            </Box>
                        </>
                    )
                }
                <FloatingImage />
            </Box >
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={downloadSnackbarOpen} autoHideDuration={6000} onClose={() => setDownloadSnackbarOpen(false)}>
                <Alert onClose={() => setDownloadSnackbarOpen(false)} severity={downloadSnackbarSeverity as AlertColor} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {downloadSnackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );
}

export default StatisticsPage;