import React from 'react';
import { CurrentUserStore } from '../stores';
import { AuthProvider, LoadProvider } from '../providers';

export interface IConstructObjects extends React.HTMLAttributes<HTMLElement> {
}

class ContextStores {
    user: CurrentUserStore;

    constructor() {
        this.user = new CurrentUserStore();
    }
}

class ContextProviders {
    auth: AuthProvider;
    
    /* Global Load Provider */
    load: LoadProvider;

    constructor(stores: ContextStores) {
        this.auth = new AuthProvider(stores.user);
        this.load = new LoadProvider([this.auth]);
    }
}

export interface IAppContext {
    stores: ContextStores;
    providers: ContextProviders;
}

const createContext = (): IAppContext => {
    const stores = new ContextStores();
    const providers = new ContextProviders(stores);
    
    return {
        stores,
        providers,
    };
}

interface IContexts {
    app: IAppContext;
}
export const AppContext = React.createContext<IContexts | null>(null);
export type AppContextType = IContexts;

interface IProviderProps {
    children?: React.ReactNode;
}
interface IProviderState {
    context: IAppContext;
}
export class AppProvider extends React.Component<IProviderProps, IProviderState> {
    state: Readonly<IProviderState> = {
        context: createContext(),
    }

    render() {
        return (
            <AppContext.Provider
            value={
                {
                    app: this.state.context,
                }
            }
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}