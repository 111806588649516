import axios from "axios"
import { API_PORTAL_URL } from "../constants/api"
import { Dayjs } from "dayjs";

export const getUserCountDates = async () => {
    try {
        const response = await axios.get(`${API_PORTAL_URL}/user/count`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getAllLogs = async () => {
    try {
        const response = await axios.get(`${API_PORTAL_URL}/log/all`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getLogByEmail = async (email: string) => {
    try {
        const response = await axios.get(`${API_PORTAL_URL}/log/${email}`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getAllLogByDate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_PORTAL_URL}/log/all/date/`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getLogByMethod = async () => {
    try {
        const response = await axios.get(`${API_PORTAL_URL}/log/count/method/`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

//Cantidad de ingresos
export const getCountLogByDate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_PORTAL_URL}/log/count/all/date`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

//Tiempo promedio de uso
export const getUserLogByDate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_PORTAL_URL}/log/use/date`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

//Promedio de uso de idiomas
export const getLanguageLogByDate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_PORTAL_URL}/log/language/date`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

//Metodo de conexion
export const getCountLogByMethodAndDate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_PORTAL_URL}/log/count/method/date`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

//Usuarios conectados
export const getCountUserConnected = async () => {
    try {
        const response = await axios.get(`${API_PORTAL_URL}/user/count/connected`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}
