import 'dayjs/locale/en-gb';
import { Box, Button, Modal, Typography, TextField, styled } from "@mui/material";
import React, { useRef, useState } from "react";

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});

const StyledFileInput = styled(TextField)`
  input[type='file'] {
    display: none;
  }
`;

type ModalFormularioProps = {
    isOpen: boolean;
    openModalHandler: () => void;
    closeModalHandler: (file: any) => any;
};


const ModalFile: React.FC<ModalFormularioProps> = ({
    isOpen,
    openModalHandler,
    closeModalHandler,
}) => {

    const [imagePreview, setImagePreview] = useState('');


    function handleSubmit(): void {
        closeModalHandler(file);
    }

    const handleCancel = () => {
        closeModalHandler(null);
    };

    const fileInputRef = useRef(null);

    const [file, setFile] = useState('');

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);

    };

    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || '#defaultColor';
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || '#defaultColor';

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        paddingTop: "15px",
                        paddingLeft: "42px",
                        marginBottom: "-50px"
                    }}
                >
                    Editar fondo
                </Typography>
                {(
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <div style={{ display: "flex", gap: "10px", margin: "42px", height: "32px", marginBottom: "-5px" }}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                                    onChange={handleFileChange}
                                />
                            </div>

                            {imagePreview && (
                                <img
                                    src={imagePreview}
                                    alt="Fondo seleccionado"
                                    style={{ maxHeight: "100px", marginTop: "-67px", marginLeft: "430px" }}
                                />
                            )}

                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: 'white',
                                        color: primaryColor,
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ModalFile;
