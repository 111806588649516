import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
interface AvailabilityDataProps {
    start_date: Dayjs | null;
    end_date: Dayjs | null;
    totalAvailability: number;
    availabilityData: { name: string; value: number }[];
}

export const AvailabilityTable: React.FC<AvailabilityDataProps> = ({ start_date, end_date, totalAvailability, availabilityData }) => {
    
    const containerWidth = Math.max(availabilityData.length * 50, 1100);

    return (
        <div style={{ overflowX: 'auto', maxWidth: '100%', overflowY: 'hidden' }}>
        <div style={{ minWidth: 1100, width: containerWidth }}>
            <ResponsiveContainer width={"100%"} height={650}>
                <BarChart
                    data={availabilityData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} angle={75} textAnchor="start" height={150} label={{ position: 'insideBottom' }} />
                    <YAxis dataKey="value" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#00adb5" name="Disponibilidad" />
                </BarChart>
            </ResponsiveContainer>
        </div>
        </div>
    );
};