import React, { useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
interface UserData {
    Username: string;
    ConnectedTime: string;
    LoginMethod: string;
    Language: string;
    CreatedAt: string;
    AccountExpiration: string;
}
interface UsersProps {
    dataUser: UserData[];
}

export const UsersOnlineTable: React.FC<UsersProps> = ({ dataUser }) => {
    const [pageUser, setPageUser] = React.useState(0);
    const [rowsPerPageUser, setRowsPerPageUser] = React.useState(10);
    const [paginatedDataUser, setPaginatedDataUser] = useState<UserData[]>([]);

    const handleChangeRowsPerPageUser = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPageUser(parseInt(event.target.value, 10));
        setPageUser(0);
        setPaginatedDataUser(dataUser.slice(0, rowsPerPageUser));
    };
    const handleChangePageUser = (event: React.MouseEvent<HTMLButtonElement> | null, newPageUser: number) => {
        setPageUser(newPageUser);
        const startIndex = newPageUser * rowsPerPageUser;
        const endIndex = startIndex + rowsPerPageUser;
        setPaginatedDataUser(dataUser.slice(startIndex, endIndex));
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Correo electrónico</TableCell>
                            <TableCell align="left">Tiempo de uso</TableCell>
                            <TableCell align="left">Método de Conexión</TableCell>
                            <TableCell align="left">Idioma</TableCell>
                            <TableCell align="left">Creación de cuenta</TableCell>
                            <TableCell align="left">Expiración de cuenta</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataUser.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell>{user.Username}</TableCell>
                                <TableCell>{user.ConnectedTime}</TableCell>
                                <TableCell>{user.LoginMethod}</TableCell>
                                <TableCell>{user.Language}</TableCell>
                                <TableCell>{user.CreatedAt}</TableCell>
                                <TableCell>{user.AccountExpiration}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={dataUser.length}
                page={pageUser}
                onPageChange={handleChangePageUser}
                rowsPerPage={rowsPerPageUser}
                onRowsPerPageChange={handleChangeRowsPerPageUser}
                sx={{ marginBottom: "50px" }}
            />
        </Box >
    );
}

