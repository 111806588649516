import axios from 'axios';
import { clearSession, getSessionToken } from '../utils/auth';
import { logoutWithCheck, userLogout } from './user';

axios.interceptors.request.use(
  (config) => {
    const token = getSessionToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Si la solicitud fue exitosa, devuelve la respuesta
    return response;
  },
  async (error) => {
    // Manejar errores aquí
    if (error.response) {
      // Error de respuesta del servidor
     
      if(error.response.data && error.response.data.error 
          && error.response.data.error.includes('token')) {
        logoutWithCheck();
      }
    }

    return Promise.reject(error);
  }
);
export default axios;
