import { API_BACKOFFICE_URL } from "../constants/api";
import axios from '../services/interceptor';

export const getAllBanners = async () => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/banner` , { withCredentials: false });

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getBannerByName = async (name: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/banner/all/${name}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getBanner = async (id: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/banner/${id}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const createBanner = async (formData: FormData, apariciones: any) => {
    const response = await axios.post(`${API_BACKOFFICE_URL}/admin/banner/new`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        });

    return response;
}

export const updateBanner = async (id: string, body: any) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/banner/update/${id}`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
          });
    
        return response;
    } catch (err) {
        console.error(err);
    }
}

export const updateBannerPresentation = async (id: string, body: any) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/banner/update/presentation/${id}`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
          });
    
        return response;
    } catch (err) {
        console.error(err);
    }
}

export const updateFileBanner = async (id: string, formData: FormData) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/banner/update/file/${id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
               
            },
          });
    
        return response;
    } catch (err) {
        console.error(err);
    }
}

export const getAvailableWeight = async () => {
    try {
      const response = await axios.get(`${API_BACKOFFICE_URL}/admin/banner/availableweight`);
      return response.data; 
    } catch (err) {
        console.error(err);
    }
  };
  
  export const updateBannerDelete = async (id: string) => {
    try {
        const response = await axios.delete(`${API_BACKOFFICE_URL}/admin/banner/delete/${id}`,{
            headers: {
                'Content-Type': 'application/json',
            }
          });
    
        return response;
    } catch (err) {
        console.error(err);
    }
}


export const updateIncrementClicksBanner = async (id: string) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/banner/update/incrementeclick/${id}`, {
            
          });
    
        return response;
    } catch (err) {
        console.error(err);
    }
}

export const updateIncrementRejectBanner = async (id: string) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/banner/update/incrementereject/${id}`, {
            
          });
    
        return response;
    } catch (err) {
        console.error(err);
    }
}