import React from 'react';
import { Box, Typography } from '@mui/material';

interface IncomeProps {
    usersCantidad: number;
}

export const AmountIncome: React.FC<IncomeProps> = ({ usersCantidad }) => {

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box width={360} sx={{ my: 2, border: `1px solid ${primaryColor}`, marginLeft: "10px", textAlign: 'center', paddingY: '30px', overflowY: 'auto' }}>
                <Typography variant="h5" sx={{ color: '#3D496E' }}>
                    Cantidad de ingresos
                </Typography>
                <Typography variant="h3" sx={{ color: primaryColor, marginTop: "10px" }}>
                    {usersCantidad !== null ? usersCantidad : 'Cargando...'}
                </Typography>
            </Box>
        </div>
    )
}