/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography, TextField, Button, Paper, styled, FormControlLabel, Checkbox } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LogIn } from '../../../../services';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../context';
import FloatingImage from '../../../FloatingImage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ModalRecovery from './Modal/ModalRecovery';

const StyledTextField = styled(TextField)({
    '& .MuiInputLabel-asterisk': {
        color: 'red', // Cambia el color del asterisco a rojo
    },
});

const AuthPage = () => {
    const context = useContext(AppContext)!;
    const { auth } = context.app.providers;
    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [savePassword, setSavePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleUsuarioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser(event.target.value);
    };

    const handleContraseñaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleGuardarContraseñaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSavePassword(event.target.checked);
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedPassword = localStorage.getItem('password');
        const storedSavePassword = localStorage.getItem('savePassword');

        if (storedUser !== null) {
            setUser(storedUser);
        }
        if (storedPassword !== null) {
            setPassword(storedPassword);
        }
        if (storedSavePassword !== null) {
            setSavePassword(JSON.parse(storedSavePassword));
        }
    }, []);

    useEffect(() => {
        if (savePassword) {
            localStorage.setItem('user', user);
            localStorage.setItem('password', password);
        }

        localStorage.setItem('guardarContraseña', JSON.stringify(savePassword));
    }, [user, password, savePassword]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const response = await LogIn(data.get('email') as string, data.get('password') as string);

        if (response) {
            setIsLoading(false);
            setErrorMessage('');
            auth.configureSession(
                {
                    token: response.data!.token,
                    refreshToken: response.data!.refreshToken,
                }
            );
            navigate('/backoffice/cover-edition');
        } else {
            setIsLoading(false);
            setErrorMessage("El usuario o contraseña es incorrecto.");
        }
    };

    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleSnackbarClick = async () => {
        setIsOpen(true);
    };

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(result: boolean): void {
        setIsOpen(result);
    }


    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                {
                    <img src={`../assets/images/wizzie-logo.png`} alt="logo" />
                }
            </Box>
            <Paper elevation={5} sx={{ borderRadius: '1rem', padding: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '382px', flexGrow: 0, flexShrink: 0 }}>
                <Typography sx={{ color: primaryColor, fontFamily: 'Poppins, sans-serif' }} component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <StyledTextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        value={user}
                        onChange={handleUsuarioChange}
                        sx={{
                            '& fieldset': {
                                borderRadius: '0.5rem',
                                '& legend': {
                                    '& span': {
                                        color: '#F68D2E'
                                    }
                                }
                            },
                        }}
                    />
                    <StyledTextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        //type="password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        // onChange={handleContraseñaChange}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            '& fieldset': {
                                borderRadius: '0.5rem',
                                '& legend': {
                                    '& span': {
                                        color: '#F68D2E'
                                    }
                                }
                            },
                        }}
                    />
                    <Button
                        onClick={() => setShowPassword(!showPassword)}
                        sx={{
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            marginLeft: "250px",
                            marginTop: "-95px",
                            '&:hover': {
                                backgroundColor: "transparent",
                            },
                            '&:active': {
                                backgroundColor: "transparent",
                            },
                        }}
                    >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </Button>
                    <Button
                        onClick={handleSnackbarClick}
                        sx={{
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            width: "maxContent",
                            marginLeft: "175px",
                            marginTop: "-55px",
                            fontSize: "12px",
                            padding: 0,
                            color: "rgba(0, 0, 0, 0.87)",
                            textTransform: "none",
                            '&:hover': {
                                backgroundColor: "transparent",
                                color: primaryColor,
                            },
                            '&:active': {
                                backgroundColor: "transparent",
                                color: primaryColor,
                            },
                        }}
                    >
                        ¿Olvidaste tu contraseña?
                    </Button>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={savePassword}
                                onChange={handleGuardarContraseñaChange}
                                color="primary"
                            />
                        }
                        label="Guardar contraseña"
                    />
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        size='large'
                        variant="contained"
                        sx={{
                            mt: 2,
                            borderRadius: '0.5rem',
                            backgroundColor: primaryColor,
                            color: "white",
                            '&:hover': {
                                backgroundColor: primaryColorHover,
                                color: 'white',
                            },
                            '&:active': {
                                backgroundColor: primaryColorHover,
                                color: 'white',
                            }
                        }}>
                        Entrar
                    </Button>
                    {errorMessage && <p style={{ color: 'red', marginBottom: "-10px" }}>{errorMessage}</p>}
                </Box>
            </Paper>
            <FloatingImage />
            <ModalRecovery isOpen={isOpen} openModalHandler={openModalHandler} closeModalHandler={closeModalHandler}></ModalRecovery>
        </Box>
    );
}

export default AuthPage;