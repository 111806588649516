import decodeJwt, { JwtPayload } from 'jwt-decode';

export interface ISessionTokenInfo extends JwtPayload {
    Id: string;
    email?: string;
}

export interface ISession {
    token: string;
    refreshToken: string;
}

const SessionKey = 'session';
export const getSession = () => {
    try {
        const value = localStorage.getItem(SessionKey);
        if (!value) return null;
        const session = JSON.parse(value) as ISession;
        return session;
    } catch(err) {
        return null;
    }
}

export const setSession = (session: ISession) => {
    localStorage.setItem(SessionKey, JSON.stringify(session));
}

export const clearSession = () => {
    localStorage.removeItem(SessionKey);
}

export const verifySession = (session: ISession) => {
    try {
        const decoded = decodeJwt<ISessionTokenInfo>(session.token);
        if (decoded?.exp == null) return true;
        return Date.now() < decoded.exp * 1000;
    } catch(error) {
        return false;
    }
}

export const getSessionToken = () => {
    try {
        const session = getSession();
        return session?.token ?? null;
    } catch(err) {
        return null;
    }
}

export const getSessionTokenDecoded = (session: ISession) => {
    try {
        const decoded = decodeJwt<ISessionTokenInfo>(session.token);
        return decoded ?? null;
    } catch(error) {
        return null;
    }
}

export const getRefreshToken = () => {
    try {
        const session = getSession();
        return session?.refreshToken ?? null;
    } catch(err) {
        return null;
    }
}