import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip, Legend } from 'recharts';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
interface ConnectionMethodTableProps {
    start_date: Dayjs | null;
    end_date: Dayjs | null;
    totalConnections: number;
    loginMethodData: { name: string; value: number }[];
}

export const ConnectionMethodTable: React.FC<ConnectionMethodTableProps> = ({ start_date, end_date, loginMethodData, totalConnections }) => {

    const getColor = (index: number) => {
        const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff6f61', '#40E0D0', '#FFC0CB', '#9966CC', '#FFD700'];
        return colors[index % colors.length];
    };

    return (
        <div>
            <h3>Total de Conexiones: {totalConnections}</h3>

            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        dataKey="value"
                        data={loginMethodData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        label={(entry: { name: any; value: number; }) => `${entry.name} (${((entry.value / totalConnections) * 100).toFixed(2)}%)`}
                    >
                        {loginMethodData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(index)} />
                        ))}
                    </Pie>
                    <Tooltip formatter={(value: any, name: any) => [value, `${name}`]} />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};
