import React from 'react';
import { AppBar, Box, Divider, List, ListItemButton, styled, Toolbar, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import { logoutWithCheck } from '../../../services/user';
import { clearSession } from '../../../utils/auth';

const drawerWidth: number = 360;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const Dashboard = () => {
    const [open, setOpen] = React.useState(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const logout = async () => {
        logoutWithCheck();
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Box>
            <AppBar position="fixed" sx={{ backgroundColor: primaryColor, position: 'absolute' }}>
                <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
                    {
                        <img src={`../assets/images/wizzie-sidebar.png`} alt="logo" />
                    }
                </Toolbar>
            </AppBar>
            <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
                {
                    <img src={`../assets/images/wizzie-sidebar.png`} alt="logo" />
                }
            </Toolbar>
            <Divider sx={{ backgroundColor: '#9CA3AF', opacity: '0.2' }} />
            <Drawer variant="permanent" sx={{ height: '100vh', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.12)' }} open={open}>
                <List component="nav" >

                    <Typography variant="body2" display="block" sx={{ ml: 4, my: 1, color: '#274B5B', fontSize: 18 }} fontWeight={600}>Configuración del sistema</Typography>
                    <Link to='/cover-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >Portada</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/landing-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >Landing Page</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/term-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >Terminos y Condiciones</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/general-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >General</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/management-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >Gestión de usuarios</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/management-group-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >Gestión de grupos</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/role-management-edition' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2, fontSize: 14 }} variant="body2" display="block" >Administradores</Typography>
                        </ListItemButton>
                    </Link>
                    <Typography variant="body2" display="block" sx={{ ml: 4, my: 1, color: '#274B5B', fontSize: 18, marginTop: 5 }} fontWeight={600}>Campañas</Typography>
                    <Link to='/banner' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2 }} variant="body2" display="block" >Banner</Typography>
                        </ListItemButton>
                    </Link>
                    <Typography variant="body2" display="block" sx={{ ml: 4, my: 1, color: '#274B5B', fontSize: 18, marginTop: 5 }} fontWeight={600}>Reportes</Typography>
                    <Link to='/statistics' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2 }} variant="body2" display="block">Estadísticas de uso</Typography>
                        </ListItemButton>
                    </Link>
                    <Link to='/statistics-banner' style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2 }} variant="body2" display="block">Estadísticas de campaña</Typography>
                        </ListItemButton>
                    </Link>
                    <Typography variant="body2" display="block" sx={{ ml: 4, my: 1, color: '#274B5B', fontSize: 18, marginTop: 5 }} fontWeight={600}>Otros</Typography>
                    <Link to="#" onClick={logout} style={{ color: '#3D496E', textDecoration: 'none' }}>
                        <ListItemButton>
                            <Typography sx={{ ml: 2 }} variant="body2" display="block">Salir</Typography>
                        </ListItemButton>
                    </Link>
                </List>
            </Drawer>
        </Box>

    );
}

export default Dashboard;