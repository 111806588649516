import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Icon, TableHead } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';
import { getAllBanners, getBanner, getBannerByName } from '../../../../../../services/banner';
import ModalViewPresentatio from './Modal/ModalViewPresentation';
import ModalEditPresentation from './Modal/ModalEditPresentation';
import ModalDeletePresentation from './Modal/ModalDeletePresentation';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="Primer página"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Página anterior"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Próxima página"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Última página"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number) {
    return { id, name, splash_page, pre_login, pre_registro, landing_page, display_seconds };
}


export default function PresentationTable(props: any) {
    const [page, setPage] = React.useState(props.page ? props.page : 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 5);
    const [rows, setRows] = React.useState<{ id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }[]>([]);
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        if (!props.valueQuery || props.valueQuery.valueQuery === "") {
            getAll();
        } else {
            getByName(props.valueQuery);
        }
    }, []);

    const getAll = async () => {
        const propertyName = "banners";
        const res = await getAllBanners();
        if (res) {
            const aux: { id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }[] = [];
            res[propertyName].forEach((element: { id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }) => {
                aux.push(createData(element.id, element.name, element.splash_page, element.pre_login, element.pre_registro, element.landing_page, element.display_seconds));
            });
            setRows(aux);
            setShow(true);
        }
    }

    const getByName = async (valueQuery: any) => {
        const propertyName = "banner";
        const res = await getBannerByName(valueQuery);
        if (res && res[propertyName]) {
            const aux: { id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }[] = [];
            res[propertyName].forEach((element: { id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }) => {
                aux.push(createData(element.id, element.name, element.splash_page, element.pre_login, element.pre_registro, element.landing_page, element.display_seconds));
            });
            setRows(aux);
            setShow(true);
        }
    }

    // Evitar un salto de diseño al llegar a la última página con filas vacías.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
        props.onChange(parseInt(event.target.value, 10));
    };
    const iconDoneStyle = {
        color: '#B1CC33',
    };

    const iconClearStyle = {
        color: '#EA5A47',
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [currentTab, setCurrentTab] = React.useState(1);

    const handleSnackbarClick = async (row: any) => {
        try {
            const propertyName = "banner";
            let banner = await getBanner(row.id);
            if (banner) {
                const start_date = banner[propertyName]?.start_date.split('T')[0];
                const end_date = banner[propertyName]?.end_date.split('T')[0];
                banner[propertyName].start_date = start_date;
                banner[propertyName].end_date = end_date;
                setSelectedRow(banner[propertyName]);
                setIsOpen(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };
    const handleSnackbarEditClick = async (row: any) => {
        try {
            const propertyName = "banner";
            let banner = await getBanner(row.id);
            if (banner) {
                const start_date = banner[propertyName]?.start_date.split('T')[0];
                const end_date = banner[propertyName]?.end_date.split('T')[0];
                banner[propertyName].start_date = start_date;
                banner[propertyName].end_date = end_date;
                setSelectedRow(banner[propertyName]);
                setIsOpenEdit(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };
    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(): void {
        setIsOpen(false);
    }

    function closeModalEditHandler(): void {
        setIsOpenEdit(false);
    }

    function closeModalDeleteHandler(): void {
        setIsOpenDelete(false);
    }

    const emptyImage = `../assets/images/empty-state.svg`;

    const handleSnackbarDeleteClick = async (row: any) => {
        try {
            const propertyName = "banner";
            let banner = await getBanner(row.id);
            if (banner) {
                const start_date = banner[propertyName]?.start_date.split('T')[0];
                const end_date = banner[propertyName]?.end_date.split('T')[0];
                banner[propertyName].start_date = start_date;
                banner[propertyName].end_date = end_date;
                setSelectedRow(banner[propertyName]);
                setIsOpenDelete(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    const [forceUpdate, setForceUpdate] = React.useState(false);

    const updateBannerList = async () => {
        const propertyName = "banners";
        const res = await getAllBanners();
        if (res) {
            const aux: { id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }[] = [];
            res[propertyName].forEach((element: { id: string, name: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, display_seconds: number }) => {
                aux.push(createData(element.id, element.name, element.splash_page, element.pre_login, element.pre_registro, element.landing_page, element.display_seconds));
            });
            setRows(aux);
            setShow(true);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <TableContainer component={Paper}  sx={{ marginBottom: "50px" }}>
            {(show && <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Nombre</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Splash Page</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Visualizar Splash Page (seg)</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Pre Registro</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Pre Login</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Landing Page</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row" style={{ width: 100 }} align="left">
                                {row.name}
                            </TableCell>
                            <TableCell style={{ width: 100 }} align="center">
                                {row.splash_page ? <Icon style={iconDoneStyle} component={DoneIcon} /> : <Icon style={iconClearStyle} component={ClearIcon} />}
                            </TableCell>
                            <TableCell style={{ width: 190 }} align="center">
                                {row.display_seconds}
                            </TableCell>
                            <TableCell style={{ width: 100 }} align="center">
                                {row.pre_registro ? <Icon style={iconDoneStyle} component={DoneIcon} /> : <Icon style={iconClearStyle} component={ClearIcon} />}
                            </TableCell>
                            <TableCell style={{ width: 100 }} align="center">
                                {row.pre_login ? <Icon style={iconDoneStyle} component={DoneIcon} /> : <Icon style={iconClearStyle} component={ClearIcon} />}
                            </TableCell>
                            <TableCell style={{ width: 110 }} align="center">
                                {row.landing_page ? <Icon style={iconDoneStyle} component={DoneIcon} /> : <Icon style={iconClearStyle} component={ClearIcon} />}
                            </TableCell>

                            <TableCell style={{ width: 80 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={VisibilityIcon} onClick={() => handleSnackbarClick(row)} />
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={EditIcon} onClick={() => handleSnackbarEditClick(row)} />
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={DeleteIcon} onClick={() => handleSnackbarDeleteClick(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            align='center'
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>)}
            {(!show && <img src={emptyImage} alt="Empty" />)}
            <><ModalViewPresentatio isOpen={isOpen} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalHandler}></ModalViewPresentatio></>
            <><ModalEditPresentation isOpen={isOpenEdit} entryData={selectedRow} tab={currentTab} openModalHandler={openModalHandler} closeModalHandler={closeModalEditHandler} updateBannerList={updateBannerList}></ModalEditPresentation></>
            <><ModalDeletePresentation isOpen={isOpenDelete} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalDeleteHandler} updateBannerList={updateBannerList} setShow={setShow} rows={rows}></ModalDeletePresentation></>
        </TableContainer>
    );
}
