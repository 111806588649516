import 'dayjs/locale/en-gb';
import { Box, Button, Modal, Typography, TextField, styled, FormControlLabel, Checkbox, Snackbar } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from "yup";
import ModalSendCode from './ModalSendCode';
import { checkToken, recoveryPassword, resetPassword } from '../../../../../services/user';
import { useNavigate } from 'react-router-dom';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "", // Cambia el color del asterisco a rojo
    },
});

const StyledFileInput = styled(TextField)`
  input[type='file'] {
    display: none;
  }
`;

const initialState = {
    email: '',
    token: '',
    newPassword: '',
    newPasswordRepeat: '',
}

type ModalFormularioProps = {
    isOpen: boolean;
    openModalHandler: () => void;
    closeModalHandler: (create: boolean) => void;
};

const ModalRecovery: React.FC<ModalFormularioProps> = ({ isOpen, openModalHandler, closeModalHandler }) => {
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState(initialState);
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const resetFormData = () => {
        setFormData(initialState);
    };

    const handleNext = async () => {
        setActiveStep(activeStep + 1);
    };

    const handlePrevius = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep - 1);
    };

    function clearRecovery() {
        setEmail("");
        setNewPassword("");
        setNewPasswordRepeat("");
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }

    const handleSuccessModalOpen = () => {
        setSuccessModalOpen(true);
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const [isFormInitialized, setIsFormInitialized] = useState(false);

    useEffect(() => {
        if (isOpen) {

            if (!isFormInitialized) {
                setIsFormInitialized(true);
            }
        } else {
            setIsFormInitialized(false);
        }
    }, [isOpen]);

    const handleOpenModal = () => {
        setIsFormInitialized(true);

        openModalHandler();
    };
   
    //Primer modal de envio de email
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [hasTriedSubmit, setHasTriedSubmit] = useState(false);
    const [recoveryToken, setRecoveryToken] = useState<string | null>(null);

    const handleNextEmail = async () => {
        if (!isEmailValid(email)) {
            setEmailError('Correo electrónico invalido.');
            setHasTriedSubmit(true);
            return;
        }

        const formData = new FormData();
        formData.append('email', email);

        const result = await recoveryPassword(formData);

        // Almacenar el token en el estado para el 3 modal
        if (result && result.token) {
            setRecoveryToken(result.token);
        }

        setActiveStep(activeStep + 1);
    };

    const handleCancel = () => {
        closeModalHandler(false);
        clearRecovery();

        setHasTriedSubmit(false);
        setEmailError('');
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        setEmail(value);

        if (hasTriedSubmit) {
            if (!isEmailValid(value)) {
                setEmailError('Correo electrónico invalido.');
            } else {
                setEmailError('');
            }
        }
    };


    //Segundo modal de envio de codigo
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '', '', '']);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);

    const inputRefs: React.MutableRefObject<HTMLInputElement | null>[] = Array.from(
        { length: 8 },
        () => React.createRef<HTMLInputElement | null>()
    );

    const handleChange = (index: number, value: string) => {
        let updatedCode = [...verificationCode];
        updatedCode[index] = value;

        if (value !== '' && index < updatedCode.length - 1 && inputRefs[index + 1]?.current) {

            const nextInput = inputRefs[index + 1]?.current;
            if (nextInput) {
                nextInput.focus();
            }
        }

        setVerificationCode(updatedCode);
    };

    const handleNextToken = async () => {
        const combinedCode = verificationCode.join('');

        const formData = new FormData();
        formData.append('email', email);
        formData.append('token', combinedCode);

        try {
            const response = await checkToken(formData);

            if (response && response.status === 200 && response.data && response.data.status === 'verified') {
                setActiveStep(activeStep + 1);
            } else {
                console.error('La verificación del token falló');
                setShowError(true);
            }
        } catch (err) {
            console.error(err);
            setShowError(true);
        }
    };


    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

    //Boton - No recibi el codigo
    const openSendCodeModal = async () => {
        setShowSendCodeModal(true);

        const formData = new FormData();
        formData.append('email', email);

        const result = await recoveryPassword(formData);

        const timer: NodeJS.Timeout = setTimeout(() => {
            setShowSendCodeModal(false);
        }, 5000);

        setTimerId(timer);
    };

    useEffect(() => {
        return () => {
            if (timerId !== null) {
                clearTimeout(timerId);
            }
        };
    }, [timerId]);

    //Tercer modal de newpassword
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [savePasswordModal, setSavePasswordModal] = useState(false);

    const handleGuardarContraseñaModalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSavePasswordModal(event.target.checked);
    };

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required("La contraseña es requerida")
            .min(1),
        newPasswordRepeat: Yup.string()
            .test(
                "passwords-match",
                "Las contraseñas no coinciden entre sí",
                function (value) {
                    const { newPassword } = this.parent;
                    if (newPassword === null && value === null) {
                        return true;
                    }
                    return newPassword === value; // Comprobar si las contraseñas coinciden
                }
            )
            .required("La confirmación de la contraseña es requerida"),
    });

    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showSendCodeModal, setShowSendCodeModal] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSubmitPassword = async () => {
        const formValues = {
            newPassword,
            newPasswordRepeat,
        };

        try {
            // Validar las contraseñas antes de enviar el formulario
            await validationSchema.validate(formValues, { abortEarly: false });
            setPasswordsMatch(true);

            if (recoveryToken !== null) {
                const formData = new FormData();
                formData.append('jwt_token', recoveryToken);
                formData.append('new_password', newPassword);

                const response = await resetPassword(formData);

                if (response && response.status === 200 && response.data && response.data.message === 'success') {
                    setSnackbarOpen(true);
                    closeModalHandler(false);
                    
                    clearRecovery();
                    setActiveStep(activeStep - 2);
                }
            }
        } catch (error) {
            setPasswordsMatch(false);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3500}
            >
                <div style={{ backgroundColor: 'white', color: primaryColor, padding: '15px', borderRadius: '5px', border: `2px solid ${primaryColor}`, }}>
                    El cambio fue exitoso
                </div>
            </Snackbar >

            <Modal
                open={isOpen}
                onClose={closeModalHandler}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box>
                    {activeStep === 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "62px",
                                width: "400px",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginTop: "140px",
                                backgroundColor: "#ffffff",
                                borderRadius: '1rem',
                                border: "1 solid #E5E7EB",
                            }}
                        >
                            <Typography sx={{ color: primaryColor, fontSize: "22px", paddingTop: "32px", paddingLeft: "80px", marginBottom: "-55px" }}>
                                Recuperar contraseña
                            </Typography>
                            <Typography sx={{ fontSize: "13px", paddingLeft: "20px", marginBottom: "-70px" }}>
                                Te enviaremos un codigo de verificacion al correo electronico para que puedas recuperar tu contraseña.
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <StyledTextField
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="identity"
                                    autoFocus
                                    variant="outlined"
                                    value={email}
                                    onChange={handleEmailChange}
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        margin: "42px",
                                        height: "32px",
                                        marginBottom: "45px",
                                        alignContent: "center",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                />
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleCancel}
                                        sx={{
                                            backgroundColor: "white",
                                            color: primaryColor,
                                            width: "200px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNextEmail}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            color: "white",
                                            width: "200px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Enviar
                                    </Button>
                                </Box>
                            </Box>
                            {hasTriedSubmit && emailError && (
                                <div style={{ color: "red", marginTop: "-60px", marginLeft: "85px", marginBottom: "15px" }}>{emailError}</div>
                            )}
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "62px",
                                width: "400px",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginTop: "140px",
                                backgroundColor: "#ffffff",
                                borderRadius: '1rem',
                                border: "1 solid #E5E7EB"
                            }}>
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <Typography sx={{ color: primaryColor, fontSize: "22px", paddingTop: "32px", paddingLeft: "80px", marginBottom: "5px" }}>
                                    Código de verificación
                                </Typography>
                                <Typography sx={{ fontSize: "13px", paddingLeft: "20px", marginBottom: "35px" }}>
                                    Ingresa el código que se envio al correo electrónico ingresado.
                                </Typography>

                                {verificationCode.map((digit, index) => (
                                    <TextField
                                        key={index}
                                        variant="outlined"
                                        size="small"
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        inputProps={{
                                            style: {
                                                textAlign: 'center',
                                                fontSize: '16px',
                                                padding: '8px',
                                            },
                                            maxLength: 1, // Limit input to a single character
                                        }}
                                        style={{ width: '30px', marginRight: '0px', marginLeft: '17px', marginBottom: "35px" }}
                                        inputRef={inputRefs[index]}
                                    />
                                ))}

                                <Button
                                    onClick={openSendCodeModal}
                                    sx={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                        width: "maxContent",
                                        marginLeft: "277px",
                                        marginTop: "-55px",
                                        fontSize: "12px",
                                        padding: 0,
                                        color: "rgba(0, 0, 0, 0.87)",
                                        textTransform: "none",
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                            color: primaryColor,
                                        },
                                        '&:active': {
                                            backgroundColor: "transparent",
                                            color: primaryColor,
                                        },
                                    }}
                                >
                                    No recibí el código
                                </Button>
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        marginTop: '-10px'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handlePrevius}
                                        sx={{
                                            backgroundColor: "white",
                                            color: primaryColor,
                                            width: "200px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNextToken}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            color: "white",
                                            width: "200px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Enviar
                                    </Button>
                                </Box>
                            </Box>
                            {showError && (
                                <div style={{ color: "red", marginTop: "-60px", marginLeft: "125px", marginBottom: "15px" }}>
                                    Código incorrecto.
                                </div>
                            )}
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "62px",
                                width: "400px",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginTop: "140px",
                                backgroundColor: "#ffffff",
                                borderRadius: '1rem',
                                border: "1 solid #E5E7EB"
                            }}
                        >

                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <Typography sx={{ color: primaryColor, fontSize: "22px", paddingTop: "32px", paddingLeft: "80px", marginBottom: "-20px" }}>
                                    Actualizar contraseña
                                </Typography>
                                <StyledTextField
                                    id="password"
                                    label="Ingrese nueva contraseña"
                                    name="Ingrese nueva contraseña"
                                    autoComplete="password"
                                    autoFocus
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        margin: "42px",
                                        height: "32px",
                                        alignContent: "center",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                />
                                <Button
                                    onClick={() => setShowPassword(!showPassword)}
                                    sx={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                        marginLeft: "300px",
                                        marginTop: "-115px",
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                        },
                                        '&:active': {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </Button>
                                <StyledTextField
                                    id="password"
                                    label="Confirmación contraseña"
                                    name="Confirmación contraseña"
                                    autoFocus
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPasswordRepeat}
                                    onChange={(e) => setNewPasswordRepeat(e.target.value)}
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        margin: "42px",
                                        height: "32px",
                                        marginTop: "-10px",
                                        alignContent: "center",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                />
                                <Button
                                    onClick={() => setShowPassword(!showPassword)}
                                    sx={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                        marginLeft: "300px",
                                        marginTop: "-115px",
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                        },
                                        '&:active': {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </Button>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={savePasswordModal}
                                            onChange={handleGuardarContraseñaModalChange}
                                            color="primary"
                                        />
                                    }
                                    label="Guardar contraseña"
                                    style={{ marginLeft: "25px", marginTop: "-25px" }}
                                />
                                <Box sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                </Box>
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "-40px",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmitPassword}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            color: "white",
                                            width: "330px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            marginRight: "25px",
                                            "&:hover": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                        }}

                                    >
                                        Enviar
                                    </Button>
                                </Box>
                                {!passwordsMatch && (
                                    <div style={{ color: "red", marginTop: "5px", marginLeft: "45px", marginBottom: "20px" }}>
                                        Las contraseñas no coinciden entre sí.
                                    </div>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Modal >
            <ModalSendCode isOpen={showSendCodeModal} onClose={setShowSendCodeModal} />
        </>
    );
};

export default ModalRecovery;


