import { Box, Button, Modal, Typography, TextField, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { editGroup } from "../../../../../services/group";

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "", // Cambia el color del asterisco a rojo
    },
});

const StyledFileInput = styled(TextField)`
  input[type='file'] {
    display: none;
  }
`;

const initialState = {
    name: '',
    email: '',
    password: '',
};

type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    openModalHandler: () => void;
    closeModalHandler: () => void;
};

const ModalEditGroup: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    openModalHandler,
    closeModalHandler,
}) => {

    useEffect(() => {
        if (isOpen && entryData) {
            setName(entryData.name || "");
            setSessionTime(entryData.sessionTime || "");
            setAccountExpiration(entryData.accountExpiration || "");
        }
    }, [isOpen, entryData]);

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState(initialState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [sessionTime, setSessionTime] = useState('');
    const [accountExpiration, setAccountExpiration] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [isFormInitialized, setIsFormInitialized] = useState(false);

    const resetFormData = () => {
        setFormData(initialState);
    };

    const [errorMessage, setErrorMessage] = useState('');
    const handleNext = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep + 1);
    };

    const handlePrevius = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep - 1);
    };

    function clearBanner() {
        setId("");
        setName("");
        setAccountExpiration('');
        setSessionTime('');
    }

    const handleSave = async () => {
        const body = {
            session_time: parseInt(sessionTime,),
            account_expiration: parseInt(accountExpiration, 10)
        };
        try {
            const result = await editGroup(entryData.id, body);
            if (result) {
                clearBanner();
                closeModalHandler();
                handleSuccessModalOpen();
            } else {
                setErrorMessage("Hubo un error con la edición de grupo.");
            }
        } catch (error) {
            setErrorMessage("Hubo un error en la solicitud.");
        } finally {
            setIsSubmitting(false);
        }
        clearBanner();
        closeModalHandler();
        handleSuccessModalOpen();
    };

    const handleCancel = () => {
        closeModalHandler();
        clearBanner();
        setErrorMessage('');
    };

    const handleSuccessModalOpen = () => {
        setSuccessModalOpen(true);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const validateForm = () => {
        const requiredFields = [name, sessionTime, accountExpiration];
        const isFormValid = requiredFields.every((field) => !!field);
        if (parseInt(sessionTime, 10) < 0) {
            return false;
        }
        if (parseInt(accountExpiration, 10) < 0) {
            return false;
        }
        return isFormValid;
    };

    useEffect(() => {
        if (isOpen) {
            validateForm();

            if (!isFormInitialized) {
                setIsFormInitialized(true);
            }
        } else {
            setIsFormInitialized(false);
        }
    }, [isOpen]);

    const handleOpenModal = () => {
        setIsFormInitialized(true);
        resetFormData();
        openModalHandler();
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography sx={{ color: "#274B5B", fontSize: "22px", paddingTop: "32px", paddingLeft: "42px", marginBottom: "-10px" }}>
                    Editar grupo
                </Typography>

                {activeStep === 0 && (
                    <div>
                        <StyledTextField
                            required
                            id="name"
                            label="Nombre"
                            name="nombre"
                            autoComplete="identity"
                            variant="outlined"
                            value={name}
                            disabled
                            onChange={(event) => {
                                setName(event.target.value);
                                validateForm();
                            }}
                            sx={{
                                display: "flex",
                                gap: "10px",
                                margin: "42px",
                                height: "32px",
                                marginBottom: "45px",
                                marginTop: "10px",
                                alignContent: "center",
                                "& fieldset": {
                                    borderRadius: "0.5rem",
                                    "& legend": {
                                        "& span": {
                                            color: "#F68D2E",
                                        },
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="sessionTime"
                            label="Tiempo de Sesión"
                            name="sessionTime"
                            type="number"
                            autoComplete="identity"
                            variant="outlined"
                            value={sessionTime}
                            onChange={(event) => {
                                setSessionTime(event.target.value);
                                if (event.target.value && parseInt(event.target.value, 10) < 0) {
                                    setErrorMessage("El tiempo de sesión de la cuenta debe ser mayor a 0.");
                                } else {
                                    setErrorMessage('');
                                }
                            }}
                            sx={{
                                display: "flex",
                                gap: "10px",
                                margin: "42px",
                                height: "32px",
                                marginBottom: "45px",
                                marginTop: "10px",
                                alignContent: "center",
                                "& fieldset": {
                                    borderRadius: "0.5rem",
                                    "& legend": {
                                        "& span": {
                                            color: "#F68D2E",
                                        },
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="accountExpiration"
                            label="Expiración de cuenta"
                            name="accountExpiration"
                            autoComplete="identity"
                            variant="outlined"
                            type="number"
                            value={accountExpiration}
                            onChange={(event) => {
                                setAccountExpiration(event.target.value);
                                if (event.target.value && parseInt(event.target.value, 10) < 0) {
                                    setErrorMessage("El tiempo de expiración de la cuenta debe ser mayor a 0.");
                                } else {
                                    setErrorMessage('');
                                }
                                validateForm();
                            }}
                            sx={{
                                display: "flex",
                                gap: "10px",
                                margin: "42px",
                                height: "32px",
                                marginBottom: "30px",
                                marginTop: "10px",
                                alignContent: "center",
                                "& fieldset": {
                                    borderRadius: "0.5rem",
                                    "& legend": {
                                        "& span": {
                                            color: "#F68D2E",
                                        },
                                    },
                                },
                            }}
                        />

                        {errorMessage && <p style={{ color: 'red', marginLeft: '10px' }}>{errorMessage}</p>}

                        <Box
                            sx={{
                                padding: "10px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleCancel}
                                sx={{
                                    backgroundColor: "white",
                                    color: primaryColor,
                                    width: "90px",
                                    padding: "10px",
                                    fontSize: "14px",
                                    margin: "10px",
                                    "&:hover": {
                                        backgroundColor: primaryColor,
                                        color: "white",
                                    },
                                    "&:active": {
                                        backgroundColor: primaryColor,
                                        color: "white",
                                    },
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSave}
                                sx={{
                                    backgroundColor: primaryColor,
                                    color: "white",
                                    width: "90px",
                                    padding: "10px",
                                    fontSize: "14px",
                                    margin: "10px",
                                    "&:hover": {
                                        backgroundColor: primaryColorHover,
                                        color: "white",
                                    },
                                    "&:active": {
                                        backgroundColor: primaryColorHover,
                                        color: "white",
                                    },
                                }}
                                disabled={!validateForm() || isSubmitting}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};
export default ModalEditGroup;
