import 'dayjs/locale/en-gb';
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    styled,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";
import React, { FormEvent, useState } from "react";


const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    openModalHandler: () => void;
    closeModalHandler: () => void;
};

const ModalViewPresentation: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    openModalHandler,
    closeModalHandler,
}) => {

    const [activeStep, setActiveStep] = useState(0);

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }

    const [nombre, setNombre] = useState('');

    const handlNombredChange = (event: any) => {
        setNombre(event.target.value);
    };

    const [display_seconds, setDisplay_seconds] = useState(entryData?.display_seconds);
    const handleDisplaySecondsChange = (event: any) => {
        setDisplay_seconds(event.target.value);
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingLeft: "42px"
                    }}
                >
                    Ver registro
                </Typography>
                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <StyledTextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id="nombre"
                                label="Nombre"
                                name="nombre"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={entryData?.name ? entryData.name : ''}
                                onChange={handlNombredChange}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginTop: "-5px",
                                    marginBottom: "5px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <FormControl component="fieldset" sx={{ marginLeft: '42px', marginTop: '42px' }}>
                                <FormLabel sx={{ color: '#374151', fontSize: '16px' }} component="legend">Intersticial visible:</FormLabel>
                                <FormGroup aria-label="position" sx={{ marginLeft: '58px' }}>
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px', marginTop: '10px' }}
                                        control={<Checkbox value={entryData?.splash_page} checked={entryData?.splash_page} />}
                                        label="SplashPage"
                                        labelPlacement="end"
                                    />
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Visualizar SplashPage (segundos)"
                                        autoComplete="identity"
                                        value={entryData?.display_seconds}
                                        onChange={handleDisplaySecondsChange}
                                        sx={{
                                            marginTop: '-55px',
                                            marginLeft: '140px',
                                            alignContent: "center",
                                            "& fieldset": {
                                                borderRadius: "0.5rem",
                                                "& legend": {
                                                    "& span": {
                                                        color: "#F68D2E",
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px' }}
                                        control={<Checkbox value={entryData?.pre_registro} checked={entryData?.pre_registro} />}
                                        label="Pre-registro"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px' }}
                                        control={<Checkbox value={entryData?.pre_login} checked={entryData?.pre_login} />}
                                        label="Pre-login"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px' }}
                                        control={<Checkbox value={entryData?.landing_page} checked={entryData?.landing_page} />}
                                        label="LandingPage"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={closeModalHandler}
                                    sx={{
                                        backgroundColor: "#F68D2E",
                                        color: 'white',
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        marginTop: "20px",
                                        "&:hover": {
                                            backgroundColor: "#fcb574",
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: "#fcb574",
                                            color: "white",
                                        },
                                    }}
                                >
                                    Aceptar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ModalViewPresentation;