import React, { useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Typography, Button, Tabs, Tab, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ICoverLanguage } from '../../../../services';
import SwipeableViews from 'react-swipeable-views';
import MultimediaTable from './Tables/MultimediaTable';
import PresentationTable from './Tables/PresentationTable';
import LogicTable from './Tables/LogicTable';
import ModalBannerCreate from './Modal/Modal-Create';
import { BannerSearch } from './BannerSearch';
import LoadingModal from '../../../../services/loading';
import FloatingImage from '../../../FloatingImage';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const BannerPage = () => {
    const [success, setSuccess] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [value, setValue] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isOpen, setIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const theme = useTheme();
    const [page, setPage] = React.useState(0);

    useLayoutEffect(
        () => {
            const getAll = async () => {
                const res: ICoverLanguage[] = [];//await GetAllCovers();
                setIsLoading(false);
            }
            //getAll();
            const timeout = setTimeout(() => {
                getAll();
            }, 1000);
        },
        []
    );


    const handleSnackbarClick = async () => {
        setIsOpen(true);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };


    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(result: boolean): void {
        setIsLoading(true);
        setIsOpen(false);
        if (result) {
            setReload(true);
            setSuccess(true);
            setSnackbarOpen(true);
            setIsLoading(false);
        } else {
            setReload(false);
            setIsLoading(false);
        }
    }

    function handleAttributeChange(result: any) {
        setRowsPerPage(result);
    }

    function handlePageChange(result: any) {
        setPage(result);
    }

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;
    return (
        <>
            {isLoading && <LoadingModal />}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px", marginBottom: "-65px" }}>Campañas</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 600, 
                                        ml: '1rem',
                                        marginRight: "22px",
                                        marginTop: "15px",
                                        backgroundColor: primaryColor,
                                        textTransform: 'capitalize',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        },
                                        '&:active': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        }
                                    }}
                                    onClick={handleSnackbarClick}
                                >
                                    Crear Campaña
                                </Button>
                            </Box>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "-15px" }}>Banner</Typography>

                            <Box sx={{ mt: 4, marginLeft: "22px", marginRight: "22px", }}>
                                <Tabs
                                    value={value}
                                    variant="fullWidth"
                                    sx={{
                                        '& .MuiTabs-indicator': {
                                            backgroundColor: primaryColor, // Cambia el color del indicador de la pestaña seleccionada
                                        },
                                        '& .MuiTab-root': {
                                            color: primaryColor, // Cambia el color del texto de las pestañas
                                            '& .MuiTab-root': {
                                                textTransform: 'capitalize', // Capitaliza el texto de las pestañas
                                            },

                                        },
                                        fontSize: 14
                                    }}
                                    onChange={handleChange} centered
                                >
                                    <Tab sx={{ textTransform: 'capitalize' }} label="Multimedia" />
                                    <Tab sx={{ textTransform: 'capitalize' }} label="Presentación" />
                                    <Tab sx={{ textTransform: 'capitalize' }} label="Lógica" />
                                </Tabs>
                            </Box>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', marginLeft: "22px", marginRight: "22px" }}>
                                <BannerSearch value={searchQuery} onChange={setSearchQuery} />
                            </Box>

                            <Box sx={{ mt: 4 }}>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >

                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <MultimediaTable valueQuery={searchQuery} search={reload}
                                            onChange={handleAttributeChange} onChangePage={handlePageChange} rowsPerPage={rowsPerPage} page={page} />
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <PresentationTable valueQuery={searchQuery} search={reload}
                                            onChange={handleAttributeChange} onChangePage={handlePageChange} rowsPerPage={rowsPerPage} page={page} />
                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                        <LogicTable valueQuery={searchQuery} search={reload}
                                            onChange={handleAttributeChange} onChangePage={handlePageChange} rowsPerPage={rowsPerPage} page={page} />
                                    </TabPanel>
                                </SwipeableViews>
                            </Box>
                        </>
                    )
                }
                <FloatingImage />
            </Box >
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {success ? "Cambios guardados" : "Operacion fallida"}
                </Alert>
            </Snackbar>
            <ModalBannerCreate isOpen={isOpen} openModalHandler={openModalHandler} closeModalHandler={closeModalHandler}></ModalBannerCreate>
        </>
    );
}

export default BannerPage;