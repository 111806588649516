import { observable, action, computed, makeObservable } from "mobx";

export class LoadBaseProvider {
    loading: boolean = true;

    constructor() {
        makeObservable(
            this,
            {
                loading: observable,
                setLoading: action,
            }
        );
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }
}

export class LoadProvider {
    constructor(private providers: LoadBaseProvider[]) {
        makeObservable(
            this,
            {
                isLoading: computed,
            }
        );
    }

    get isLoading(): boolean {
        for (let n = 0; n < this.providers.length; ++n)
        {
            if (this.providers[n].loading !== false)
                return true;
        }
        return false;
    }
}