import { Box, Button, Modal, Typography, TextField, styled } from "@mui/material";
import React, { FormEvent, useState, useLayoutEffect } from "react";
import { updateAdminId } from '../../../../../services/user';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    openModalHandler: () => void;
    closeModalHandler: () => void;
    updateAdminList: any;
};

const ModalEditRole: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    openModalHandler,
    closeModalHandler,
    updateAdminList,
}) => {

    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [id, setId] = useState('');
    const [first_name, setFirst_name] = useState<string>('');
    const [last_name, setLast_name] = useState<string>('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [initialState, setInitialState] = useState(true);

    useLayoutEffect(() => {

        if (isOpen && entryData) {

            setFirst_name(entryData.first_name);
            setLast_name(entryData.last_name);
            setEmail(entryData.email);
            setInitialState(false);
        }
       
    }, [isOpen, entryData, initialState]);
 
 
    const handleSave = async () => {

        const data = { id, first_name, last_name, password };

        setActiveStep(0);
        closeModalHandler();

        const body = {
            id: id,
            first_name: first_name,
            last_name: last_name,
            password: password,
        };

        try {
            await updateAdminId(entryData?.id, body);
            
            updateAdminList();
            closeModalHandler();
        } catch (error) {
            console.error('Error al guardar:', error);
        }
    };


    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }
    const handleCancel = () => {
        // Lógica para guardar los datos del formulario
        setActiveStep(0);
        closeModalHandler();
    };

    const handleIdChange = (event: any) => {
        setId(event.target.value);
    };
    const handleFirst_nameChange = (event: any) => {
        setFirst_name(event.target.value);
    };
    const handleLast_nameChange = (event: any) => {
        setLast_name(event.target.value);
    };
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };
  
    const onChange = (event: React.ChangeEvent<any>) => {
        event.persist();
        handleIdChange(event);
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingLeft: "42px",
                        marginBottom: "-40px"
                    }}
                >
                    Editar usuario
                </Typography>

                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <StyledTextField
                                id="first_name"
                                label="Nombre"
                                name="first_name"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={first_name}
                                onChange={(e) => setFirst_name(e.target.value)}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginBottom: "45px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <StyledTextField
                                id="last_name"
                                label="Apellido"
                                name="last_name"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={last_name}
                                onChange={(e) => setLast_name(e.target.value)}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginBottom: "45px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <StyledTextField
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled 
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginBottom: "45px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <StyledTextField
                                id="password"
                                label="Nueva contraseña"
                                name="nueva password"
                                autoComplete="password"
                                autoFocus
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <Button
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                    marginLeft: "540px",
                                    marginTop: "-115px",
                                    '&:hover': {
                                        backgroundColor: "transparent",
                                    },
                                    '&:active': {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </Button>
                            
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: "white",
                                        color: primaryColor,
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}

            </Box>
        </Modal>
    );
};

export default ModalEditRole;