import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Icon, TableHead } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import { getGroups, getGroupsByName } from '../../../../services/group';
import ModalEditGroup from './Modal Groups/ModalEditGroup';
import ModalDeleteGroup from './Modal Groups/ModalDeleteGroup';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Última página"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(id: string, name: string, sessionTime: string, accountExpiration: string) {
    return { id, name, sessionTime, accountExpiration };
}

export default function GroupManagement(props: any) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 5);
    const [rows, setRows] = React.useState<{ id: string; name: string; sessionTime: string; accountExpiration: string; }[]>([]);
    const [currentTab, setCurrentTab] = React.useState(0);
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        if (props.value || !props.valueQuery || props.valueQuery === "") {
            getAllGroups();
        } else {
            getByName(props.valueQuery);
        }
    }, [props.valueQuery, props.value]);

    const getAllGroups = async () => {
        const res = await getGroups();
        if (res) {
            const aux: { id: string; name: string; sessionTime: string; accountExpiration: string; }[] = [];
            res.forEach((element: { id: string; name: string; session_time: string; account_expiration: string; }) => {
                aux.push(createData(element.id, element.name, element.session_time, element.account_expiration));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const getByName = async (valueQuery: any) => {

        const res = await getGroupsByName(valueQuery);
        const aux: { id: string; name: string; sessionTime: string; accountExpiration: string; }[] = [];
        if (res) {
            res.forEach((element: { id: string; name: string; sessionTime: string; accountExpiration: string; }) => {
                aux.push(createData(element.id, element.name, element.sessionTime, element.accountExpiration));
            });
            //aux.push(createData(res.id, res.name, res.sessionTime, res.accountExpiration));
            setRows(aux);
            setShow(true);
        } else {
            setShow(false);
        }

    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.onChange(parseInt(event.target.value, 10));
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const handleSnackbarEditClick = async (row: any) => {
        if (row) {
            setSelectedRow(row);
            setIsOpenEdit(true);
        }
    };

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(): void {
        setIsOpen(false);
    }

    function closeModalEditHandler(): void {
        setIsOpenEdit(false);
        getAllGroups();
    }

    function closeModalDeleteHandler(): void {
        setIsOpenDelete(false);
        getAllGroups();
    }

    const emptyImage = `../assets/images/empty-state.svg`;

    const handleSnackbarDeleteClick = async (row: any) => {
        if (row) {
            setSelectedRow(row);
            setIsOpenDelete(true);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <TableContainer component={Paper}>
            {(show && <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Nombre</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Tiempo de sesión</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Tiempo de duración</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell style={{ width: 200 }} align="left">
                                {row.name}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="left">
                                {row.sessionTime}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="left">
                                {row.accountExpiration}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="right">
                                <Icon style={{ cursor: 'pointer' }} component={EditIcon} onClick={() => handleSnackbarEditClick(row)} />
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={DeleteIcon} onClick={() => handleSnackbarDeleteClick(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            align='right'
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>)}
            {(!show && <img src={emptyImage} alt="Empty" />)}
            <><ModalEditGroup isOpen={isOpenEdit} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalEditHandler} ></ModalEditGroup></>
            <><ModalDeleteGroup isOpen={isOpenDelete} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalDeleteHandler}></ModalDeleteGroup></>
        </TableContainer>

    );
}
