import axios from "axios"
import { API_BACKOFFICE_URL } from "../constants/api"

export const getGroups = async () => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/groups`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getGroup = async (groupName: string) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/group/${groupName}`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getGroupsByName = async (groupName: string) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/groups/${groupName}`);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const createGroup = async (body: any) => {
    try {
        const response = await axios.post(`${API_BACKOFFICE_URL}/admin/group/new`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const editGroup = async (groupId: string, body: any) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/group/update/${groupId}`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const deleteGroup = async (groupId: string) => {
    try {
        const response = await axios.delete(`${API_BACKOFFICE_URL}/admin/group/delete/${groupId}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}