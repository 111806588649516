import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { useContext, useState } from 'react';
import { LocaleProvider, LOCALES } from './lang';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import AuthPage from './components/pages/backoffice/AuthPage';
import CoverEditionPage from './components/pages/backoffice/CoverEditionPage';
import { AppContext, AppProvider } from './context';
import Dashboard from './components/shared/Dashboard';
import BannerPage from './components/pages/backoffice/Banner';
import GeneralEdition from './components/pages/backoffice/General/index';
import LandingEdition from './components/pages/backoffice/LandingEdition';
import Management from './components/pages/backoffice/UserManagement';
import RoleManagement from './components/pages/backoffice/UserRoleManagement';
import StatisticsPage from './components/pages/backoffice/Statistics';
import StatisticsBanner from './components/pages/backoffice/StatisticsCampaign';
import TermsAndConditions from './components/pages/backoffice/TermURL';

const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

const theme = createTheme({
	palette: {
		primary: {
			main: '#ffffff',
		},
		secondary: {
			main: '#3D496E',
		}
	},
	typography: {
		fontFamily: [
			'NunitoSans',
			'sans-serif',
			'Poppins',
			'Poppins-Thin'
		].join(','),
	},
});

const backofficetheme = createTheme({
	palette: {
		primary: {
			main: primaryColor,
		},
		secondary: {
			main: '#8C8C8C',
		}
	},
	typography: {
		fontFamily: [
			'-apple-system',
			'Nunito Sans',
			'sans-serif',
			'Poppins',
			'Poppins-Thin'
		].join(','),
	},
});


const AuthSecurity = observer(() => {
	const context = useContext(AppContext)!;
	const { load } = context.app.providers;

	if (load.isLoading) {
		return (
			<>
				LOADING
			</>
		);
	}

	const { user } = context.app.stores;

	if (user.isLoggedIn) {
		return (
			<div style={{ display: 'flex' }}>
				<Dashboard />
				<Routes>
					<Route path='/cover-edition' element={<CoverEditionPage />} />
					<Route path='/landing-edition' element={<LandingEdition />} />
					<Route path='/term-edition' element={<TermsAndConditions />} />
					<Route path='/general-edition' element={<GeneralEdition />} />
					<Route path='/management-edition' element={<Management isGroup={false} />} />
					<Route path='/management-group-edition' element={<Management isGroup={true} />} />
					<Route path='/role-management-edition' element={<RoleManagement />} />
					<Route path='/banner' element={<BannerPage />} />
					<Route path='/statistics' element={<StatisticsPage />} />
					<Route path='/statistics-banner' element={<StatisticsBanner />} />
					<Route
						path="*"
						element={<Navigate to='/cover-edition' replace />}
					/>
				</Routes>
			</div>
		)
	}

	return (
		<Routes>
			<Route path='/' element={<AuthPage />} />
			<Route
				path="*"
				element={<Navigate to='/' replace />}
			/>
		</Routes>
	);
});


function App() {

	const [currentLocale, setCurrentLocale] = useState<string>(LOCALES.ENGLISH)
	const [width, setWidth] = useState<number>(window.innerWidth);

	const isMobile = width <= 768;

	return (
		<LocaleProvider locale={currentLocale}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<Routes>

						<Route path='/*' element={
							<ThemeProvider theme={backofficetheme}>
								<AppProvider>
									<AuthSecurity />
								</AppProvider>
							</ThemeProvider>
						}
						/>

					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</LocaleProvider>
	);
}

export default App;
