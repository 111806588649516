import { ISessionTokenInfo } from "../../utils/auth";
import { observable, action, makeObservable, computed } from "mobx";

export class CurrentUserStore {
    token: string | null = null;
    info: ISessionTokenInfo | null = null;

    constructor() {
        makeObservable(
            this,
            {
                token: observable,
                info: observable,
                isLoggedIn: computed,
                set: action,
                clear: action,
            }
        );
    }

    get isLoggedIn() {
        return this.token != null;
    }

    set({
        token,
        info,
    }: {
        token?: string;
        info?: ISessionTokenInfo
    }) {
        this.token = token ?? this.token;
        this.info = info ?? this.info;
    }

    clear() {
        this.token = null;
        this.info = null;
    }
}