import axios from "axios"
import { API_BACKOFFICE_URL } from "../constants/api";
import { Dayjs } from "dayjs";

//Informe de disponibilidad
export const getBannerAvailability = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/logs/banner/availability`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

//Tasa de aparicion
export const getBannerAppearanceRate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/logs/banner/appearancerate`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

// Informe de impacto
export const getBannerImpactRate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/logs/banner/impactrate`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

// Informe de tasa de conversión
export const getBannerConversionRate = async (start_date: Dayjs | null, end_date: Dayjs | null) => {
    try {
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : '';
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : '';

        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/logs/banner/conversionrate`, {
            params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
    }
}