import React from 'react'
import { TextField, InputAdornment, IconButton, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


export const BannerSearch = ({ value, onChange }: { value: string; onChange: (newValue: string) => void }) => {

    return (
        <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item xs={4} sx={{marginTop: "10px", marginBottom: "-15px" }}> {/* Define el ancho deseado aquí */}
                <TextField
                    label="Ingresar nombre del banner"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton disabled>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disabled>

                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            backgroundColor: 'white',
                            cursor: 'pointer'
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};