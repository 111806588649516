import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FloatingImage from '../../../../../FloatingImage';

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: '#3D496E',
        '& legend': {
            '& span': {
                color: '#3D496E'
            }
        }
    },
}));

type EditContentFormProps = {
    propsSpanish: {
        title: string;
        description: string;
        setTitle: (value: string) => void;
        setDesc: (value: string) => void;
    };
    propsPortugese: {
        title: string;
        description: string;
        setTitle: (value: string) => void;
        setDesc: (value: string) => void;
    };
    propsEnglish: {
        title: string;
        description: string;
        setTitle: (value: string) => void;
        setDesc: (value: string) => void;
    };

    onSelectionChange: (selectedValue: string) => void;
};
const EditContentForm = ({ propsSpanish, propsEnglish, propsPortugese, onSelectionChange }: EditContentFormProps) => {

    const [language, setLanguage] = useState<string>('spanish');
    const [currentTitle, setCurrentTitle] = useState<string>('');
    const [currentDesc, setCurrentDesc] = useState<string>('');

    const handleSelectionChange = (event: any) => {
        const selectedValue = event.target.value;
        // Llamamos a la función del componente padre
        onSelectionChange(selectedValue);
    };

    useEffect(
        () => {
            switch (language) {
                case 'spanish':
                    {
                        setCurrentTitle(propsSpanish.title);
                        setCurrentDesc(propsSpanish.description);
                    }
                    break;
                case 'english':
                    {
                        setCurrentTitle(propsEnglish.title);
                        setCurrentDesc(propsEnglish.description);
                    }
                    break;
                case 'portuguese':
                    {
                        setCurrentTitle(propsPortugese.title);
                        setCurrentDesc(propsPortugese.description);
                    }
                    break;
                default:
                    {
                        setCurrentTitle(propsSpanish.title);
                        setCurrentDesc(propsSpanish.description);
                    }
                    break;
            }
        },
        [language]
    );


    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        switch (language) {
            case 'spanish':
                {
                    propsSpanish.setTitle(newValue);   // Usar la función setter para actualizar el estado
                }
                break;
            case 'english':
                {
                    propsEnglish.setTitle(newValue);
                }
                break;
            case 'portuguese':
                {
                    propsPortugese.setTitle(newValue);
                }
                break;
            default:
                {
                    propsSpanish.setTitle(newValue);
                }
                break;

        }
        setCurrentTitle(newValue);
    };


    const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        switch (language) {
            case 'spanish':
                {
                    propsSpanish.setDesc(newValue);   // Usar la función setter para actualizar el estado
                }
                break;
            case 'english':
                {
                    propsEnglish.setDesc(newValue);
                }
                break;
            case 'portuguese':
                {
                    propsPortugese.setDesc(newValue);
                }
                break;
            default:
                {
                    propsSpanish.setDesc(newValue);
                }
                break;

        }
        setCurrentDesc(newValue);
    };

    const handleLanguageChange = (event: SelectChangeEvent) => {
        handleSelectionChange(event);
        setLanguage(event.target.value as string);

    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Box >
            <TextInput
                margin="normal"
                value={currentTitle}
                onChange={handleTitleChange}
                sx={{ width: '100%' }}
                name="title"
                label="Título"
                type="title"
                id="title"
                autoComplete="title"
            />
            <TextInput
                margin="normal"
                value={currentDesc}
                onChange={handleDescChange}
                sx={{ width: '100%' }}
                name="description"
                label="Descripción"
                type="description"
                id="description"
                autoComplete="description"
            />
            <FormControl sx={{ mt: '1rem', width: '100%' }}>
                <InputLabel id="language-select-label">Idioma</InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    sx={{ borderRadius: '1.25rem' }}
                    value={language}
                    label="Idioma"
                    onChange={handleLanguageChange}
                >
                    <MenuItem value={"spanish"}>Castellano</MenuItem>
                    <MenuItem value={"english"}>Inglés</MenuItem>
                    <MenuItem value={"portuguese"}>Portugués</MenuItem>
                </Select>
            </FormControl>

            <FloatingImage />
        </Box>
    )
}

export default EditContentForm