import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    styled,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import React, { FormEvent, useState, useEffect, useLayoutEffect } from "react";
import { updateUserId } from '../../../../../services/user';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getGroups } from "../../../../../services/group";

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    openModalHandler: () => void;
    closeModalHandler: () => void;
    updateUserList: any;
};

const ModalEdit: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    openModalHandler,
    closeModalHandler,
    updateUserList,
}) => {

    const [activeStep, setActiveStep] = useState(0);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [initialState, setInitialState] = useState(true);
    const [availableGroups, setAvailableGroups] = useState<{ id: string, name: string }[]>([]);
    const [groupid, setGroupid] = useState<string>('');

    useLayoutEffect(() => {

        if (isOpen && entryData) {
            setName(entryData.name);
            setEmail(entryData.email);
            setGroupid(entryData.groupid);
            setInitialState(false);
        }

    }, [isOpen, entryData]);


    const handleSave = async () => {
        const data = { name, password, groupid };

        setActiveStep(0);
        closeModalHandler();

        const body = {
            name: name,
            password: password,
            groupid: groupid,
        };

        try {
            await updateUserId(entryData?.id, body);

            updateUserList();
            closeModalHandler();
        } catch (error) {
            console.error('Error al guardar:', error);
        }
    };

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }
    const handleCancel = () => {
        // Lógica para guardar los datos del formulario
        entryData = null;
        setActiveStep(0);
        closeModalHandler();
    };

    const handleIdChange = (event: any) => {
        setId(event.target.value);
    };
    const handleNameChange = (event: any) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };
    const onChange = (event: React.ChangeEvent<any>) => {
        event.persist();
        handleIdChange(event);
    };

    const handleGroupsChange = (event: any) => {
        setGroupid(event.target.value);
    };

    useEffect(() => {
        if (isOpen) {
            fetchGroups();
        }
    }, [isOpen]);

    const fetchGroups = async () => {
        try {
            const groupsData = await getGroups();
            setAvailableGroups(groupsData);
        } catch (error) {
            console.error("Error al obtener los grupos:", error);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingLeft: "42px",
                        marginBottom: "-40px"
                    }}
                >
                    Editar usuario
                </Typography>

                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <StyledTextField
                                id="name"
                                label="Nombre"
                                name="nombre"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginBottom: "45px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <StyledTextField
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginBottom: "45px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <StyledTextField
                                id="password"
                                label="Nueva contraseña"
                                name="password"
                                autoComplete="password"
                                autoFocus
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <Button
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                    marginLeft: "540px",
                                    marginTop: "-115px",
                                    '&:hover': {
                                        backgroundColor: "transparent",
                                    },
                                    '&:active': {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </Button>


                            {availableGroups && availableGroups.length > 0 && (
                                <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                    <InputLabel sx={{ marginLeft: "42px", marginTop: "-35px" }}>Grupo</InputLabel>
                                    <Select
                                        required
                                        id="groupid"
                                        label="Grupo"
                                        name="groups"
                                        autoComplete="identity"
                                        value={groupid}
                                        onChange={handleGroupsChange}
                                        sx={{
                                            display: "flex",
                                            marginLeft: "42px",
                                            marginRight: "42px",
                                            marginBottom: "15px",
                                            marginTop: "-35px",
                                            "& fieldset": {
                                                borderRadius: "0.5rem",
                                                "& legend": {
                                                    "& span": {
                                                        color: "#F68D2E",
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        {availableGroups.map((group) => (
                                            <MenuItem key={group.name} value={group.id}>
                                                {group.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: "white",
                                        color: primaryColor,
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}

            </Box>
        </Modal>
    );
};

export default ModalEdit;