import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    styled,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { updateBannerPresentation } from '../../../../../../../services/banner';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    tab: number;
    openModalHandler: () => void;
    closeModalHandler: () => void;
    updateBannerList: any;
};

const ModalEditPresentation: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    tab,
    openModalHandler,
    closeModalHandler,
    updateBannerList,
}) => {

    const [activeStep, setActiveStep] = useState(0);

    const [nombre, setNombre] = useState('');
    const [isCheckedSplash, setIsCheckedSplash] = useState(entryData?.splash_page);
    const [isCheckedRegistro, setIsCheckedRegistro] = useState(entryData?.pre_registro);
    const [isCheckedPreLogin, setIsCheckedPreLogin] = useState(entryData?.pre_login);
    const [isCheckedLanding, setIsCheckedLanding] = useState(entryData?.landing_page);
    const [file, setFile] = useState('');
    const [display_seconds, setDisplay_seconds] = useState(entryData?.display_seconds);

    useEffect(() => {
        try {

            if (entryData) {
                setIsCheckedSplash(entryData.splash_page);
                setIsCheckedRegistro(entryData.pre_registro);
                setIsCheckedPreLogin(entryData.pre_login);
                setIsCheckedLanding(entryData.landing_page);
                setDisplay_seconds(entryData.display_seconds);
            }

            if (entryData && entryData.file) {
                setFile(entryData.file);
            }

        }
        catch (error) {
            console.error(error);
        }
    });


    const handleSave = async () => {
        const displaySecondsValue = parseInt(display_seconds, 10);
        const data = { nombre, isCheckedSplash, isCheckedRegistro, isCheckedPreLogin, isCheckedLanding, displaySecondsValue };

        const body = {
            splash_page: isCheckedSplash,
            pre_registro: isCheckedRegistro,
            pre_login: isCheckedPreLogin,
            landing_page: isCheckedLanding,
            display_seconds: displaySecondsValue,
        };

        try {
            await updateBannerPresentation(entryData?.id, body);
            // Luego de guardar, llama a la función para actualizar la lista
            updateBannerList();
            closeModalHandler();
        } catch (error) {
            console.error('Error al guardar:', error);
        }
    };

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }
    const handleCancel = () => {
        // Lógica para guardar los datos del formulario
        setActiveStep(0);
        closeModalHandler();
    };

    const handlNombredChange = (event: any) => {
        setNombre(event.target.value);
    };

    const handleCheckboxSplash = (event: any) => {
        entryData.splash_page = event.target.checked;
        setIsCheckedSplash(event.target.checked);
    };

    const handleCheckboxRegistro = (event: any) => {
        entryData.pre_registro = event.target.checked;
        setIsCheckedRegistro(event.target.checked);
    };

    const handleCheckboxPreLogin = (event: any) => {
        entryData.pre_login = event.target.checked;
        setIsCheckedPreLogin(event.target.checked);
    };

    const handleCheckboxLanding = (event: any) => {
        entryData.landing_page = event.target.checked;
        setIsCheckedLanding(event.target.checked);
    };

    const handleDisplaySecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^\d*\.?\d*$/;
        if (value === '' || (regex.test(value) && parseInt(value) !== 0)) {
            entryData.display_seconds = value;
            setDisplay_seconds(value);
        }
    };


    const handleChangeStartDate = (event: any) => {

    };

    const fileInputRef = useRef(null);

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        // Realizar acciones con el archivo seleccionado
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        paddingTop: "32px",
                        paddingLeft: "42px"
                    }}
                >
                    Editar registro
                </Typography>
                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <StyledTextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id="nombre"
                                label="Nombre"
                                name="nombre"
                                autoComplete="identity"
                                variant="outlined"
                                value={entryData?.name ? entryData.name : ''}
                                onChange={handlNombredChange}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <FormControl component="fieldset" sx={{ marginLeft: '42px', marginTop: '42px' }}>
                                <FormLabel sx={{ color: '#374151', fontSize: '16px' }} component="legend">Intersticial visible:</FormLabel>
                                <FormGroup aria-label="position" sx={{ marginLeft: '58px' }}>
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px', marginTop: '10px' }}
                                        control={<Checkbox value={entryData?.splash_page} checked={entryData?.splash_page} onChange={handleCheckboxSplash} />}
                                        label="SplashPage"
                                        labelPlacement="end"
                                    />
                                    <StyledTextField
                                        label="Visualizar SplashPage (segundos)"
                                        autoComplete="identity"
                                        value={display_seconds}
                                        variant="outlined"
                                        onChange={handleDisplaySecondsChange}
                                        sx={{
                                            marginTop: '-55px',
                                            marginLeft: '140px',
                                            alignContent: "center",
                                            "& fieldset": {
                                                borderRadius: "0.5rem",
                                                "& legend": {
                                                    "& span": {
                                                        color: "#F68D2E",
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                    </StyledTextField>

                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px' }}
                                        control={<Checkbox value={entryData?.pre_registro} checked={entryData?.pre_registro} onChange={handleCheckboxRegistro} />}
                                        label="Pre-registro"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px' }}
                                        control={<Checkbox value={isCheckedPreLogin} checked={entryData?.pre_login} onChange={handleCheckboxPreLogin} />}
                                        label="Pre-login"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="top"
                                        sx={{ marginBottom: '10px' }}
                                        control={<Checkbox value={entryData?.landing_page} checked={entryData?.landing_page} onChange={handleCheckboxLanding} />}
                                        label="LandingPage"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: "white",
                                        color: primaryColor,
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal >
    );
};

export default ModalEditPresentation;
function useLayoutEffect(arg0: () => void, arg1: any[]) {
    throw new Error('Function not implemented.');
}

