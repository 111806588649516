import React from 'react';

const FloatingImage = () => {
  return (
    <div style={{ position: 'fixed', margin: '0px', zIndex: 999, right: '10px', bottom: '-5px' }}>
      <img src={require("./image/wizzieLogo.png")} alt="Logo" style={{ padding: '10px', height: '61px' }} />
    </div>
  );
}

export default FloatingImage;
