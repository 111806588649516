import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

type ModalFormularioProps = {
    isOpen: boolean;
    openModalHandler: () => void;
    closeModalHandler: (result: boolean) => void;
};

const ModalReports: React.FC<ModalFormularioProps> = ({
    isOpen,
    openModalHandler,
    closeModalHandler,


}) => {

    const [reports, setReports] = useState(false);

    function handleSubmit(): void {
        closeModalHandler(true);
    }


    const handleCancel = () => {
        closeModalHandler(false);
    };


    const handleReportsChange = (event: any) => {
        setReports(event.target.value);
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "350px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        paddingTop: "20px",
                        paddingLeft: "80px",
                        marginBottom: "-20px"
                    }}
                >
                    ¿Descargar CSV?
                </Typography>
                {(
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: "white",
                                        color: primaryColor,
                                        width: "150px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "150px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Aceptar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ModalReports;
