import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { FormControl, Icon, InputLabel, MenuItem, Select, SelectChangeEvent, TableHead } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import ModalEdit from './Modal/ModalEdit';
import ModalDelete from './Modal/ModalDelete';
import { getAllUser, getAllUserGroups, getAllUserId, getUserByName } from '../../../../services/user';
import { getGroups } from '../../../../services/group';


const initialState = {
    group_id: '',
};
interface GroupMapping {
    [key: string]: string;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Última página"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(id: string, name: string, email: string, password: string, groupid: string) {
    return { id, name, email, password, groupid };
}

interface Group {
    id: string;
    name: string;
}

export default function UserManagement(props: any) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 5);
    const [rows, setRows] = React.useState<{ id: string; name: string; email: string; password: string, groupid: string; }[]>([]);
    const [currentTab, setCurrentTab] = React.useState(0);
    const [show, setShow] = React.useState(false);

    const [selectedGroup, setSelectedGroup] = useState('');
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        if (props.value || !props.valueQuery || props.valueQuery === "") {
            getAll();
        } else {
            getByName(props.valueQuery);
        }
    }, [props.value, props.valueQuery, selectedGroup]);

    const getAll = async () => {
        try {
            let res;
            if (selectedGroup === "") {
                res = await getAllUser();
            } else {
                res = await getAllUserGroups(selectedGroup);
            }

            if (res) {
                const aux: { id: string; name: string; email: string; password: string, groupid: string; }[] = [];
                res.forEach((element: { id: string; name: string; email: string; password: string, groupid: string; }) => {
                    aux.push(createData(element.id, element.name, element.email, element.password, element.groupid));
                });

                setRows(aux);
                setShow(true);
                setNoResults(false);
            } else {
                setNoResults(true);
            }
        } catch (error) {
            console.error("Error al obtener los usuarios:", error);
            setNoResults(true);
        }
    }

    const getByName = async (valueQuery: any) => {
        const res = await getUserByName(valueQuery);

        if (res) {
            const aux: { id: string; name: string; email: string; password: string, groupid: string; }[] = [];
            res.forEach((element: { id: string; name: string; email: string; password: string, groupid: string; }) => {
                aux.push(createData(element.id, element.name, element.email, element.password, element.groupid));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.onChange(parseInt(event.target.value, 10));
    };


    // Evitar un salto de diseño al llegar a la última página con filas vacías.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [groupid, setGroupidl] = useState<string>('');

    const handleSnackbarClick = async (row: any) => {
        try {

            let user = await getAllUserId(row.id);
            if (user) {

                user.name = name;
                user.email = email;
                user.password = password;

                setSelectedRow(user);
                setIsOpen(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio- usuario:', error)
        }
    };

    const handleSnackbarEditClick = async (row: any) => {
        try {
            let user = await getAllUserId(row.id);
            if (user) {
                setName(user.name);
                setEmail(user.email);
                setPassword(user.password);


                setSelectedRow(user);
                setIsOpenEdit(true);
            }
        } catch (error) {
            console.error('Error al obtener la respuesta del servicio - usuario:', error)
        }
    };

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(): void {
        setIsOpen(false);
    }

    function closeModalEditHandler(): void {
        setIsOpenEdit(false);
    }

    function closeModalDeleteHandler(): void {
        setIsOpenDelete(false);
    }

    const emptyImage = `../assets/images/empty-state.svg`;

    const handleSnackbarDeleteClick = async (row: any) => {
        try {
            let user = await getAllUserId(row.id);
            if (user) {

                user.name = name;
                user.email = email;
                user.password = password;

                setSelectedRow(user);
                setIsOpenDelete(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    const [forceUpdate, setForceUpdate] = React.useState(false);

    const updateUserList = async () => {
        const res = await getAllUser();
        if (res) {
            const aux: { id: string; name: string; email: string; password: string; groupid: string; }[] = [];
            res.forEach((element: { id: string; name: string; email: string; password: string; groupid: string; }) => {
                aux.push(createData(element.id, element.name, element.email, element.password, element.groupid));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const [groups, setGroups] = React.useState<Record<string, string>>({});

    React.useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        try {
            const groupsData: Group[] = await getGroups();
            if (groupsData) {
                const groupMap: Record<string, string> = {};
                groupsData.forEach((group) => {
                    groupMap[group.id] = group.name;
                });
                setGroups(groupMap);
            }
        } catch (error) {
            console.error("Error al obtener los grupos:", error);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <TableContainer component={Paper}>
            <FormControl sx={{ mt: '1rem', width: '100%', marginTop: "10px", marginBottom: "15px", marginLeft: "10px" }}>
                <InputLabel htmlFor="group-filter">Buscar por Grupo</InputLabel>
                <Select
                    labelId="group-filter"
                    label="Buscar por Grupo"
                    id="group-filter"
                    sx={{ width: "200px", height: "40px", marginTop: "5px" }}
                    value={selectedGroup}
                    onChange={(event: SelectChangeEvent) => setSelectedGroup(event.target.value)}
                >
                    <MenuItem value="">Todos los Grupos</MenuItem>
                    <MenuItem value="0">default</MenuItem>
                    {Object.keys(groups).map((groupId) => (
                        <MenuItem key={groupId} value={groupId}>
                            {groups[groupId]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {(show && <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Nombre</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Correo electrónico</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Grupo</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {noResults ? (
                        <TableRow>
                            <TableCell colSpan={5}>Sin resultados</TableCell>
                        </TableRow>
                    ) : (
                        (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ width: 220 }} align="left">
                                    {row.name}
                                </TableCell>
                                <TableCell style={{ width: 260 }} align="left">
                                    {row.email}
                                </TableCell>
                                <TableCell style={{ width: 200 }} align="left">
                                    {groups[row.groupid]}
                                </TableCell>
                                <TableCell style={{ width: 60 }} align="right">
                                    <Icon style={{ cursor: 'pointer' }} component={EditIcon} onClick={() => handleSnackbarEditClick(row)} />
                                </TableCell>
                                <TableCell style={{ width: 60 }} align="center">
                                    <Icon style={{ cursor: 'pointer' }} component={DeleteIcon} onClick={() => handleSnackbarDeleteClick(row)} />
                                </TableCell>
                            </TableRow>
                        )))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            align='right'
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>)}
            {(!show && <img src={emptyImage} alt="Empty" />)}
            <><ModalEdit isOpen={isOpenEdit} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalEditHandler} updateUserList={updateUserList}></ModalEdit></>
            <><ModalDelete isOpen={isOpenDelete} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalDeleteHandler} updateUserList={updateUserList} setShow={setShow} rows={rows}></ModalDelete></>
        </TableContainer>

    );
}