import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Icon, TableHead } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { getAllBanners, getBanner, getBannerByName } from '../../../../../../services/banner';
import { useEffect } from 'react';
import ModalViewMultimedia from './Modal/ModalViewMultimedia';
import ModalEditMultimedia from './Modal/ModalEditMultimedia';
import ModalDeleteMultimedia from './Modal/ModalDeleteMultimedia';
import "./multimediaTable.css";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Última página"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(id: string, company: string, campaign_type: string, banner_type: string, state: string, file_url: string, redirect_url: string,) {
    return { id, company, campaign_type, banner_type, state, file_url, redirect_url };
}

export default function MultimediaTable(props: any) {
    const [page, setPage] = React.useState(props.page ? props.page : 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 5);
    const [rows, setRows] = React.useState<{ id: string; company: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }[]>([]);
    const [currentTab, setCurrentTab] = React.useState(0);
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        if (props && (!props.valueQuery || props.valueQuery.valueQuery === "")) {
            getAll();
        } else {
            getByName(props.valueQuery);
        }
    }, []);

    const getAll = async () => {
        const propertyName = "banners";
        const res = await getAllBanners();
        if (res) {
            const aux: { id: string; company: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }[] = [];
            res[propertyName].forEach((element: { id: string; name: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }) => {
                aux.push(createData(element.id, element.name, element.campaign_type, element.banner_type, element.state, element.file_url, element.redirect_url,));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const getByName = async (valueQuery: any) => {
        const propertyName = "banner";
        const res = await getBannerByName(valueQuery);

        if (res && res[propertyName]) {
            const aux: { id: string; company: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }[] = [];
            res[propertyName].forEach((element: { id: string; name: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }) => {
                aux.push(createData(element.id, element.name, element.campaign_type, element.banner_type, element.state, element.file_url, element.redirect_url,));
            });

            setRows(aux);
            setShow(true);
        }
    }

    // Evitar un salto de diseño al llegar a la última página con filas vacías.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
        props.onChange(parseInt(event.target.value, 10));
    };

    function handleDownload(file_url: string): void {
        const link = document.createElement('a');
        link.href = file_url;
        link.download = 'imagen.jpg';
        link.click();
    }

    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const handleSnackbarClick = async (row: any) => {
        try {
            const propertyName = "banner";
            let banner = await getBanner(row.id);
            if (banner) {
                const start_date = banner[propertyName]?.start_date.split('T')[0];
                const end_date = banner[propertyName]?.end_date.split('T')[0];
                banner[propertyName].start_date = start_date;
                banner[propertyName].end_date = end_date;
                setSelectedRow(banner[propertyName]);
                setIsOpen(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    const handleSnackbarEditClick = async (row: any) => {
        try {
            const propertyName = "banner";
            let banner = await getBanner(row.id);
            if (banner) {
                const start_date = banner[propertyName]?.start_date.split('T')[0];
                const end_date = banner[propertyName]?.end_date.split('T')[0];
                banner[propertyName].start_date = start_date;
                banner[propertyName].end_date = end_date;
                setSelectedRow(banner[propertyName]);
                setIsOpenEdit(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(): void {
        setIsOpen(false);
    }

    function closeModalEditHandler(): void {
        setIsOpenEdit(false);
    }

    function closeModalDeleteHandler(): void {
        setIsOpenDelete(false);
    }

    const emptyImage = `../assets/images/empty-state.svg`;

    const handleSnackbarDeleteClick = async (row: any) => {
        try {
            const propertyName = "banner";
            let banner = await getBanner(row.id);
            if (banner) {
                const start_date = banner[propertyName]?.start_date.split('T')[0];
                const end_date = banner[propertyName]?.end_date.split('T')[0];
                banner[propertyName].start_date = start_date;
                banner[propertyName].end_date = end_date;
                setSelectedRow(banner[propertyName]);
                setIsOpenDelete(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    const [forceUpdate, setForceUpdate] = React.useState(false);

    const updateBannerList = async () => {
        const propertyName = "banners";
        const res = await getAllBanners();
        if (res) {
            const aux: { id: string; company: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }[] = [];
            res[propertyName].forEach((element: { id: string; name: string; campaign_type: string; banner_type: string; state: string; file_url: string; redirect_url: string; }) => {
                aux.push(createData(element.id, element.name, element.campaign_type, element.banner_type, element.state, element.file_url, element.redirect_url,));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <TableContainer component={Paper}  sx={{ marginBottom: "50px" }}>
            {(show && <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Campaña</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Tipo</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">URL de redirección</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Tipo de publicación</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Archivo</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="center">Estado</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell style={{ width: 60 }} align="left">
                                {row.company}
                            </TableCell>
                            <TableCell style={{ width: 250 }} align="left">
                                {row.campaign_type}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="left">
                                {row.redirect_url}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.banner_type}
                            </TableCell>
                            <TableCell style={{ width: 80 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={VideoFileIcon} onClick={() => handleDownload(row.file_url)} />
                            </TableCell>
                            <TableCell style={{ width: 120 }} align="center">
                                <p className={`truncate-text ${row.state ? 'activo' : 'inactivo'}`}>
                                    {row.state ? 'Activo' : 'Inactivo'}
                                </p>
                            </TableCell>
                            <TableCell style={{ width: 40 }} align="right">
                                <Icon style={{ cursor: 'pointer' }} component={VisibilityIcon} onClick={() => handleSnackbarClick(row)} />
                            </TableCell>
                            <TableCell style={{ width: 40 }} align="right">
                                <Icon style={{ cursor: 'pointer' }} component={EditIcon} onClick={() => handleSnackbarEditClick(row)} />
                            </TableCell>
                            <TableCell style={{ width: 40 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={DeleteIcon} onClick={() => handleSnackbarDeleteClick(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            align='right'
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>)}
            {(!show && <img src={emptyImage} alt="Empty" />)}
            <><ModalViewMultimedia isOpen={isOpen} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalHandler}></ModalViewMultimedia></>
            <><ModalEditMultimedia isOpen={isOpenEdit} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalEditHandler}></ModalEditMultimedia></>
            <><ModalDeleteMultimedia isOpen={isOpenDelete} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalDeleteHandler} updateBannerList={updateBannerList} setShow={setShow} rows={rows}></ModalDeleteMultimedia></>
        </TableContainer>
    );

}
