import React from 'react';
import './LoadingModal.css';

function LoadingModal() {
  return (
    <div className="loading-modal">
      <div className="loading-spinner"></div>
    </div>
  );
}

export default LoadingModal;
