import { LoadBaseProvider } from '../load';
import { CurrentUserStore } from '../../stores';
import { clearSession, getSession, getSessionTokenDecoded, ISession, setSession, verifySession } from '../../utils/auth';

export class AuthProvider extends LoadBaseProvider {
    constructor(private user: CurrentUserStore) {
        super();

        /*
            retrieve user session from local storage
            if not available, check location for a session token
            if not available then not logged in.
            * Use asynchronous function without wait...
        */
        this.initCurrentSession();
    }

    /* Private */
    private initCurrentSession = async () => {
        const session = getSession();
        if (session && verifySession(session)) {
            this.configureSession(session);
        }
        this.setLoading(false);
    }

    clear() {
        clearSession();
        this.user.clear();
    }

    configureSession(session: ISession) {
        try {
            const info = getSessionTokenDecoded(session);
            if (info == null) throw new Error('failed to decode jwt');
            setSession(session);
            this.user.set({ token: session.token, info });
            return 'success';
        } catch(err) {
            return 'error';
        }
    }

    logout() {
        if (!this.user.isLoggedIn) return;
        this.clear();
    }
}