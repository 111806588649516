import React, { useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Typography, Button, useTheme, FormControl, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Paper, FormControlLabel, FormGroup, Checkbox, Tab, Tabs } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import LoadingModal from '../../../../services/loading';
import './styles.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ModalReports from './Modal/ModalReports';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import TablePagination from '@mui/material/TablePagination';
import { getBannerAppearanceRate, getBannerAvailability, getBannerConversionRate, getBannerImpactRate } from '../../../../services/statisticsBanner';
import { getAllBanners } from '../../../../services/banner';
import FloatingImage from '../../../FloatingImage';
import SwipeableViews from 'react-swipeable-views';
import { ImpactTable } from './Tables/Impact';
import { ConversionTable } from './Tables/Conversion';
import { AvailabilityTable } from './Tables/Availability';
import { AppearanceRate } from './Tables/AppearanceRate';

interface LogData {
    id: string;
    name: string;
    start_date: string;
    end_date: string;
    banner_type: string;
    total_appearance: string;
    appearance_count: string;
    weight: string;
    clicks: string;
    rejection: string;
    redirect_url: string;
}


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StatisticsBanner = () => {
    const [success, setSuccess] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [value, setValue] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [paginatedData, setPaginatedData] = useState<LogData[]>([]);

    useLayoutEffect(
        () => {
            const getAll = async () => {
                const res1 = await getAllBanners();
                setIsLoading(false);
            }
            const timeout = setTimeout(() => {
                getAll();
            }, 1000);
        },
        []
    );

    const handleSnackbarClick = async () => {
        setIsOpen(true);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);

        setButtonClicked(false);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(result: boolean): void {
        setReports(false);
        setIsLoading(true);
        setIsOpen(false);
        if (result) {
            setReload(true);
            setSuccess(true);
            setSnackbarOpen(true);
            setIsLoading(false);
        } else {
            setReload(false);
            setIsLoading(false);
        }
    }

    const [downloadSnackbarOpen, setDownloadSnackbarOpen] = React.useState(false);
    const [downloadSnackbarMessage, setDownloadSnackbarMessage] = React.useState('');
    const [downloadSnackbarSeverity, setDownloadSnackbarSeverity] = React.useState<AlertColor | undefined>('error');

    // Para los checks
    async function closeModalDownloadHandler(result: any) {
        let resultFile = '';
        let fileName = "InformeSeleccionado.csv";

        let anyDataSelected = false;

        if (result) {
            if (isCheckedImpact) {
                const resultData = await getBannerImpactRate(start_date, end_date);

                if (resultData && Object.keys(resultData).length > 0) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const csvHeaders = Object.keys(resultData);
                    resultFile += csvHeaders.join(', ') + '\n';
                    const rowData = Object.values(resultData);
                    resultFile += rowData.map(value => `${value}`).join(', ') + '\n';

                    anyDataSelected = true;
                }
                fileName = `Informe de impacto_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }

            if (isCheckedRate) {
                const resultData = await getBannerConversionRate(start_date, end_date);

                if (resultData && Object.keys(resultData).length > 0) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const csvHeaders = Object.keys(resultData);
                    resultFile += csvHeaders.join(', ') + '\n';
                    const rowData = Object.values(resultData);
                    resultFile += rowData.map(value => `${value}`).join(', ') + '\n';

                    anyDataSelected = true;
                }
                fileName = `Informe de tasa de conversion_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }

            if (isCheckedAvailability) {
                const resultData = await getBannerAvailability(start_date, end_date);

                if (resultData && Object.keys(resultData).length > 0) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const csvHeaders = Object.keys(resultData);
                    resultFile += csvHeaders.join(', ') + '\n';
                    const rowData = Object.values(resultData);
                    resultFile += rowData.map(value => `${value}`).join(', ') + '\n';

                    anyDataSelected = true;
                }
                fileName = `Informe de disponibilidad_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }

            if (isCheckedAppearanceRate) {
                const resultData = await getBannerAppearanceRate(start_date, end_date);

                if (resultData && Object.keys(resultData).length > 0) {
                    resultFile += `Fecha inicial: ${start_date?.format('YYYY-MM-DD')} - Fecha final: ${end_date?.format('YYYY-MM-DD')}\n`;
                    const csvHeaders = Object.keys(resultData);
                    resultFile += csvHeaders.join(', ') + '\n';
                    const rowData = Object.values(resultData);
                    resultFile += rowData.map(value => `${value}`).join(', ') + '\n';

                    anyDataSelected = true;
                }
                fileName = `Informe de tasa de aparicion_${start_date?.format('YYYY-MM-DD')}_${end_date?.format('YYYY-MM-DD')}.csv`;
            }


            if (!anyDataSelected) {
                setDownloadSnackbarMessage("No hay datos para esas fechas");
                setDownloadSnackbarSeverity("error");
                setDownloadSnackbarOpen(true);

                setReports(false);
                return;
            }


            //Info adicional en la descarga
            resultFile += '\n';
            resultFile += '\n';
            const resultData = await getAllBanners();
            if (resultData.banners && Array.isArray(resultData.banners)) {
                const header = "id,name,start_date,end_date,banner_type,total_appearance,appearance_count,weight,clicks,rejection,redirect_url";
                resultFile += `${header}\n`;
                const bannersData = resultData.banners;
                bannersData.forEach((banner: { id: any; name: any; start_date: any; end_date: any; banner_type: any; total_appearance: any; appearance_count: any; weight: any; clicks: any; rejection: any; redirect_url: any; }) => {
                    const formattedStartDate = dayjs(banner.start_date).format('YYYY-MM-DD HH:mm:ss');
                    const formattedEndDate = dayjs(banner.end_date).format('YYYY-MM-DD HH:mm:ss');
                    resultFile += `${banner.id},${banner.name},${formattedStartDate},${formattedEndDate},${banner.banner_type},${banner.total_appearance},${banner.appearance_count},${banner.weight},${banner.clicks},${banner.rejection},${banner.redirect_url}\n`;
                });
            }


            const blob = new Blob([resultFile], { type: 'text/csv;charset=utf-8' });
            const csvUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setReports(false);
    }

    function handleAttributeChange(result: any) {
        setRowsPerPage(result);
    }

    function handlePageChange(result: any) {
        setPage(result);
    }

    const fechaActual = new Date();
    const ultimoDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);

    // Obtén los componentes de la fecha (día, mes, año)
    const dia = fechaActual.getDate();
    const ultimoDia = ultimoDiaDelMes.getDate();
    const mes = fechaActual.getMonth() + 1; // Los meses son indexados desde 0, por lo que sumamos 1
    const año = fechaActual.getFullYear();

    const [start_date, setStart_date] = React.useState<Dayjs | null>(dayjs(`${año}-${mes}-${dia}`));
    const [end_date, setEnd_date] = React.useState<Dayjs | null>(dayjs(`${año}-${mes}-${ultimoDia}`));

    const [dates, setDates] = useState('');
    const handleDatesChange = (event: any) => {
        setDates(event.target.value);
    };

    const [isCheckedImpact, setIsCheckedImpact] = useState(false);
    const [isCheckedRate, setIsCheckedRate] = useState(false);
    const [isCheckedAvailability, setIsCheckedAvailability] = useState(false);
    const [isCheckedAppearanceRate, setIsCheckedAppearanceRate] = useState(false);

    const handleCheckboxImpact = () => {
        setIsCheckedImpact(true);
        setIsCheckedRate(false);
        setIsCheckedAvailability(false);
        setIsCheckedAppearanceRate(false);

        setButtonClicked(false);
    };

    const handleCheckboxRate = () => {
        setIsCheckedImpact(false);
        setIsCheckedRate(true);
        setIsCheckedAvailability(false);
        setIsCheckedAppearanceRate(false);

        setButtonClicked(false);
    };

    const handleCheckboxAvailability = () => {
        setIsCheckedImpact(false);
        setIsCheckedRate(false);
        setIsCheckedAvailability(true);
        setIsCheckedAppearanceRate(false);

        setButtonClicked(false);
    };

    const handleCheckboxAppearanceRate = () => {
        setIsCheckedImpact(false);
        setIsCheckedRate(false);
        setIsCheckedAvailability(false);
        setIsCheckedAppearanceRate(true);

        setButtonClicked(false);
    };

    const [reports, setReports] = useState(false);
    const handleDownloadClick = () => {
        setReports(true);
    };

    const [data, setData] = useState<LogData[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        const startIndex = newPage * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        setPaginatedData(data.slice(startIndex, endIndex));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setPaginatedData(data.slice(0, rowsPerPage));
    };

    //Para la info de todos los banners
    const handleViewClickBanners = async () => {
        try {
            const response = await getAllBanners();

            if (response.banners && Array.isArray(response.banners)) {
                const formattedData = response.banners.map((row: { name: any; start_date: any; end_date: any; total_appearance: any; appearance_count: any; weight: any; clicks: any; rejection: any; redirect_url: any; }) => ({
                    name: row.name || '-',
                    start_date: row.start_date || '-',
                    end_date: row.end_date || '-',
                    total_appearance: row.total_appearance || '-',
                    appearance_count: row.appearance_count || '-',
                    weight: row.weight || '-',
                    clicks: row.clicks || '-',
                    rejection: row.rejection || '-',
                    redirect_url: row.redirect_url || '-',
                }));

                const startIndex = page * rowsPerPage;
                const endIndex = startIndex + rowsPerPage;

                const paginatedData = formattedData.slice(startIndex, endIndex);

                setPaginatedData(paginatedData);
                setData(formattedData);
            } else {
                console.error("La respuesta no contiene una matriz 'banners'.");
            }
        } catch (error) {
            console.error("Error al obtener datos:", error);
        }
    };

    const [buttonClicked, setButtonClicked] = useState(false);
    const [prevDates, setPrevDates] = useState<{ start_date: dayjs.Dayjs | null; end_date: dayjs.Dayjs | null }>({ start_date: null, end_date: null });
    const [impactData, setImpactData] = useState<{ name: string; value: number }[]>([]);
    const [totalImpact, setTotalImpact] = useState(0);
    const [conversionData, setConversionData] = useState<{ name: string; value: number }[]>([]);
    const [totalConversion, setTotalConversion] = useState(0);
    const [availabilityData, setAvailabilityData] = useState<{ name: string; value: number }[]>([]);
    const [totalAvailability, setTotalAvailability] = useState(0);
    const [appearanceData, setAppearanceData] = useState<{ name: string; value: number }[]>([]);
    const [totalAppearance, setTotalAppearance] = useState(0);

    //Para los graficos
    const handleViewClick = async () => {
        try {

            if (isCheckedImpact) { // Impacto
                const methodData = await getBannerImpactRate(start_date, end_date);
                const transformedData = Object.entries(methodData).map(([name, value]) => ({
                    name,
                    value: value as number,
                }));
                setImpactData(transformedData);
                const total = transformedData.reduce((acc, entry) => acc + entry.value, 0);
                setTotalImpact(total);

                setButtonClicked(true);
            }

            if (isCheckedRate) { // Tasa de conversion
                const methodDataConversion = await getBannerConversionRate(start_date, end_date);
                const transformedDataConversion = Object.entries(methodDataConversion).map(([name, value]) => ({
                    name,
                    value: value as number,
                }));
                setConversionData(transformedDataConversion);
                const ConversionTotal = transformedDataConversion.reduce((acc, entry) => acc + entry.value, 0);
                setTotalConversion(ConversionTotal);

                setButtonClicked(true);
            }

            if (isCheckedAvailability) { // Disponibilidad
                const methodDataAvailability = await getBannerAvailability(start_date, end_date);
                const transformedDataAvailability = Object.entries(methodDataAvailability).map(([name, value]) => ({
                    name,
                    value: value as number,
                }));
                setAvailabilityData(transformedDataAvailability);
                const availabilityTotal = transformedDataAvailability.reduce((acc, entry) => acc + entry.value, 0);
                setTotalAvailability(availabilityTotal);

                setButtonClicked(true);
            }


            if (isCheckedAppearanceRate) { // Tasa de aparición
                const methodDataAppearance = await getBannerAppearanceRate(start_date, end_date);
                const transformedDataAppearance = Object.entries(methodDataAppearance).map(([name, value]) => ({
                    name,
                    value: value as number,
                }));
                setAppearanceData(transformedDataAppearance);
                const AppearanceTotal = transformedDataAppearance.reduce((acc, entry) => acc + entry.value, 0);
                setTotalAppearance(AppearanceTotal);

                setButtonClicked(true);
            }

        } catch (error) {
            console.error("Error al obtener datos:", error);
        }
    };

    const handleStartDateChange = (newValue: dayjs.Dayjs | null) => {
        setStart_date(newValue);
        if (!prevDates.start_date || !newValue || !newValue.isSame(prevDates.start_date) || !dayjs(end_date).isSame(prevDates.end_date)) {
            setButtonClicked(false);
        }
        setPrevDates({ ...prevDates, start_date: newValue });
    };

    const handleEndDateChange = (newValue: dayjs.Dayjs | null) => {
        setEnd_date(newValue);
        if (!prevDates.end_date || !newValue || !dayjs(start_date).isSame(prevDates.start_date) || !newValue.isSame(prevDates.end_date)) {
            setButtonClicked(false);
        }
        setPrevDates({ ...prevDates, end_date: newValue });
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {isLoading && <LoadingModal />}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>

                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px" }}>Reportes - Estadísticas de campaña</Typography>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "5px" }}>Banner</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', marginLeft: "22px", marginRight: "22px", }}>
                                <div className="box-statistics" >

                                    <div style={{ display: 'grid' }}>
                                        <FormControl component="fieldset" sx={{}}>
                                            <FormGroup aria-label="position" sx={{ marginLeft: '20px' }}>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', marginTop: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedImpact} onChange={handleCheckboxImpact} />}
                                                        label="Informe de impacto"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedRate} onChange={handleCheckboxRate} />}
                                                        label="Informe de tasa de conversión"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedAvailability} onChange={handleCheckboxAvailability} />}
                                                        label="Informe de disponibilidad"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        htmlFor="checkbox-impact"
                                                        value="top"
                                                        sx={{ marginBottom: '10px', pointerEvents: 'auto', cursor: 'default' }}
                                                        control={<Checkbox checked={isCheckedAppearanceRate} onChange={handleCheckboxAppearanceRate} />}
                                                        label="Informe de tasa de aparición"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            </FormGroup>

                                            <Button variant="contained" onClick={handleDownloadClick}
                                                sx={{
                                                    backgroundColor: primaryColor,
                                                    color: "white",
                                                    width: "140px",
                                                    height: "40px",
                                                    padding: "8px 16px",
                                                    fontSize: "14px",
                                                    margin: "10px",
                                                    marginBottom: "10px",
                                                    marginTop: "60px",
                                                    "&:hover": {
                                                        backgroundColor: primaryColorHover,
                                                        color: "white",
                                                    },
                                                    "&:active": {
                                                        backgroundColor: primaryColorHover,
                                                        color: "white",
                                                    },
                                                    '@media (min-width: 230px) and (max-width: 670px)': {
                                                        right: "-20%",
                                                    },
                                                    '@media (min-width: 671px) and (max-width: 959px)': {
                                                        right: "-75%",
                                                    },
                                                    '@media (min-width: 960px) and (max-width: 1023px)': {
                                                        right: "-135%",
                                                    },
                                                    '@media (min-width: 1024px) and (max-width: 1327px)': {
                                                        right: "-140%",
                                                    },
                                                    '@media (min-width: 1328px) and (max-width: 1627px)': {
                                                        right: "-165%",
                                                    },
                                                    '@media (min-width: 1628px)': {
                                                        right: "-170%",
                                                    },
                                                }}
                                            >
                                                <FileDownloadOutlinedIcon sx={{ marginLeft: "-5px" }} />
                                                Descargar
                                            </Button>
                                        </FormControl>
                                    </div>
                                    <FormControl sx={{ width: '100%', marginTop: "25px" }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '40px', marginTop: "15px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker label="Fecha inicial*" value={start_date} onChange={handleStartDateChange} />
                                            </LocalizationProvider>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '20px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker label="Fecha final*" value={end_date} onChange={handleEndDateChange} />
                                            </LocalizationProvider>
                                        </Box>

                                    </FormControl>
                                </div >
                                <ModalReports isOpen={reports} openModalHandler={openModalHandler} closeModalHandler={closeModalDownloadHandler} ></ModalReports>
                            </Box >

                            <Box sx={{ mt: 4, marginLeft: "22px", marginRight: "22px", }}>
                                <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginBottom: "25px", marginTop: "22px", marginLeft: "5px" }}>Gráficos</Typography>

                                <Button
                                    variant="contained"
                                    onClick={handleViewClick}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "140px",
                                        height: "40px",
                                        padding: "8px 16px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        marginTop: "-20px",
                                        marginBottom: "20px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        '@media (min-width: 230px) and (max-width: 670px)': {
                                            right: "-20%",

                                        },
                                        '@media (min-width: 671px) and (max-width: 959px)': {
                                            right: "-45%",

                                        },
                                        '@media (min-width: 960px) and (max-width: 1023px)': {
                                            right: "-70%",
                                        },
                                        '@media (min-width: 1024px) and (max-width: 1327px)': {
                                            right: "-75%",
                                        },
                                        '@media (min-width: 1328px) and (max-width: 1627px)': {
                                            right: "-80%",
                                        },
                                        '@media (min-width: 1628px)': {
                                            right: "-85%",
                                        },
                                    }}
                                >
                                    Visualizar
                                </Button>


                                {isCheckedImpact && buttonClicked && totalImpact === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedImpact && buttonClicked && totalImpact !== 0 && (
                                        <ImpactTable
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalImpact={totalImpact}
                                            impactData={impactData}
                                        />
                                    )
                                )}

                                {isCheckedRate && buttonClicked && totalConversion === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedRate && buttonClicked && totalConversion !== 0 && (
                                        <ConversionTable
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalConversion={totalConversion}
                                            conversionData={conversionData}
                                        />
                                    )
                                )}

                                {isCheckedAvailability && buttonClicked && totalAvailability === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedAvailability && buttonClicked && totalAvailability !== 0 && (
                                        <AvailabilityTable
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalAvailability={totalAvailability}
                                            availabilityData={availabilityData}
                                        />
                                    )
                                )}

                                {isCheckedAppearanceRate && buttonClicked && totalAppearance === 0 ? (
                                    <p><strong>No hay datos para esas fechas</strong></p>
                                ) : (
                                    isCheckedAppearanceRate && buttonClicked && totalAppearance !== 0 && (
                                        <AppearanceRate
                                            start_date={start_date}
                                            end_date={end_date}
                                            totalAppearance={totalAppearance}
                                            appearanceData={appearanceData}
                                        />
                                    )
                                )}

                            </Box>

                            <Box sx={{ mt: 4, marginLeft: "22px", marginRight: "22px", }}>
                                <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginBottom: "25px", marginTop: "22px", marginLeft: "5px" }}>Todos los banner</Typography>

                                <Button variant="contained" onClick={handleViewClickBanners}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "140px",
                                        height: "40px",
                                        padding: "8px 16px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        marginTop: "-20px",
                                        marginBottom: "20px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        '@media (min-width: 230px) and (max-width: 670px)': {
                                            right: "-20%",
                                        },
                                        '@media (min-width: 671px) and (max-width: 959px)': {
                                            right: "-45%",
                                        },
                                        '@media (min-width: 960px) and (max-width: 1023px)': {
                                            right: "-70%",
                                        },
                                        '@media (min-width: 1024px) and (max-width: 1327px)': {
                                            right: "-75%",
                                        },
                                        '@media (min-width: 1328px) and (max-width: 1627px)': {
                                            right: "-80%",
                                        },
                                        '@media (min-width: 1628px)': {
                                            right: "-85%",
                                        },
                                    }}
                                >
                                    Información
                                </Button>

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Nombre</TableCell>
                                                <TableCell align="left">Fecha de alta</TableCell>
                                                <TableCell align="left">Fecha de baja</TableCell>
                                                <TableCell align="left">Tasa de apariciones</TableCell>
                                                <TableCell align="left">Peso</TableCell>
                                                <TableCell align="left">Apariciones</TableCell>
                                                <TableCell align="left">Clicks</TableCell>
                                                <TableCell align="left">Rechazos</TableCell>
                                                <TableCell align="left">URL de redirreción</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {data.length > 0 ? (
                                                paginatedData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">{dayjs(row.start_date).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                                        <TableCell align="left">{dayjs(row.end_date).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                                        <TableCell align="left">{row.total_appearance}</TableCell>
                                                        <TableCell align="left">{row.weight}</TableCell>
                                                        <TableCell align="left">{row.appearance_count}</TableCell>
                                                        <TableCell align="left">{row.clicks}</TableCell>
                                                        <TableCell align="left">{row.rejection}</TableCell>
                                                        <TableCell align="left">{row.redirect_url}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        {isLoading ? "Cargando datos..." : "Seleccione visualizar para ver todos los banners (Sin fechas)."}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={data.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{ marginBottom: "50px" }}
                                />
                            </Box>
                        </>
                    )
                }
                <FloatingImage />
            </Box >

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={downloadSnackbarOpen} autoHideDuration={6000} onClose={() => setDownloadSnackbarOpen(false)}>
                <Alert onClose={() => setDownloadSnackbarOpen(false)} severity={downloadSnackbarSeverity as AlertColor} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {downloadSnackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default StatisticsBanner;