import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Container, Grid, Typography, Button, styled, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { GetAllSettings, UpdateSettingsJson } from '../../../../services';
import FloatingImage from '../../../FloatingImage';

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: '#3D496E',
        '& legend': {
            '& span': {
                color: '#3D496E'
            }
        }
    },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GeneralEdition = () => {

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(true);
    const [settingId, setSettingId] = useState<string>('');
    const [editing, setEditing] = useState(false);
    const [expiration_account, setExpiration_account] = useState<string>('');
    const [expiration_connection, setExpiration_connection] = useState<string>('');
    const [invite_mode, setInvite_mode] = useState<string>('');
    const [form_login, setForm_login] = useState<string>('');
    const [autoSignInEnabled, setAutoSignInEnabled] = useState<boolean>(false);
    const [additionalFieldsEnabled, setAdditionalFieldsEnabled] = useState(invite_mode === 'desactivado');
    const [expiration_logs, setExpiration_logs] = useState<string>('');
    const [socialData, setSocialData] = useState([
        {
            name: 'facebook',
            is_active: false,
        },
        {
            name: 'linkedin',
            is_active: false,
        },
        {
            name: 'google',
            is_active: false,
        },
    ]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(form_login === 'only_rs' && socialData.every(socialItem => !socialItem.is_active));

    useLayoutEffect(
        () => {
            const getAll = async () => {
                const settings: any[] = await GetAllSettings();
                if (settings && settings.length > 0) {
                    setSettingId(settings[0].id);
                    // TODO: siempre es uno?
                }
                if (settings[0].expiration_account) {
                    setExpiration_account(settings[0].expiration_account);
                }

                if (settings[0].expiration_connection) {
                    setExpiration_connection(settings[0].expiration_connection);
                }

                if (settings[0].expiration_logs) {
                    setExpiration_logs(settings[0].expiration_logs);
                }

                setInvite_mode(settings[0].invite_mode ? 'activado' : 'desactivado');
                setForm_login(settings[0].form_login.password ? 'email+password' : 'email');

                setAutoRegisterEnabled(settings[0].auto_register);

                const formLoginSettings = settings[0].form_login;
                if (formLoginSettings.email && formLoginSettings.password) {
                    setForm_login('email+password');
                } else if (formLoginSettings.email) {
                    setForm_login('email');
                } else if (formLoginSettings.only_rs) {
                    setForm_login('only_rs');
                }

                setSocialData(settings[0].social);

                setIsLoading(false);
            }
            getAll();
        },
        []
    );

    useEffect(() => {
        if (invite_mode === 'activado') {
            setAdditionalFieldsEnabled(false);
        } else {
            setAdditionalFieldsEnabled(true);
        }
    }, [invite_mode]);

    const handleSaveChangesClick = async (event: any) => {

        const formLoginConfig =
            invite_mode === "activado"
                ? { email: false, password: false, only_rs: false }
                : {
                    email: form_login !== 'only_rs',
                    password: form_login === "email+password",
                    only_rs: form_login === "only_rs",
                };

        const autoRegister = form_login === "email+password" && autoRegisterEnabled;

        const dataToUpdate = {
            expiration_account: parseInt(expiration_account, 10),
            expiration_connection: parseInt(expiration_connection, 10),
            expiration_logs: parseInt(expiration_logs, 10),

            invite_mode: invite_mode === "activado",
            form_login: formLoginConfig,
            social: socialData,
            auto_register: autoRegister,
        };

        const response = await UpdateSettingsJson(dataToUpdate, settingId);

        setSuccess(true);
        setSnackbarOpen(true);
    }

    const handleSelectionChange = (selectedValue: any) => { };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleExpirationAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setExpiration_account(newValue);
    };

    const handleExpirationSessionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setExpiration_connection(newValue);
    };

    const handleExpirationLogsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setExpiration_logs(newValue);
    };

    const handleInviteModeChange = (event: any) => {
        const newValue = event.target.value;
        setInvite_mode(newValue);
        setAutoSignInEnabled(newValue);

        setAdditionalFieldsEnabled(!newValue);
    };

    const [autoRegisterEnabled, setAutoRegisterEnabled] = useState<boolean>(false);

    const handleAutoRegisterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoRegisterEnabled(!autoRegisterEnabled);
    };

    const handleFormLoginChange = (event: any) => {
        const newValue = event.target.value;
        setForm_login(newValue);

        setSaveButtonDisabled(newValue === 'only_rs' && socialData.every(socialItem => !socialItem.is_active));
    };

    const handleSocialChange = (name: string) => (event: { target: { checked: any; }; }) => {
        const updatedSocialData = socialData.map((item) =>
            item.name === name ? { ...item, is_active: event.target.checked } : item
        );
        setSocialData(updatedSocialData);

        setSaveButtonDisabled(form_login === 'only_rs' && updatedSocialData.every(socialItem => !socialItem.is_active));
    };
    const formatSocialName = (name: string) => {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px", marginBottom: "-65px" }}>Configuración del sistema</Typography>
                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 600,
                                        ml: '1rem',
                                        marginRight: "22px",
                                        marginTop: "15px",
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        },
                                        '&:active': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        }
                                    }}
                                    onClick={handleSaveChangesClick}
                                    disabled={saveButtonDisabled}
                                >
                                    Guardar cambios
                                </Button>
                            </Box>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "-15px" }}>General</Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 'auto',
                                        }}
                                    >
                                        <TextInput
                                            margin="normal"
                                            value={expiration_account}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                const numericValue = inputValue.replace(/[^0-9]/g, '');
                                                handleExpirationAccountChange({ target: { value: numericValue } } as React.ChangeEvent<HTMLInputElement>);
                                            }}
                                            sx={{ width: '100%' }}
                                            name="expiration_account"
                                            label="Tiempo de expiración de cuenta (dias)"
                                            type="number"
                                            id="expiration_account"
                                            autoComplete=""
                                        />
                                        <TextInput
                                            margin="normal"
                                            value={expiration_connection}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                const numericValue = inputValue.replace(/[^0-9]/g, '');
                                                handleExpirationSessionChange({ target: { value: numericValue } } as React.ChangeEvent<HTMLInputElement>);
                                            }}
                                            sx={{ width: '100%' }}
                                            name="expiration_connection"
                                            label="Tiempo de duración de sesión (segundos)"
                                            type="number"
                                            id="expiration_connection"
                                            autoComplete="expiration_connection"
                                        />
                                        <TextInput
                                            margin="normal"
                                            value={expiration_logs}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                const numericValue = inputValue.replace(/[^0-9]/g, '');
                                                handleExpirationLogsChange({ target: { value: numericValue } } as React.ChangeEvent<HTMLInputElement>);
                                            }}
                                            sx={{ width: '100%' }}
                                            name="expiration_logs"
                                            label="Fecha de borrado automático de logs (meses)"
                                            type="number"
                                            id="expiration_logs"
                                            autoComplete="expiration_logs"
                                        />
                                        <FormControl sx={{ mt: '1rem', width: '100%', marginTop: "40px" }}>
                                            <InputLabel id="invite_mode">Ingreso directo</InputLabel>
                                            <Select
                                                labelId="invite_mode"
                                                label="Ingreso directo"
                                                name="invite_mode"
                                                value={invite_mode}
                                                onChange={handleInviteModeChange}
                                                sx={{ borderRadius: '1.25rem' }}
                                            >
                                                <MenuItem value="activado">Activado</MenuItem>
                                                <MenuItem value="desactivado">Desactivado</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                            <InputLabel id="form_login">Metodo de registro de usuario</InputLabel>
                                            <Select
                                                labelId="form_login"
                                                label="Metodo de registro de usuario"
                                                name="form_login"
                                                value={form_login}
                                                onChange={handleFormLoginChange}
                                                sx={{ borderRadius: '1.25rem', borderColor: form_login === 'only_rs' && socialData.every(socialItem => !socialItem.is_active) ? 'red' : undefined }}
                                                disabled={!additionalFieldsEnabled}
                                            >
                                                <MenuItem value="email+password">Email + Password</MenuItem>
                                                <MenuItem value="email">Email</MenuItem>
                                                <MenuItem value="only_rs">Redes sociales</MenuItem>
                                            </Select>
                                            {form_login === 'email+password' && (
                                                <div style={{ pointerEvents: 'none' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={autoRegisterEnabled}
                                                                onChange={handleAutoRegisterChange}
                                                                sx={{ color: '#3D496E', marginLeft: "15px" }}
                                                                style={{ height: "30px", pointerEvents: 'auto' }}
                                                            />
                                                        }
                                                        label={<span style={{ fontSize: "16px" }}>Autoregistro</span>}
                                                    />
                                                </div>
                                            )}

                                            {form_login === 'only_rs' && socialData.every(socialItem => !socialItem.is_active) && (
                                                <Typography variant="caption" color="error" sx={{ marginLeft: '1rem' }}>
                                                    Debe seleccionar al menos una red social.
                                                </Typography>
                                            )}
                                        </FormControl>
                                        <Typography variant="h6" component="h2" sx={{ color: '#3D496E', marginTop: "30px", marginLeft: "15px", fontSize: "13px" }}>
                                            Registro por redes sociales
                                        </Typography>
                                        {socialData.map((socialItem) => (
                                            <div key={socialItem.name} style={{ pointerEvents: 'none' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={socialItem.is_active}
                                                            onChange={handleSocialChange(socialItem.name)}
                                                            sx={{ color: '#3D496E', marginLeft: "15px" }}
                                                            style={{ height: "30px", pointerEvents: 'auto' }}
                                                        />
                                                    }
                                                    label={<span style={{ fontSize: "16px" }}>{formatSocialName(socialItem.name)}</span>}
                                                    disabled={!additionalFieldsEnabled}
                                                />
                                            </div>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                <FloatingImage />
            </Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {success ? "Cambios guardados" : "Error al modificar alguna/s portada/s"}
                </Alert>
            </Snackbar>

        </>
    );
}

export default GeneralEdition;