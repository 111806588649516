import React, { useEffect, useState } from 'react'
import { TextField, InputAdornment, IconButton, Grid, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const ManagementSearch = ({ value, onChange, isGroup }: { value: string; onChange: (newValue: string) => void; isGroup: boolean }) => {
    const [label, setLabel] = useState<string>('Buscar por nombre de usuario');

    useEffect(
        () => {
            if (isGroup) {
                setLabel('Buscar por nombre de grupo');
            } else {
                setLabel('Buscar por nombre de usuario');
            }
        },
        [isGroup, label]
    );

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item xs={6} sx={{ marginTop: "50px", marginRight: "-20px" }}> {/* Define el ancho deseado aquí */}
                <TextField
                    label={label}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton disabled>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disabled>

                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            backgroundColor: 'white',
                            cursor: 'pointer',
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};