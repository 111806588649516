import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Toolbar, Grid, Typography, Button, styled, TextField } from '@mui/material';
import EditContentForm from './components/EditContentForm/EditContentForm';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { GetAllSettings, ICoverLanguage, UpdateCoverBySettingIdAndLanguage, UpdateFileCoverSetting } from '../../../../services';
import ModalFile from './Modal/Modal-Create/ModalFile';

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: '#3D496E',
        '& legend': {
            '& span': {
                color: '#3D496E'
            }
        }
    },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CoverEditionPage = () => {

    const english = "english";
    const spanish = "spanish";
    const portuguese = "portuguese";

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [lenguage, setLenguage] = useState<string>('spanish');

    const [spanishId, setSpanishId] = useState<string>('');
    const [spanishTitle, setSpanishTitle] = useState<string>('');
    const [spanishDesc, setSpanishDesc] = useState<string>('');

    const [englishId, setEnglishId] = useState<string>('');
    const [englishTitle, setEnglishTitle] = useState<string>('');
    const [englishDesc, setEnglishDesc] = useState<string>('');

    const [portugueseId, setPortugueseId] = useState<string>('');
    const [portugueseTitle, setPortugueseTitle] = useState<string>('');
    const [portugueseDesc, setPortugueseDesc] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(true);

    const [imageToShow, setImageToShow] = useState<string>(''); //data:image/jpeg;base64,../assets/images/Burger-desktop.png
    const [blobImage, setBlobImage] = useState<File | null>(null);
    const [settingId, setSettingId] = useState<string>('');
    const [changeImage, setChangeImage] = useState<boolean>(false);

    const convertBase64ToFile = (base64String: any) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        const tempFile = new File([blob], 'archivo_generado', { type: 'application/octet-stream' });
        setBlobImage(tempFile);
    };

    const convertBlobToBase64 = async (blobFile: any) => {
        if (blobFile) {
            const blobData = await blobFile.arrayBuffer();
            const base64 = btoa(new Uint8Array(blobData).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            return base64;
        }
    };

    useLayoutEffect(
        () => {
            const getAll = async () => {
                const settings: any[] = await GetAllSettings();
                if (settings && settings.length > 0) {
                    setSettingId(settings[0].id);
                    // TODO: siempre es uno?
                    const res: ICoverLanguage[] = settings[0].covers ? settings[0].covers : [];
                    // covers
                    if (res) {
                        const spanishLanguage = { ...res.filter(r => r.language === "spanish")[0] };
                        const englishLanguage = { ...res.filter(r => r.language === "english")[0] };
                        const portugueseLanguage = { ...res.filter(r => r.language === "portuguese")[0] };

                        setSpanishId(spanishLanguage.id);
                        setSpanishTitle(spanishLanguage.title);
                        setSpanishDesc(spanishLanguage.description);

                        setEnglishId(englishLanguage.id);
                        setEnglishTitle(englishLanguage.title);
                        setEnglishDesc(englishLanguage.description);;

                        setPortugueseId(portugueseLanguage.id);
                        setPortugueseTitle(portugueseLanguage.title);
                        setPortugueseDesc(portugueseLanguage.description);
                    }

                    // file
                    if (settings[0].file_encoded) {
                        setImageToShow(`data:image/jpeg;base64,${settings[0].file_encoded}`);
                        convertBase64ToFile(settings[0].file_encoded);
                    }
                }
                setIsLoading(false);
            }

            getAll();
        },
        []
    );

    const handleSaveChangesClick = async (event: any) => {
        // TODO: updateTodo
        switch (lenguage) {
            case 'spanish':
                const bodySpanish = {
                    title: spanishTitle,
                    description: spanishDesc
                };
                UpdateCoverBySettingIdAndLanguage(bodySpanish, 'spanish', settingId);
                break;
            case 'english':
                const bodyEnglish = {
                    title: englishTitle,
                    description: englishDesc
                };
                UpdateCoverBySettingIdAndLanguage(bodyEnglish, 'english', settingId);
                break;
            case 'portuguese':
                const bodyPortuguese = {
                    title: portugueseTitle,
                    description: portugueseDesc
                };
                UpdateCoverBySettingIdAndLanguage(bodyPortuguese, 'portuguese', settingId);
                break;
        }

        if (changeImage) {
            if (blobImage) {
                UpdateFileCoverSetting(blobImage, settingId);
            }
        }

        setSuccess(true);
        setSnackbarOpen(true);
    };

    const handleSelectionChange = (selectedValue: any) => {
        setLenguage(selectedValue);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const [editing, setEditing] = useState(false);

    const handleEditClick = () => {
        setEditing(true);
    };
    const fileInputRef = useRef(null);

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.error('Error: ', error);
        };
    }

    function closeModalHandler(file: any): void {       //
        if (file) {
            setChangeImage(true);
            setBlobImage(file);
            getBase64(file, (result: any) => {
                setImageToShow(result);
            });
        }

        setEditing(false);
    }

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px", marginBottom: "-65px" }}>Configuración del sistema</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 600,
                                        ml: '1rem',
                                        marginRight: "22px",
                                        marginTop: "15px",
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        },
                                        '&:active': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        }
                                    }}
                                    onClick={handleSaveChangesClick}
                                >
                                    Guardar cambios
                                </Button>
                            </Box>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "-15px" }}>Portada</Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 'auto',
                                        }}
                                    >

                                        <EditContentForm
                                            onSelectionChange={handleSelectionChange}
                                            propsSpanish={{
                                                title: spanishTitle,
                                                description: spanishDesc,
                                                setTitle: setSpanishTitle,   // Paso la función setter aquí
                                                setDesc: setSpanishDesc,
                                            }}
                                            propsPortugese={{
                                                title: portugueseTitle,
                                                description: portugueseDesc,
                                                setTitle: setPortugueseTitle,   // Paso la función setter aquí
                                                setDesc: setPortugueseDesc,
                                            }}
                                            propsEnglish={{
                                                title: englishTitle,
                                                description: englishDesc,
                                                setTitle: setEnglishTitle,   // Paso la función setter aquí
                                                setDesc: setEnglishDesc,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            height: 'auto',
                                        }}
                                    >
                                        {imageToShow && (
                                            <img style={{ height: '15rem', width: '15rem', borderRadius: '1rem' }} src={`${imageToShow}`} alt="logo" />
                                        )}
                                        {!editing && (
                                            <button onClick={handleEditClick} style={{ marginTop: "10px", marginLeft: "-40px" }}>
                                                Editar fondo
                                            </button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            <ModalFile isOpen={editing} openModalHandler={openModalHandler} closeModalHandler={closeModalHandler}></ModalFile>
                        </>
                    )
                }
            </Box >
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {success ? "Cambios guardados" : "Error al modificar alguna/s portada/s"}
                </Alert>
            </Snackbar>


        </>
    );
}

export default CoverEditionPage;