import axios from "axios"
import { API_BACKOFFICE_URL } from "../constants/api";

export interface ISettingsUrl {
  id: string;
  url_landing: string;
}
export interface TermsAndConditionsData {
  id: string;
  termscond: string;
}
export interface ICoverLanguage {
  id: string;
  title: string;
  description: string;
  language: "spanish" | "english" | "portuguese";
  file_enconded: string;
}

export const LogIn = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_BACKOFFICE_URL}/admin/token`, {
      email,
      password
    });

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const GetAllCoversBySettingId = async (settingId: string) => {
  try {
    const response = await axios.get(`${API_BACKOFFICE_URL}/admin/settings/${settingId}`);

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const GetCoverBySettingIdAndLanguage = async (language: string, settingId: string) => {
  try {
    const response = await axios.get(`${API_BACKOFFICE_URL}/admin/settings/${settingId}/cover/one/${language}`);

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateCoverBySettingIdAndLanguage = async (body: any, language: string, settingId: string) => {
  try {
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/${settingId}/cover/${language}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const CreateSetting = async (body: any) => {
  try {
    const response = await axios.post(`${API_BACKOFFICE_URL}/admin/settings/create`, {
      body
    });

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const GetAllSettings = async () => {
  try {
    const response = await axios.get(`${API_BACKOFFICE_URL}/admin/settings` , { withCredentials: false });

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const GetSettingById = async (settingId: string) => {
  try {
    const response = await axios.get(`${API_BACKOFFICE_URL}/admin/settings/one/${settingId}`);

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateSetting = async (body: any, settingId: string) => {
  try {
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/${settingId}`, {
      body
    });

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateFileCoverSetting = async (selectedFile: File, settingId: string) => {
  try {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/file/${settingId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateAllCovers = async (bodySpanish: any, spanishId: string, bodyEnglish: any, englishId: string,
  bodyPortuguese: any, portugueseId: string) => {
  try {
    const spanish = await axios.put(`${API_BACKOFFICE_URL}/admin/cover/${spanishId}`, bodySpanish, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const english = await axios.put(`${API_BACKOFFICE_URL}/admin/cover/${englishId}`, bodyEnglish, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const portuguese = await axios.put(`${API_BACKOFFICE_URL}/admin/cover/${portugueseId}`, bodyPortuguese, {
      headers: {
        'Content-Type': 'application/json',
      },
    });


    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export const UpdateSettings = async (formData: FormData, id: string) => {
  try {
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/url/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateSettingss = async (formData: FormData, id: string) => {
  try {
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateSettingsJson = async (dataToUpdate: object, id: string) => {
  try {
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/${id}`, dataToUpdate, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    console.error(err);
  }
}

export const GetSelectedBanner = async () => {
  try {
    const response = await axios.get(`${API_BACKOFFICE_URL}/banner/select`);

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const UpdateTermsAndConditionsSetting = async (id: string, data: TermsAndConditionsData) => {
  try {
    const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    console.error(err);
  }
}