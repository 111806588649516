import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import {
    Box,
    Button,
    Modal,
    Step,
    StepLabel,
    Stepper,
    Typography,
    TextField,
    styled,
    Select,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    InputLabel,
} from "@mui/material";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { createBanner } from '../../../../../../services/banner';
import LoadingModal from '../../../../../../services/loading';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "", // Cambia el color del asterisco a rojo
    },
});

const StyledFileInput = styled(TextField)`
  input[type='file'] {
    display: none;
  }
`;

const fechaActual = new Date();
const ultimoDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);

// Obtén los componentes de la fecha (día, mes, año)
const dia = fechaActual.getDate();
const ultimoDia = ultimoDiaDelMes.getDate();
const mes = fechaActual.getMonth() + 1; // Los meses son indexados desde 0, por lo que sumamos 1
const año = fechaActual.getFullYear();

const initialState = {
    id: '',
    nombre: '',
    tipoPubli: '',
    tipoCampaña: '',
    link: '',
    peso: 0,
    apariciones: 0,
    porcentaje: '',
    cantidad: '',
    isCheckedSplash: false,
    display_seconds: 0,
    isCheckedRegistro: false,
    isCheckedPreLogin: false,
    isCheckedLanding: false,
    selectedStartDate: dayjs(`${año}-${mes}-${dia}`),
    selectedEndDate: dayjs(`${año}-${mes}-${ultimoDia}`),
    file: '',

};

type ModalFormularioProps = {
    isOpen: boolean;
    openModalHandler: () => void;
    closeModalHandler: (create: boolean) => void;
};

const ModalBannerCreate: React.FC<ModalFormularioProps> = ({ isOpen, openModalHandler, closeModalHandler }) => {

    const [activeStep, setActiveStep] = useState(0);

    const [formData, setFormData] = useState(initialState);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetFormData = () => {
        setFormData(initialState);
    };

    const [errorMessage, setErrorMessage] = useState('');
    const handleNext = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep + 1);
    };

    const handlePrevius = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep - 1);
    };

    function clearBanner() {
        setId("");
        setNombre("");
        setTipoPubli("");
        setTipoCampaña("");
        setLink("");
        setIsCheckedSplash(false);
        setDisplay_seconds(1)
        setIsCheckedPreLogin(false);
        setIsCheckedRegistro(false);
        setIsCheckedLanding(false);
        setSelectedStartDate(dayjs(`${año}-${mes}-${dia}`));
        setSelectedEndDate(dayjs(`${año}-${mes}-${ultimoDia}`));
        setApariciones(0);
        setCantidad("");
        setPeso(0);
        setPorcentaje("");
    }
    const handleSave = async () => {
        setIsLoading(true);
        setErrorMessage('');

        const startDate = dayjs(selectedStartDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss") + 'Z';
        const endDate = dayjs(selectedEndDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss") + 'Z';
                   
        const data = { id, nombre, tipoCampaña, tipoPubli, link, isCheckedSplash, display_seconds, isCheckedRegistro, isCheckedPreLogin, isCheckedLanding, startDate, endDate, peso, apariciones, cantidad, porcentaje };

        const formData = new FormData();
        formData.append('name', nombre);
        formData.append('banner_type', tipoPubli);
        formData.append('redirect_url', link);
        formData.append('file', file);
        formData.append('key_name', nombre);
        formData.append('campaign_type', tipoCampaña);
        formData.append('state', "true");
        formData.append('weight', peso.toString());
        formData.append('total_appearance', apariciones.toString());
        formData.append('cantidad', cantidad);
        formData.append('porcentaje', porcentaje);

        formData.append('splash_page', isCheckedSplash ? "true" : "false");
        formData.append('display_seconds', display_seconds.toString());
        formData.append('pre_login', isCheckedPreLogin ? "true" : "false");
        formData.append('pre_registro', isCheckedRegistro ? "true" : "false");
        formData.append('landing_page', isCheckedLanding ? "true" : "false");

        formData.append('start_date', startDate);
        formData.append('end_date', endDate);

        const result = await createBanner(formData, apariciones)
            .then((response) => {
                clearBanner();
                setActiveStep(0);
                closeModalHandler(true);
                handleSuccessModalOpen();
            })
            .catch((error) => {
                if (error.response) {
                    const property = "error: ";
                    setErrorMessage(error.response.data[property]);
                } else {
                    setErrorMessage("Error al crear el banner.");
                }
            });

        setIsLoading(false);
    };

    const steps = ["Multimedia", "Presentación", "Lógica"]

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }

    const handleCancel = () => {
        closeModalHandler(false);
        clearBanner();

        setErrorMessage('');
    };

    const [id, setId] = useState('');
    const [nombre, setNombre] = useState('');
    const [tipoPubli, setTipoPubli] = useState('');
    const [tipoCampaña, setTipoCampaña] = useState('');
    const [link, setLink] = useState('');
    const [peso, setPeso] = useState(0);
    const [apariciones, setApariciones] = useState(0);
    const [porcentaje, setPorcentaje] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [isCheckedSplash, setIsCheckedSplash] = useState(false);
    const [isCheckedRegistro, setIsCheckedRegistro] = useState(false);
    const [isCheckedPreLogin, setIsCheckedPreLogin] = useState(false);
    const [isCheckedLanding, setIsCheckedLanding] = useState(false);

    const handlNombredChange = (event: any) => {
        setNombre(event.target.value);
        validateForm();
    };
    const handleTipoPubliChange = (event: any) => {
        setTipoPubli(event.target.value);
        validateForm();
    };
    const handleTipoCampañaChange = (event: any) => {
        setTipoCampaña(event.target.value);
        validateForm();
    };
    const handleLinkChange = (event: any) => {
        setLink(event.target.value);
    };
    const handleCheckboxSplash = (event: any) => {
        setIsCheckedSplash(event.target.checked);
    };
    const handleCheckboxRegistro = (event: any) => {
        setIsCheckedRegistro(event.target.checked);
    };
    const handleCheckboxPreLogin = (event: any) => {
        setIsCheckedPreLogin(event.target.checked);
    };
    const handleCheckboxLanding = (event: any) => {
        setIsCheckedLanding(event.target.checked);
    };

    const [selectedStartDate, setSelectedStartDate] = React.useState<Dayjs | null>(dayjs(`${año}-${mes}-${dia}`));
    const [selectedEndDate, setSelectedEndDate] = React.useState<Dayjs | null>(dayjs(`${año}-${mes}-${ultimoDia}`));

    const handlePesoChange = (event: any) => {
        setPeso(event.target.value);
        validateForm1();
    };
    const handleAparicionesChange = (event: any) => {
        setApariciones(event.target.value);
        validateForm1();
    };
    const handlePorcentajeChange = (event: any) => {
        setPorcentaje(event.target.value);
        validateForm1();
    };
    const handleCantidadChange = (event: any) => {
        setCantidad(event.target.value);
        validateForm1();
    };

    const fileInputRef = useRef(null);
    const [file, setFile] = useState('');

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        validateForm();
    };

    const handleClick = () => {
        /*if (fileInputRef.current){
          fileInputRef.current.click();
        }*/

    };
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const handleSuccessModalOpen = () => {
        setSuccessModalOpen(true);
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const validateForm = () => {
        const requiredFields = [nombre, tipoCampaña, tipoPubli, file];
        const isFormValid = requiredFields.every((field) => !!field);

        return isFormValid;
    };

    const validateForm1 = () => {
        const requiredFields = [peso, apariciones, porcentaje, cantidad];
        const isFormValid1 = requiredFields.every((field) => !!field);

        return isFormValid1;
    };

    const [isFormInitialized, setIsFormInitialized] = useState(false);

    useEffect(() => {
        if (isOpen) {
            validateForm();
            validateForm1();

            if (!isFormInitialized) {
                setIsFormInitialized(true);
            }
        } else {
            setIsFormInitialized(false);
        }
    }, [isOpen]);

    const handleOpenModal = () => {
        setIsFormInitialized(true);
        resetFormData();
        openModalHandler();
    };

    const [display_seconds, setDisplay_seconds] = useState(1);

    const handleDisplaySecondsChange = (event: { target: { value: any; }; }) => {
        const value = event.target.value;

        const regex = /^\d*\.?\d*$/;
        if (value === '' || (regex.test(value) && parseInt(value) !== 0)) {
            setDisplay_seconds(value);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {isLoading && <LoadingModal />}
            <Modal
                open={isOpen}
                onClose={closeModalHandler}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "32px",
                        width: "650px",
                        flexDirection: "column",
                        justifyContent: "center",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Typography sx={{ color: "#274B5B", fontSize: "22px", paddingTop: "32px", paddingLeft: "42px", marginBottom: "-10px" }}>
                        Crear campaña
                    </Typography>
                    <Stepper sx={{ marginX: '42px', color: '#FFFFFF' }} activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step sx={{
                                '& .MuiStepIcon-root': {
                                    width: '28px', // Cambia el tamaño del círculo del paso
                                    height: '28px', // Cambia el color del ícono del paso
                                },
                                '& .MuiStepLabel-label': {
                                    fontSize: '14px', // Cambia el tamaño de la letra del paso
                                    color: primaryColor,
                                },
                                '& .MuiStepIcon-text': {
                                    fill: '#FFFFFF', // Cambia el color del número interior
                                },
                            }} key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 && (
                        <div>
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <StyledTextField
                                    required
                                    id="nombre"
                                    label="Nombre de la campaña"
                                    name="nombre"
                                    autoComplete="identity"
                                    variant="outlined"
                                    value={nombre}
                                    onChange={(event) => {
                                        setNombre(event.target.value);
                                        validateForm();
                                    }}
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        margin: "42px",
                                        height: "32px",
                                        marginBottom: "35px",
                                        marginTop: "10px",
                                        alignContent: "center",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                />
                                <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                    <InputLabel sx={{
                                        marginLeft: "42px", "&.MuiInputLabel-required": {
                                            color: "red",
                                        },
                                    }} id="campaign_type" htmlFor="campaign_type">Tipo de campaña *</InputLabel>
                                    <Select
                                        required
                                        labelId="campaign_type"
                                        label="Tipo de campaña"
                                        name="campaign_type"
                                        value={tipoCampaña}
                                        onChange={handleTipoCampañaChange}
                                        sx={{
                                            marginLeft: "42px",
                                            marginRight: "42px",
                                            marginBottom: "-15px",
                                            "& fieldset": {
                                                borderRadius: "0.5rem",
                                                "& legend": {
                                                    "& span": {
                                                        color: "#F68D2E",
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="recomendación de producto/servicio">Recomendación de Producto/Servicio</MenuItem>
                                        <MenuItem value="descarga de aplicación">Descarga de Aplicación</MenuItem>
                                        <MenuItem value="capacitación de seguidores">Capacitación de Seguidores</MenuItem>
                                        <MenuItem value="sorteos/promociones">Sorteos/promociones</MenuItem>
                                    </Select>
                                </FormControl>
                                <StyledTextField
                                    id="link"
                                    label="URL de redirección"
                                    name="URL de redirección"
                                    autoComplete="identity"
                                    variant="outlined"
                                    value={link}
                                    onChange={handleLinkChange}
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        margin: "42px",
                                        height: "32px",
                                        marginBottom: "40px",
                                        alignContent: "center",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                />
                                <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                    <InputLabel sx={{ marginLeft: "42px" }} id="banner_type">Tipo de publicación *</InputLabel>
                                    <Select
                                        required
                                        id="banner_type"
                                        label="Tipo de publicación"
                                        name="banner_type"
                                        autoComplete="identity"
                                        value={tipoPubli}
                                        onChange={handleTipoPubliChange}
                                        sx={{
                                            display: "flex",
                                            marginLeft: "42px",
                                            marginRight: "42px",
                                            marginBottom: "-15px",
                                            "& fieldset": {
                                                borderRadius: "0.5rem",
                                                "& legend": {
                                                    "& span": {
                                                        color: "#F68D2E",
                                                    },
                                                },
                                            },
                                        }}>
                                        <MenuItem value="imagen">Imagen</MenuItem>
                                        <MenuItem value="video">Video</MenuItem>
                                        <MenuItem value="gif">GIF</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ display: "flex", gap: "10px", margin: "42px", height: "32px", marginBottom: "-10px" }}>
                                    <input
                                        required
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".png,.jpg,.jpeg,.mp4"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="fileInput" style={{ display: "block", marginLeft: "-5px" }}>
                                        *
                                    </label>
                                </div>
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleCancel}
                                        sx={{
                                            backgroundColor: "white",
                                            color: primaryColor,
                                            width: "90px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            color: "white",
                                            width: "90px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                        }}
                                        disabled={!validateForm()}
                                    >
                                        Siguiente
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    )}
                    {activeStep === 1 && (
                        <div>
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <Box sx={{ marginLeft: '42px', color: '#8C8C8C' }}>Hace clic en los intersticial que quieras mostrar en la presentación.</Box>
                                <FormControl component="fieldset" sx={{ marginLeft: '42px', marginTop: '42px' }}>
                                    <FormLabel sx={{ color: '#374151', fontSize: '16px' }} component="legend">Intersticial visible:</FormLabel>
                                    <FormGroup aria-label="position" sx={{ marginLeft: '58px' }}>
                                        <FormControlLabel
                                            value="top"
                                            sx={{ marginBottom: '10px', marginTop: '10px' }}
                                            control={<Checkbox checked={isCheckedSplash} onChange={handleCheckboxSplash} />}
                                            label="SplashPage"
                                            labelPlacement="end"
                                        />
                                        {isCheckedSplash && (
                                            <TextField
                                                label="Visualizar SplashPage (segundos)"
                                                value={display_seconds}
                                                onChange={handleDisplaySecondsChange}
                                                sx={{
                                                    marginTop: '-55px',
                                                    marginLeft: '140px',
                                                    alignContent: "center",
                                                    "& fieldset": {
                                                        borderRadius: "0.5rem",
                                                        "& legend": {
                                                            "& span": {
                                                                color: "#F68D2E",
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                        <FormControlLabel
                                            value="top"
                                            sx={{ marginBottom: '10px' }}
                                            control={<Checkbox checked={isCheckedRegistro} onChange={handleCheckboxRegistro} />}
                                            label="Pre-registro"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="top"
                                            sx={{ marginBottom: '10px' }}
                                            control={<Checkbox checked={isCheckedPreLogin} onChange={handleCheckboxPreLogin} />}
                                            label="Pre-login"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="top"
                                            sx={{ marginBottom: '10px' }}
                                            control={<Checkbox checked={isCheckedLanding} onChange={handleCheckboxLanding} />}
                                            label="LandingPage"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        marginTop: '42px'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handlePrevius}
                                        sx={{
                                            backgroundColor: "white",
                                            color: primaryColor,
                                            width: "90px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Anterior
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            color: "white",
                                            width: "90px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Siguiente
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    )}
                    {activeStep === 2 && (
                        <div>
                            <Box component="form">
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '40px', marginTop: "15px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DatePicker label="Fecha de alta *" value={selectedStartDate} onChange={(newValue) => setSelectedStartDate(newValue)} />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '20px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DatePicker label="Fecha de baja *" value={selectedEndDate} onChange={(newValue) => setSelectedEndDate(newValue)} />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <FormControl sx={{ mt: '1rem', width: '100%', marginLeft: "-10px" }}>
                                        <InputLabel sx={{ marginLeft: "42px" }} >Prioridad de campaña *</InputLabel>
                                        <Select
                                            required
                                            id="porcentaje"
                                            label="Prioridad de campaña"
                                            name="porcentaje"
                                            autoComplete="identity"
                                            value={porcentaje}
                                            onChange={handlePorcentajeChange}
                                            sx={{
                                                display: "flex",
                                                marginLeft: "42px",
                                                marginRight: "42px",
                                                marginBottom: "-15px",
                                                "& fieldset": {
                                                    borderRadius: "0.5rem",
                                                    "& legend": {
                                                        "& span": {
                                                            color: "#F68D2E",
                                                        },
                                                    },
                                                },
                                            }}>
                                            <MenuItem value="por peso">Por peso</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ display: 'flex', width: '566px', heigth: '63px', flexDirection: 'column', marginInline: '33px', color: '#274B5B' }}>
                                        <InputLabel sx={{ marginLeft: "42px" }} id="weight" ></InputLabel>
                                        <StyledTextField
                                            required
                                            id="weight"
                                            type="number"
                                            label="Porcentaje"
                                            name="weight"
                                            autoComplete="identity"
                                            variant="outlined"
                                            value={peso}
                                            onChange={(event) => {
                                                const newValue = parseInt(event.target.value, 10);
                                                setPeso(newValue);
                                                validateForm();
                                            }}
                                            sx={{
                                                display: "flex",
                                                height: "5px",
                                                marginTop: "16px",
                                                alignContent: "center",
                                                "& fieldset": {
                                                    borderRadius: "0.5rem",
                                                    "& legend": {
                                                        "& span": {
                                                            color: "#F68D2E",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <FormControl sx={{ mt: '2rem', width: '100%', marginLeft: "-10px" }}>
                                        <InputLabel sx={{ marginLeft: "42px" }} >Tasa de aparición *</InputLabel>
                                        <Select
                                            required
                                            id="cantidad"
                                            label="Tasa de aparición"
                                            name="cantidad"
                                            autoComplete="identity"
                                            value={cantidad}
                                            onChange={handleCantidadChange}
                                            sx={{
                                                display: "flex",
                                                marginLeft: "42px",
                                                marginRight: "42px",
                                                marginBottom: "15px",
                                                "& fieldset": {
                                                    borderRadius: "0.5rem",
                                                    "& legend": {
                                                        "& span": {
                                                            color: "#F68D2E",
                                                        },
                                                    },
                                                },
                                            }}>
                                            <MenuItem value="cantidad de apariciones">Cantidad de apariciones</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ display: 'flex', width: '566px', heigth: '63px', flexDirection: 'column', marginInline: '33px', color: '#274B5B' }}>
                                        <InputLabel sx={{ marginLeft: "42px" }} id="total_appearance" ></InputLabel>
                                        <StyledTextField
                                            required
                                            id="total_appearance"
                                            label="Cantidad Limitada"
                                            name="total_appearance"
                                            autoComplete="identity"
                                            variant="outlined"
                                            type="number"
                                            value={apariciones}
                                            onChange={(event) => {
                                                const newValue = parseInt(event.target.value, 10);
                                                setApariciones(newValue);
                                                validateForm();
                                            }}
                                            sx={{
                                                display: "flex",
                                                height: "15px",
                                                marginTop: "30px",
                                                alignContent: "center",
                                                "& fieldset": {
                                                    borderRadius: "0.5rem",
                                                    "& legend": {
                                                        "& span": {
                                                            color: "#F68D2E",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                {errorMessage && <div style={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}><p style={{ color: 'red' }}>{errorMessage}</p></div>}
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handlePrevius}
                                        sx={{
                                            backgroundColor: "white",
                                            color: primaryColor,
                                            width: "90px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColor,
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Anterior
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleSave}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            color: "white",
                                            width: "90px",
                                            padding: "10px",
                                            fontSize: "14px",
                                            margin: "10px",
                                            "&:hover": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                            "&:active": {
                                                backgroundColor: primaryColorHover,
                                                color: "white",
                                            },
                                        }}
                                        disabled={!validateForm1()}
                                    >
                                        Finalizar
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    )}
                </Box>
            </Modal >
        </>
    );
};

export default ModalBannerCreate;
