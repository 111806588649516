import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    styled,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createUser } from '../../../../../services/user';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SelectChangeEvent } from '@mui/material/Select';
import { getGroups } from "../../../../../services/group";


const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "", // Cambia el color del asterisco a rojo
    },
});

const StyledFileInput = styled(TextField)`
  input[type='file'] {
    display: none;
  }
`;

const initialState = {
    name: '',
    email: '',
    password: '',
    group_id: '',
};

type ModalFormularioProps = {
    isOpen: boolean;
    openModalHandler: () => void;
    closeModalHandler: (create: boolean) => void;
};

const ModalCreate: React.FC<ModalFormularioProps> = ({
    isOpen,
    openModalHandler,
    closeModalHandler,
}) => {

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState(initialState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [isFormInitialized, setIsFormInitialized] = useState(false);
    const [groups, setGroups] = useState([]);
    const [availableGroups, setAvailableGroups] = useState<{ name: string, id: string }[]>([]);
    const [groupid, setGroupid] = useState('');
    const [group_id, setGroup_id] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');


    const resetFormData = () => {
        setFormData(initialState);
    };


    const [errorMessage, setErrorMessage] = useState('');
    const handleNext = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep + 1);
    };

    const handlePrevius = () => {
        // Lógica para avanzar a la siguiente pestaña o realizar acciones adicionales
        setActiveStep(activeStep - 1);
    };

    function clearBanner() {
        setId("");
        setName("");
        setEmail("");
        setPassword("");
        setGroup_id("");
    }

    const handleSave = async () => {
        const data = { id, name, email, password, groupid };
        const body = {
            name: name,
            email: email,
            password: password,
            groupid: formData.group_id,
        };

        await createUser(id, body);

        clearBanner();
        setActiveStep(0);
        closeModalHandler(true);
        handleSuccessModalOpen();

        setFormData((prevFormData) => ({
            ...prevFormData,
            group_id: '',
        }));
    }

    const handleCancel = () => {
        closeModalHandler(false);
        clearBanner();
        setErrorMessage('');

        setFormData((prevFormData) => ({
            ...prevFormData,
            group_id: '',
        }));
    };


    const handleNamedChange = (event: any) => {
        setName(event.target.value);
        validateForm();
    };
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
        validateForm();
    };
    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
        validateForm();
    };
    const handleGroupsChange = (event: SelectChangeEvent<string>) => {
        const selectedGroup = event.target.value;
        setFormData({
            ...formData,
            group_id: selectedGroup,
        });
        validateForm();
    };




    const handleClick = () => {
        
    };

    const handleSuccessModalOpen = () => {
        setSuccessModalOpen(true);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const validateForm = () => {
        const requiredFields = [name, email, password, formData.group_id];
        const isFormValid = requiredFields.every((field) => !!field);

        return isFormValid;
    };


    useEffect(() => {
        if (isOpen && !isFormInitialized) {
            setIsFormInitialized(true);
            resetFormData();
            fetchGroups();
        }
    }, [isOpen, isFormInitialized]);


    const handleOpenModal = () => {
        setIsFormInitialized(true);
        resetFormData();
        openModalHandler();
    };

    const fetchGroups = async () => {
        try {
            const groupsData = await getGroups();
            setAvailableGroups(groupsData);
        } catch (error) {
            console.error("Error al obtener los grupos:", error);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography sx={{ color: "#274B5B", fontSize: "22px", paddingTop: "32px", paddingLeft: "42px", marginBottom: "-10px" }}>
                    Crear usuario
                </Typography>

                {activeStep === 0 && (
                    <div>
                        <StyledTextField
                            required
                            id="name"
                            label="Nombre"
                            name="nombre"
                            autoComplete="identity"
                            variant="outlined"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                                validateForm();
                            }}
                            sx={{
                                display: "flex",
                                gap: "10px",
                                margin: "42px",
                                height: "32px",
                                marginBottom: "45px",
                                marginTop: "10px",
                                alignContent: "center",
                                "& fieldset": {
                                    borderRadius: "0.5rem",
                                    "& legend": {
                                        "& span": {
                                            color: "#F68D2E",
                                        },
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="email"
                            label="Correo electrónico"
                            name="email"
                            autoComplete="identity"
                            variant="outlined"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                validateForm();
                            }}
                            sx={{
                                display: "flex",
                                gap: "10px",
                                margin: "42px",
                                height: "32px",
                                marginBottom: "45px",
                                marginTop: "10px",
                                alignContent: "center",
                                "& fieldset": {
                                    borderRadius: "0.5rem",
                                    "& legend": {
                                        "& span": {
                                            color: "#F68D2E",
                                        },
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="password"
                            label="Contraseña"
                            name="password"
                            autoComplete="identity"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value);
                                validateForm();
                            }}
                            sx={{
                                display: "flex",
                                gap: "10px",
                                margin: "42px",
                                height: "32px",
                                marginBottom: "30px",
                                marginTop: "10px",
                                alignContent: "center",
                                "& fieldset": {
                                    borderRadius: "0.5rem",
                                    "& legend": {
                                        "& span": {
                                            color: "#F68D2E",
                                        },
                                    },
                                },
                            }}
                        />
                        <Button
                            onClick={() => setShowPassword(!showPassword)}
                            sx={{
                                backgroundColor: "transparent",
                                border: "none",
                                outline: "none",
                                cursor: "pointer",
                                marginLeft: "540px",
                                marginTop: "-100px",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                },
                                '&:active': {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </Button>

                        {availableGroups && availableGroups.length > 0 && (
                            <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                <InputLabel sx={{ marginLeft: "42px", marginTop: "-23px" }} id="groups">Grupos*</InputLabel>
                                <Select
                                    required
                                    id="groups"
                                    label="Grupos"
                                    name="groups"
                                    autoComplete="identity"
                                    value={formData.group_id}
                                    onChange={handleGroupsChange }
                                    sx={{
                                        display: "flex",
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: "15px",
                                        marginTop: "-23px",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {availableGroups.map((group) => (
                                        <MenuItem key={group.name} value={group.id}>
                                            {group.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <Box
                            sx={{
                                padding: "10px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleCancel}
                                sx={{
                                    backgroundColor: "white",
                                    color: primaryColor,
                                    width: "90px",
                                    padding: "10px",
                                    fontSize: "14px",
                                    margin: "10px",
                                    "&:hover": {
                                        backgroundColor: primaryColor,
                                        color: "white",
                                    },
                                    "&:active": {
                                        backgroundColor: primaryColor,
                                        color: "white",
                                    },
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSave}
                                sx={{
                                    backgroundColor: primaryColor,
                                    color: "white",
                                    width: "90px",
                                    padding: "10px",
                                    fontSize: "14px",
                                    margin: "10px",
                                    "&:hover": {
                                        backgroundColor: primaryColorHover,
                                        color: "white",
                                    },
                                    "&:active": {
                                        backgroundColor: primaryColorHover,
                                        color: "white",
                                    },
                                }}
                                disabled={!validateForm() || isSubmitting}
                            >
                                Finalizar
                            </Button>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};
export default ModalCreate;
