import React, { useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Typography, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { GetAllSettings, TermsAndConditionsData, UpdateTermsAndConditionsSetting } from '../../../../services/index';
import FloatingImage from '../../../FloatingImage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const TermsAndConditions = () => {

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(true);
    const [id, setId] = useState<string>('');
    const [termscond, setTermscond] = useState<string>('');

    const handleLinkChange = (value: string) => {
        setTermscond(value);
    };

    useLayoutEffect(() => {
        const getAll = async () => {
            const settingsData: TermsAndConditionsData[] = await GetAllSettings();
            if (settingsData && settingsData.length > 0) {
                const firstSettings = settingsData[0];
                setId(firstSettings.id);

                const urlWithoutPrefix = firstSettings.termscond;
                setTermscond(urlWithoutPrefix);
            }
            setIsLoading(false);
        };
        getAll();
    }, []);


    const handleSnackbarClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (id) {
            try {
                const data: TermsAndConditionsData = {
                    id: id,
                    termscond: termscond
                };
                const response = await UpdateTermsAndConditionsSetting(id, data);
                setSuccess(true);
            } catch (error) {
                console.error("Error al enviar los datos", error);
                setSuccess(false);
            }

            setSnackbarOpen(true);
        } else {
            console.error("ID no válido");
        }
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px", marginBottom: "-65px" }}>Configuración del sistema</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 600,
                                        ml: '1rem',
                                        marginRight: "22px",
                                        marginTop: "15px",
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        },
                                        '&:active': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        }
                                    }}
                                    onClick={handleSnackbarClick}
                                >
                                    Guardar cambios
                                </Button>
                            </Box>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "-15px" }}>Terminos y Condiciones</Typography>

                            <Box
                                sx={{
                                    p: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'auto',
                                    width: 'auto',
                                }}
                            >
                                <Box>
                                    <ReactQuill
                                        value={termscond}
                                        onChange={handleLinkChange}
                                    />
                                </Box>
                            </Box>
                        </>
                    )
                }
                <FloatingImage />
            </Box >
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {success ? "Cambios guardados" : "Error al modificar la URL"}
                </Alert>
            </Snackbar>

        </>
    );
}

export default TermsAndConditions;