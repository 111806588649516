import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip, Legend } from 'recharts';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
interface LanguageTableProps {
    start_date: Dayjs | null;
    end_date: Dayjs | null;
    totalLanguages: number;
    languageData: { name: string; value: number }[];
}

export const LanguageTable: React.FC<LanguageTableProps> = ({ start_date, end_date, totalLanguages, languageData }) => {
    const getColor = (index: number) => {
        const colors = ['#ff6f61', '#9966CC', '#82ca9d', '#FFC0CB', '#FFD700', '#40E0D0', '#ffc658', '#8884d8'];
        return colors[index % colors.length];
    };

    return (
        <div>
            <h3>Idiomas: {languageData.length}</h3>

            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        dataKey="value"
                        data={languageData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        label={(entry: { name: any; value: number; }) => `${entry.name} (${((entry.value / totalLanguages) * 100).toFixed(2)}%)`}
                    >
                        {languageData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(index)} />
                        ))}
                    </Pie>
                    <Tooltip formatter={(value: number, name: any) => [`${name} (${((value as number / totalLanguages) * 100).toFixed(2)}%)`]} />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

