import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { Box, Button, Modal, Typography, TextField, styled } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { updateBannerDelete } from '../../../../../../../services/banner';

const StyledTextField = styled(TextField)({
  "& .MuiInputLabel-asterisk": {
    color: "red", // Cambia el color del asterisco a rojo
  },
});


type ModalFormularioProps = {
  isOpen: boolean;
  entryData: any;
  openModalHandler: () => void;
  closeModalHandler: () => void;
  updateBannerList: any;
  setShow: any;
  rows: { id: string; company: string; campaign_type: string; banner_type: string; state: string; file_url: string; }[];
};

const ModalDeleteMultimedia: React.FC<ModalFormularioProps> = ({
  isOpen,
  entryData,
  openModalHandler,
  closeModalHandler,
  updateBannerList,
  setShow,
  rows,
}) => {

  const [activeStep, setActiveStep] = useState(0);
  const [banners, setBanners] = useState([]);

  const handleDelete = async () => {
    try {
      await updateBannerDelete(entryData?.id);
      closeModalHandler();

      updateBannerList();

      if (rows.length === 1) {
        setShow(false);
      }
    } catch (error) {
      console.error("Error al eliminar el banner:", error);
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
  }
  const handleCancel = () => {
    // Lógica para guardar los datos del formulario
    setActiveStep(0);
    closeModalHandler();
  };

  const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
  const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

  return (
    <Modal
      open={isOpen}
      onClose={closeModalHandler}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "32px",
          width: "650px",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          sx={{
            color: "#274B5B",
            fontSize: "22px",
            fontStyle: "normal",
            paddingTop: "32px",
            paddingLeft: "42px",
            marginBottom: "-40px"
          }}
        >
          ¿Quieres ELIMINAR la campaña seleccionada?
        </Typography>
        {activeStep === 0 && (
          <div>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Box
                sx={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleCancel}
                  sx={{
                    backgroundColor: "white",
                    color: primaryColor,
                    width: "90px",
                    padding: "10px",
                    fontSize: "14px",
                    margin: "10px",
                    "&:hover": {
                      backgroundColor: primaryColor,
                      color: "white",
                    },
                    "&:active": {
                      backgroundColor: primaryColor,
                      color: "white",
                    },
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  sx={{
                    backgroundColor: primaryColor,
                    color: 'white',
                    width: "90px",
                    padding: "10px",
                    fontSize: "14px",
                    margin: "10px",
                    "&:hover": {
                      backgroundColor: primaryColorHover,
                      color: "white",
                    },
                    "&:active": {
                      backgroundColor: primaryColorHover,
                      color: "white",
                    },
                  }}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ModalDeleteMultimedia;