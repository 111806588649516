import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { Box, Button, Modal, Typography, TextField, styled, FormControl } from "@mui/material";
import React, { FormEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { updateBanner } from '../../../../../../../services/banner';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    tab: number;
    openModalHandler: () => void;
    closeModalHandler: (result: boolean) => void;
    updateBannerList: any;
};

const ModalBannerEdit: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    tab,
    openModalHandler,
    closeModalHandler,
    updateBannerList,
}) => {

    const [activeStep, setActiveStep] = useState(0);

    const [estadoActivo, setEstadoActivo] = useState(true);
    const [selectedStartDate, setSelectedStartDate] = React.useState<Dayjs | null>(dayjs(entryData?.start_date));
    const [selectedEndDate, setSelectedEndDate] = React.useState<Dayjs | null>(dayjs(entryData?.end_date));
    const [selectedPeso, setSelectedPeso] = React.useState(entryData?.weigth);
    const [selectedApariciones, setSelectedApariciones] = React.useState(entryData?.total_appearance);


    useEffect(() => {
        setSelectedStartDate(dayjs(entryData?.start_date));
        setSelectedEndDate(dayjs(entryData?.end_date));
    }, [entryData]);

    useLayoutEffect(() => {

        if (entryData && entryData.weight) {

            setPeso(entryData.weight);
        }
        if (entryData && entryData.total_appearance) {

            setApariciones(entryData.total_appearance);
        }

    }, [entryData]);


    const handleSave = async () => {
      
        const startDate = dayjs(selectedStartDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss") + 'Z';
        const endDate = dayjs(selectedEndDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss") + 'Z';
       
        const data = { nombre, tipoPubli, startDate, endDate, peso, apariciones };

        const formData = new FormData();
        const body = {
            start_date: startDate,
            end_date: endDate,
            weight: peso,
            total_appearance: apariciones,
        };

        updateBanner(entryData.id, body);
        updateBannerList();

        closeModalHandler(true);
    };

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }
    const handleCancel = () => {
        // Lógica para guardar los datos del formulario
        setActiveStep(0);
        closeModalHandler(false);
    };

    const [nombre, setNombre] = useState('');
    const [tipoPubli, setTipoPubli] = useState('');


    const handlNombredChange = (event: any) => {
        setNombre(event.target.value);
    };
    const handleTipoPubliChange = (event: any) => {
        setTipoPubli(event.target.value);
    };

    const handleChangeStartDate = (event: any) => {

    };

    const [peso, setPeso] = useState(entryData?.weight);
    const handlePesoChange = (event: any) => {
        setPeso(event.target.value);
    };

    const [apariciones, setApariciones] = useState(entryData?.total_appearance);
    const handleAparicionesChange = (event: any) => {
        setApariciones(event.target.value);
    };

    const fileInputRef = useRef(null);

    const [file, setFile] = useState('');
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        // Realizar acciones con el archivo seleccionado
    };


    const renderOptions = () => {
        const options = [];

        for (let i = 0; i <= 100; i += 1) {
            const optionValue = i.toString();
            const optionLabel = `${i}%`;
            options.push(<option value={optionValue} key={optionValue}>{optionLabel}</option>);
        }

        return options;
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        paddingTop: "32px",
                        paddingLeft: "42px"
                    }}
                >
                    Editar campaña
                </Typography>
                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} /*noValidate*/>
                            <StyledTextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id="nombre"
                                label="Nombre"
                                name="nombre"
                                autoComplete="identity"
                                variant="outlined"
                                value={entryData?.name ? entryData.name : ''}
                                onChange={handlNombredChange}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginTop: "5px",
                                    marginBottom: "55px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '30px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DatePicker
                                        label="Fecha de alta"
                                        value={dayjs(entryData?.start_date)}
                                        onChange={(newValue) => { setSelectedStartDate(newValue) }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '28px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DatePicker
                                        label="Fecha de baja"
                                        value={dayjs(entryData?.end_date)}
                                        onChange={(newValue) => { setSelectedEndDate(newValue) }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <FormControl sx={{ mt: '1rem', width: '100%', marginTop: "5px" }}>
                                <StyledTextField
                                    id="weight"
                                    label="Peso ( % )"
                                    name="weight"
                                    autoComplete="identity"
                                    variant="outlined"
                                    value={peso}
                                    onChange={(event) => {
                                        if (event.target.value) {
                                            const newValue = parseInt(event.target.value, 10);
                                            setPeso(newValue)
                                        } else {
                                            setPeso("");
                                        }
                                    }}
                                    sx={{
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: '15px',
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {renderOptions()}
                                </StyledTextField>
                            </FormControl>
                            <FormControl sx={{ mt: '1rem', width: '100%', marginTop: "15px" }}>
                                <StyledTextField
                                    id="total_appearance"
                                    label="Tasa de aparición"
                                    name="total_appearance"
                                    autoComplete="identity"
                                    variant="outlined"
                                    value={apariciones}
                                    onChange={(event) => {
                                        if (event.target.value) {
                                            const newValue = parseInt(event.target.value, 10);
                                            setApariciones(newValue)
                                        } else {
                                            setApariciones("");
                                        }
                                    }}
                                    sx={{
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: '15px',
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {renderOptions()}
                                </StyledTextField>
                            </FormControl>
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: "white",
                                        color: primaryColor,
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ModalBannerEdit;
