import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Dayjs } from 'dayjs';
interface UseTimeTableProps {
    start_date: Dayjs | null;
    end_date: Dayjs | null;
    totalUseTime: number;
    useTimeData: { name: string; value: number }[];
}

export const UseTimeTable: React.FC<UseTimeTableProps> = ({ start_date, end_date, useTimeData, totalUseTime }) => {
    
    const containerWidth = Math.max(useTimeData.length * 50, 1100); 

    return (
        <div style={{ overflowX: 'auto', maxWidth: '100%', overflowY: 'hidden' }}>
             <div style={{ minWidth: 1100, width: containerWidth }}> 
                <ResponsiveContainer width={"100%"} height={650}>
                    <BarChart
                        data={useTimeData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" interval={0} angle={75} textAnchor="start" height={250} label={{ position: 'insideBottom' }} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8884d8" name="Tiempo de uso" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};