import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


type ModalSendCodeProps = {
    isOpen: boolean;
    onClose: (create: boolean) => void;
};

const ModalSendCode: React.FC<ModalSendCodeProps> = ({ isOpen, onClose }) => {

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "62px",
                    width: "450px",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "140px",
                    backgroundColor: "#ffffff",
                    border: "1 solid #E5E7EB",
                    borderRadius: "16px"
                }}
            >
                <Box component="form">
                    <Typography sx={{ color: primaryColor, fontSize: "22px", paddingTop: "32px", paddingLeft: "110px", marginBottom: "5px" }}>
                        Codigo de verificacion
                    </Typography>
                    <Typography sx={{ fontSize: "13px", paddingLeft: "20px", paddingRight: "20px", marginBottom: "25px" }}>
                        Te hemos enviado un nuevo correo electronico con otro codigo, si aun no funciona verifica que el correo electronico sea correcto.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 5, marginLeft: '200px', height: "40px" }}>
                        {
                            <img src={`/assets/images/Group.png`} alt="enviando" />
                        }
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalSendCode;
