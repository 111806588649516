import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Icon, TableHead } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import ModalEditRole from './Modal/ModalEditRole';
import ModalDeleteRole from './Modal/ModalDeleteRole';
import { getAdminByName, getAllAdmin, getAllAdminId } from '../../../../services/user';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Última página"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(id: string, first_name: string, last_name: string, email: string, password: string) {
    return { id, first_name, last_name, email, password };
}

export default function UserRoleManagement(props: any) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 5);
    const [rows, setRows] = React.useState<{ id: string; first_name: string; last_name: string; email: string; password: string }[]>([]);
    const [currentTab, setCurrentTab] = React.useState(0);
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        if (props.value || !props.valueQuery || props.valueQuery === "") {
            getAll();
        } else {
            getByName(props.valueQuery);
        }
    }, [props.value, props.valueQuery]);

    const getAll = async () => {
        const res = await getAllAdmin();
        if (res) {
            const aux: { id: string; first_name: string; last_name: string; email: string; password: string }[] = [];
            res.forEach((element: { id: string; first_name: string; last_name: string; email: string; password: string }) => {
                aux.push(createData(element.id, element.first_name, element.last_name, element.email, element.password));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const getByName = async (valueQuery: any) => {

        const res = await getAdminByName(valueQuery);

        if (res) {
            const aux: { id: string; first_name: string; last_name: string; email: string; password: string }[] = [];
            res.forEach((element: { id: string; first_name: string; last_name: string; email: string; password: string }) => {
                aux.push(createData(element.id, element.first_name, element.last_name, element.email, element.password));
            });

            setRows(aux);
            setShow(true);
        }
    }

    // Evitar un salto de diseño al llegar a la última página con filas vacías.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.onChange(parseInt(event.target.value, 10));
    };


    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [first_name, setFirst_name] = useState<string>('');
    const [last_name, setLast_name] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSnackbarClick = async (row: any) => {
        try {

            let admin = await getAllAdminId(row.id);
            if (admin) {

                admin.first_name = first_name;
                admin.last_name = last_name;
                admin.email = email;
                admin.password = password;

                setSelectedRow(admin);
                setIsOpen(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    const handleSnackbarEditClick = async (row: any) => {
        try {
            let admin = await getAllAdminId(row.id);
            if (admin) {
                setFirst_name(admin.first_name);
                setLast_name(admin.last_name);
                setEmail(admin.email);
                setPassword(admin.password);


                setSelectedRow(admin);
                setIsOpenEdit(true);
            }
        } catch (error) {
            console.error('Error al obtener la respuesta del servicio - usuario:', error)
        }
    };


    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(): void {
        setIsOpen(false);
    }

    function closeModalEditHandler(): void {
        setIsOpenEdit(false);
    }

    function closeModalDeleteHandler(): void {
        setIsOpenDelete(false);
    }

    const emptyImage = `../assets/images/empty-state.svg`;

    const handleSnackbarDeleteClick = async (row: any) => {
        try {
            let admin = await getAllAdminId(row.id);
            if (admin) {

                admin.first_name = first_name;
                admin.last_name = last_name;
                admin.email = email;
                admin.password = password;

                setSelectedRow(admin);
                setIsOpenDelete(true);
            }

        } catch (error) {
            console.error('Error al obtener la respuesta del servicio:', error)
        }
    };

    const [forceUpdate, setForceUpdate] = React.useState(false);

    const updateAdminList = async () => {
        const res = await getAllAdmin();
        if (res) {
            const aux: { id: string; first_name: string; last_name: string; email: string; password: string }[] = [];
            res.forEach((element: { id: string; first_name: string; last_name: string; email: string; password: string }) => {
                aux.push(createData(element.id, element.first_name, element.last_name, element.email, element.password));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <TableContainer component={Paper}>
            {(show && <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Nombre</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Apellido</TableCell>
                        <TableCell sx={{ color: '#4B5563', fontFamily: 'Verdana' }} align="left">Correo electrónico</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell style={{ width: 180 }} align="left">
                                {row.first_name}
                            </TableCell>
                            <TableCell style={{ width: 180 }} align="left">
                                {row.last_name}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="left">
                                {row.email}
                            </TableCell>
                            <TableCell style={{ width: 80 }} align="right">
                                <Icon style={{ cursor: 'pointer' }} component={EditIcon} onClick={() => handleSnackbarEditClick(row)} />
                            </TableCell>
                            <TableCell style={{ width: 80 }} align="center">
                                <Icon style={{ cursor: 'pointer' }} component={DeleteIcon} onClick={() => handleSnackbarDeleteClick(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            align='right'
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>)}
            {(!show && <img src={emptyImage} alt="Empty" />)}
            <><ModalEditRole isOpen={isOpenEdit} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalEditHandler} updateAdminList={updateAdminList}></ModalEditRole></>
            <><ModalDeleteRole isOpen={isOpenDelete} entryData={selectedRow} openModalHandler={openModalHandler} closeModalHandler={closeModalDeleteHandler} updateAdminList={updateAdminList} setShow={setShow} rows={rows}></ModalDeleteRole></>
        </TableContainer>

    );
}
