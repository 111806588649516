import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { Box, Button, Modal, Typography, TextField, styled, FormControl } from "@mui/material";
import React, { FormEvent, useRef, useState, useEffect } from "react";
import { DatePicker } from '@mui/x-date-pickers';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    openModalHandler: () => void;
    closeModalHandler: () => void;
};

const ModalViewLogic: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    openModalHandler,
    closeModalHandler,
}) => {

    const [activeStep, setActiveStep] = useState(0);


    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }

    const [nombre, setNombre] = useState('');
    const [tipoPubli, setTipoPubli] = useState('');

    const handlNombredChange = (event: any) => {
        setNombre(event.target.value);
    };
    const handleTipoPubliChange = (event: any) => {
        setTipoPubli(event.target.value);
    };


    const [selectedStartDate, setSelectedStartDate] = React.useState<Dayjs | null>(dayjs(entryData?.start_date));
    const [selectedEndDate, setSelectedEndDate] = React.useState<Dayjs | null>(dayjs(entryData?.end_date));

    const [peso, setPeso] = useState(entryData?.weight);
    const handlePesoChange = (event: any) => {
        setPeso(event.target.value);
    };

    const [apariciones, setApariciones] = useState(entryData?.total_appearance);
    const handleAparicionesChange = (event: any) => {
        setApariciones(event.target.value);
    };

    const fileInputRef = useRef(null);

    const [file, setFile] = useState('');
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        // Realizar acciones con el archivo seleccionado
    };

    const [estadoActivo, setEstadoActivo] = useState(true);

    useEffect(() => {
        if (entryData && entryData.state) {
            setEstadoActivo(true);
        } else {
            setEstadoActivo(false);
        }

    }, []);

    useEffect(() => {
        try {

            if (entryData && entryData.banner_type) {
                setTipoPubli(entryData.banner_type.toLowerCase());
            }
        }
        catch (error) {
            console.error(error);
        }
    })

    const renderOptions = () => {
        const options = [];

        for (let i = 0; i <= 100; i += 1) {
            const optionValue = i.toString();
            const optionLabel = `${i}%`;
            options.push(<option value={optionValue} key={optionValue}>{optionLabel}</option>);
        }

        return options;
    };


    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingLeft: "42px"
                    }}
                >
                    Detalles de campaña
                </Typography>
                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <StyledTextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id="nombre"
                                label="Nombre"
                                name="nombre"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={entryData?.name ? entryData.name : ''}
                                onChange={handlNombredChange}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    marginTop: "5px",
                                    marginBottom: "55px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '33px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DatePicker label="Fecha de alta" readOnly value={dayjs(entryData?.start_date)} />
                                </LocalizationProvider>

                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginInline: '42px', marginBottom: '15px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DatePicker label="Fecha de baja" readOnly value={dayjs(entryData?.end_date)} />
                                </LocalizationProvider>

                            </Box>
                            <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                <StyledTextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    id="weight"
                                    label="Peso ( % )"
                                    name="weight"
                                    autoComplete="identity"
                                    variant="outlined"
                                    value={entryData?.weight}
                                    onChange={handlePesoChange}
                                    sx={{
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: '15px',
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {renderOptions()}</StyledTextField>
                            </FormControl>
                            <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                <StyledTextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    id="total_appearance"
                                    label="Tasa de aparición"
                                    name="total_appearance"
                                    autoComplete="identity"
                                    variant="outlined"
                                    value={entryData?.total_appearance}
                                    onChange={handleAparicionesChange}
                                    sx={{
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: '15px',
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {renderOptions()}</StyledTextField>
                            </FormControl>
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >

                                <Button
                                    variant="contained"
                                    onClick={closeModalHandler}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        marginTop: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Aceptar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ModalViewLogic;

