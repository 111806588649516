import React, { useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Grid, Typography, Button, TextField, styled } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { GetAllSettings, ISettingsUrl, UpdateSettings } from '../../../../services';
import FloatingImage from '../../../FloatingImage';

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: '#3D496E',
        '& legend': {
            '& span': {
                color: '#3D496E'
            }
        }
    },
}));


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const LandingEdition = () => {

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(true);
    const [id, setId] = useState<string>('');
    const [url_landing, setUrl_landing] = useState<string>('');

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (newValue === '') {
            setUrl_landing('');
        } else {
            setUrl_landing(newValue);
        }
    };

    useLayoutEffect(() => {
        const getAll = async () => {
            const settingsData: ISettingsUrl[] = await GetAllSettings();
            if (settingsData && settingsData.length > 0) {
                const firstSettings = settingsData[0];
                setId(firstSettings.id);

                const urlWithoutPrefix = firstSettings.url_landing.replace(/^(http:\/\/|https:\/\/)/, '');
                setUrl_landing(urlWithoutPrefix);
            }
            setIsLoading(false);
        };
        getAll();
    }, []);


    const handleSnackbarClick = async (event: any) => {
        if (id) {
            const formData = new FormData();
            const fullUrl = url_landing ? 'http://' + url_landing : '';
            formData.append('url_landing', fullUrl);
            const response = await UpdateSettings(formData, id);
            setSuccess(true);
            setSnackbarOpen(true);
        } else {
            console.error("ID no válido");
        }
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px", marginBottom: "-65px" }}>Configuración del sistema</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', }} >
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 600,
                                        ml: '1rem',
                                        marginRight: "22px",
                                        marginTop: "15px",
                                        backgroundColor: primaryColor,
                                        color: "white",
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        },
                                        '&:active': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        }
                                    }}
                                    onClick={handleSnackbarClick}
                                >
                                    Guardar cambios
                                </Button>
                            </Box>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "-15px" }}>Landing Page</Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 'auto',
                                        }}
                                    >
                                        <Box>
                                            <TextInput
                                                margin="normal"
                                                value={url_landing}
                                                onChange={handleLinkChange}
                                                sx={{ width: '100%' }}
                                                name="url_landing"
                                                label="URL - Redirección luego de registro"
                                                type="url_landing"
                                                id="url_landing"
                                                autoComplete="url_landing"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                <FloatingImage />
            </Box >
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {success ? "Cambios guardados" : "Error al modificar alguna/s landing/s"}
                </Alert>
            </Snackbar>

        </>
    );
}

export default LandingEdition;