import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box, Toolbar, Typography, Button, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import UserRoleManagement from './UserRoleManagement';
import { getAdminByName, getAllAdmin } from '../../../../services/user';
import ModalCreateRole from './Modal/ModalCreateRole';
import { ManagementSearch } from '../UserManagement/ManagmentSearch';
import FloatingImage from '../../../FloatingImage';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function createData(id: string, first_name: string, last_name: string, email: string, password: string) {
    return { id, first_name, last_name, email, password };
}

const RoleManagement = (props: any) => {
    const [success, setSuccess] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [value, setValue] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isOpen, setIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [userId, setUserId] = useState<string>('');
    const [first_name, setFirst_name] = useState<string>('');
    const [last_name, setLast_name] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [page, setPage] = React.useState(0);
    const theme = useTheme();
    const [recargar, setRecargar] = useState<boolean>(false);

    useLayoutEffect(
        () => {
            const getAll = async () => {
                const res = await getAllAdmin();

                setFirst_name(first_name);
                setLast_name(last_name);
                setEmail(email);
                setPassword(password);

                setIsLoading(false);
            }
            getAll();
            if (recargar) {
                setRecargar(false);
            }
        },
        [recargar]
    );

    const handleSnackbarClick = async () => {
        setIsOpen(true);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    function openModalHandler(): void {
        throw new Error('Function not implemented.');
    }

    function closeModalHandler(result: boolean): void {
        setIsOpen(false);
        setRecargar(true);
        if (result) {
            setReload(true);
            setSuccess(true);

        } else {
            setReload(false);
        }
    }

    function handleAttributeChange(result: any) {
        setRowsPerPage(result);
    }

    function handlePageChange(result: any) {
        setPage(result);
    }

    const [rows, setRows] = React.useState<{ id: string, first_name: string, last_name: string, email: string, password: string }[]>([]);
    const [show, setShow] = React.useState(false);

    const updateUserList = async () => {
        const res = await getAllAdmin();
        if (res) {
            const aux: { id: string, first_name: string, last_name: string, email: string, password: string }[] = [];
            res.forEach((element: { id: string, first_name: string, last_name: string, email: string, password: string }) => {
                aux.push(createData(element.id, element.first_name, element.last_name, element.email, element.password));
            });

            setRows(aux);
            setShow(true);
        }
    }

    useEffect(() => {
        if (!props.valueQuery || props.valueQuery === "") {
            getAll();
        } else {
            getByName(props.valueQuery);
        }
    }, [props.valueQuery]);

    const getAll = async () => {
        const res = await getAllAdmin();
        if (res) {
            const aux: { id: string, first_name: string, last_name: string, email: string, password: string }[] = [];
            res.forEach((element: { id: string, first_name: string, last_name: string, email: string, password: string }) => {
                aux.push(createData(element.id, element.first_name, element.last_name, element.email, element.password));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const getByName = async (valueQuery: any) => {

        const res = await getAdminByName(valueQuery);

        if (res) {
            const aux: { id: string, first_name: string, last_name: string, email: string, password: string }[] = [];
            res.forEach((element: { id: string, first_name: string, last_name: string, email: string, password: string }) => {
                aux.push(createData(element.id, element.first_name, element.last_name, element.email, element.password));
            });

            setRows(aux);
            setShow(true);
        }
    }

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Toolbar sx={{ borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid' }} />
                {
                    !isLoading && (
                        <>
                            <Typography variant="subtitle2" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "25px", marginBottom: "-65px" }}>Configuración del sistema</Typography>
                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 600,
                                        ml: '1rem',
                                        marginRight: "22px",
                                        marginTop: "15px",
                                        backgroundColor: primaryColor,
                                        textTransform: 'capitalize',
                                        marginLeft: "-20px",
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        },
                                        '&:active': {
                                            backgroundColor: primaryColorHover,
                                            color: 'white',
                                        }
                                    }}
                                    onClick={handleSnackbarClick}
                                >
                                    Crear usuarios
                                </Button>
                            </Box>

                            <Typography variant="h4" component="h2" sx={{ color: '#3D496E', marginLeft: "22px", marginTop: "-15px" }}>Administradores</Typography>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', marginLeft: "22px", marginRight: "42px", marginTop: "-10px" }}>
                                <ManagementSearch value={searchQuery} onChange={setSearchQuery} isGroup={false} />
                            </Box>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', marginLeft: "22px", marginRight: "22px", }}>
                                <UserRoleManagement value={recargar} valueQuery={searchQuery} />
                            </Box>
                        </>
                    )
                }
                <FloatingImage />
            </Box >
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%', backgroundColor: 'white', color: primaryColor }}>
                    {success ? "Cambios guardados" : "Operacion fallida"}
                </Alert>
            </Snackbar>
            <ModalCreateRole isOpen={isOpen} openModalHandler={openModalHandler} closeModalHandler={closeModalHandler}></ModalCreateRole>
        </>
    );
}

export default RoleManagement;