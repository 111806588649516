import axios from "axios"
import { API_BACKOFFICE_URL, API_PORTAL_URL } from "../constants/api"
import { clearSession } from "../utils/auth";

export const userLogout = async () => {
    try {
        const userInfo = await axios.get(`${API_PORTAL_URL}/admin/logout`);

        return userInfo || null;
    } catch (err) {
        console.error(err);
    }
}

export const logoutWithCheck = async () => {
    clearSession();
    if (localStorage.getItem('guardarContraseña') === 'false') {
        localStorage.removeItem('user');
        localStorage.removeItem('password');
    }
    const result = await userLogout();
    window.location.href = '/';
}

//Usuarios
export const getAllUser = async () => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/user/all`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getAllUserId = async (id: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/user/one/${id}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getUserByEmail = async (email: string) => {
    if (email.length) {
        try {
            const userInfo = await axios.get(`${API_PORTAL_URL}/users/${email}`);

            return userInfo || null;
        } catch (err) {
            console.error(err);
        }
    }
}

export const getUserByName = async (name: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/user/all/${name}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const createUser = async (id: string, body: any) => {
    try {
        const response = await axios.post(`${API_BACKOFFICE_URL}/admin/user/new`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const updateUserId = async (id: string, body: any) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/user/update/${id}`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

/* Dejo de funcionar
export const updateUserDelete = async (id: string) => {  
    try {
        const response = await axios.delete(`${API_BACKOFFICE_URL}/admin/user/delete/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}*/
export const updateUserDelete = async (id: string) => {
    try {
        const response = await axios.delete(`${API_PORTAL_URL}/user/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const getAllUserGroups = async (id: string) => {
    try {
        const response = await axios.get(`${API_PORTAL_URL}/user/all/${id}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}




//Administradores


export const getAllAdmin = async () => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/viewall`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getAllAdminId = async (id: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/one/${id}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const createAdmin = async (body: any) => {
    try {
        const response = await axios.post(`${API_BACKOFFICE_URL}/admin/register`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const updateAdminId = async (id: string, body: any) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/data/${id}`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const getAdminByName = async (name: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/viewall/${name}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const getAdminByEmail = async (email: any) => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/email/${email}`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const updateAdminDelete = async (id: string) => {
    try {
        const response = await axios.delete(`${API_BACKOFFICE_URL}/admin/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

//Recovery password - administradores


export const recoveryPassword = async (formData: FormData) => {
    try {
        const response = await axios.post(`${API_BACKOFFICE_URL}/recovery-password`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (err) {
        console.error('Error en recoveryPassword:', err);
        throw err;
    }
};

export const checkToken = async (formData: FormData) => {
    try {
        const response = await axios.post(`${API_BACKOFFICE_URL}/check-token`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const resetPassword = async (formData: FormData) => {
    try {
        const response = await axios.post(`${API_BACKOFFICE_URL}/reset-password`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

