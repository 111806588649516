import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    styled,
    FormControl,
    MenuItem,
    InputLabel,
    TableCell,
} from "@mui/material";
import React, { FormEvent, useRef, useState, useEffect } from "react";
import { updateBanner, updateFileBanner } from '../../../../../../../services/banner';

const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-asterisk": {
        color: "red", // Cambia el color del asterisco a rojo
    },
});


type ModalFormularioProps = {
    isOpen: boolean;
    entryData: any;
    openModalHandler: () => void;
    closeModalHandler: () => void;
};

const ModalEditMultimedia: React.FC<ModalFormularioProps> = ({
    isOpen,
    entryData,
    openModalHandler,
    closeModalHandler,

}) => {

    const [activeStep, setActiveStep] = useState(0);


    const handleSave = async () => {
        // Lógica para guardar los datos del formulario
        // Acomodar
        const data = { id, nombre, tipoCampaña, tipoPubli, link };


        setActiveStep(0);
        closeModalHandler();

        const formData = new FormData();
        const body = {
            state: estadoActivo,
            file: fileInputRef
        };

        if (file) {
            formData.append('file', file);
            updateFileBanner(entryData?.id, formData);
        }

        updateBanner(entryData?.id, body);
        closeModalHandler();
    };



    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }
    const handleCancel = () => {
        // Lógica para guardar los datos del formulario
        setActiveStep(0);
        closeModalHandler();
    };

    const [id, setId] = useState('');
    const [nombre, setNombre] = useState('');
    const [tipoPubli, setTipoPubli] = useState('');
    const [tipoCampaña, setTipoCampaña] = useState('');
    const [link, setLink] = useState('');
    const [file, setFile] = useState('');


    const handlIdChange = (event: any) => {
        setId(event.target.value);
    };
    const handlNombredChange = (event: any) => {
        setNombre(event.target.value);
    };
    const handleTipoPubliChange = (event: any) => {
        setTipoPubli(event.target.value);
    };
    const handleTipoCampañaChange = (event: any) => {
        setTipoCampaña(event.target.value);
    };
    const handleLinkChange = (event: any) => {
        setLink(event.target.value);
    };

    const fileInputRef = useRef(null);

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        // Realizar acciones con el archivo seleccionado
    };

    const [estadoActivo, setEstadoActivo] = useState(true);

    useEffect(() => {
        if (entryData && entryData.state) {
            setEstadoActivo(true);
        } else {
            setEstadoActivo(false);
        }

    }, [entryData]);

    useEffect(() => {

        try {
            if (entryData && entryData.campaign_type !== null) {
                setTipoCampaña(entryData.campaign_type);
            }

            if (entryData && entryData.banner_type) {
                setTipoPubli(entryData.banner_type.toLowerCase());
            }
        }
        catch (error) {
            console.error(error);
        }

    })

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <Modal
            open={isOpen}
            onClose={closeModalHandler}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "32px",
                    width: "650px",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Typography
                    sx={{
                        color: "#274B5B",
                        fontSize: "22px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingLeft: "42px",
                        marginBottom: "-40px"
                    }}
                >
                    Editar campaña
                </Typography>

                {activeStep === 0 && (
                    <div>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <StyledTextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id="nombre"
                                label="Nombre de la campaña"
                                name="nombre"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={entryData?.name ? entryData.name : ''}
                                onChange={handlNombredChange}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <FormControl sx={{ mt: '1rem', width: '100%', marginTop: "15px" }}>
                                <InputLabel sx={{ marginLeft: "42px" }} id="campaign_type"></InputLabel>
                                <StyledTextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    id="campaign_type"
                                    label="Tipo de campaña"
                                    name="campaign_type"
                                    autoComplete="identity"
                                    value={tipoCampaña}
                                    onChange={handleTipoCampañaChange}
                                    sx={{
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: "-8px",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="recomendacion de producto/servicio">Recomendacion de Producto/servicio</MenuItem>
                                    <MenuItem value="descarga de aplicación">Descarga de Aplicación</MenuItem>
                                    <MenuItem value="capacitación de seguidores">Capacitación de Seguidores</MenuItem>
                                </StyledTextField>
                            </FormControl>
                            <StyledTextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id="link"
                                label="URL de redirección"
                                name="URL de redirección"
                                autoComplete="identity"
                                autoFocus
                                variant="outlined"
                                value={entryData ? entryData.redirect_url : ''}
                                onChange={handleLinkChange}
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    margin: "42px",
                                    height: "32px",
                                    alignContent: "center",
                                    "& fieldset": {
                                        borderRadius: "0.5rem",
                                        "& legend": {
                                            "& span": {
                                                color: "#F68D2E",
                                            },
                                        },
                                    },
                                }}
                            />
                            <FormControl sx={{ mt: '1rem', width: '100%' }}>
                                <InputLabel sx={{ marginLeft: "42px" }} id="type_publisher"></InputLabel>
                                <StyledTextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    id="type_publisher"
                                    label="Tipo de publicación"
                                    name="type_publisher"
                                    autoComplete="identity"
                                    value={tipoPubli}
                                    onChange={handleTipoPubliChange}
                                    sx={{
                                        display: "flex",
                                        marginLeft: "42px",
                                        marginRight: "42px",
                                        marginBottom: "-15px",
                                        "& fieldset": {
                                            borderRadius: "0.5rem",
                                            "& legend": {
                                                "& span": {
                                                    color: "#F68D2E",
                                                },
                                            },
                                        },
                                    }}>
                                    <MenuItem value="imagen">Imagen</MenuItem>
                                    <MenuItem value="video">Video</MenuItem>
                                    <MenuItem value="gif">GIF</MenuItem></StyledTextField>
                            </FormControl>
                            <div style={{ display: "flex", gap: "10px", margin: "42px", height: "32px", marginBottom: "-10px" }}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".pdf,.doc,.docx,.png"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <Typography sx={{ margin: "42px", height: "32px", marginTop: "20px", marginBottom: "-26px " }}>Estado </Typography>
                            <TableCell style={{ width: 120, borderBottom: "none" }} align="center">
                                <p className={`viewMultimedia ${entryData && entryData.state ? 'activo' : 'inactivo'}`}>
                                    {entryData && entryData.state ? "Activo" : "Inactivo"}
                                </p>
                            </TableCell>
                            <Box
                                sx={{
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        backgroundColor: "white",
                                        color: primaryColor,
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColor,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        width: "90px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        margin: "10px",
                                        "&:hover": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                        "&:active": {
                                            backgroundColor: primaryColorHover,
                                            color: "white",
                                        },
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ModalEditMultimedia;